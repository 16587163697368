import { Button, LabeledFormSection, Modal, Select, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { FormApi } from 'final-form'
import { FC, useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import { loadUnitsArray } from 'views/CalculateEmissions/RFQ/utils/sharedTypes'
import {
    RFQBaseData,
    RFQBaseState,
    RFQTemplateOptions,
    RFQTemplateState,
} from 'views/CalculateEmissions/RFQ/utils/types'
import {
    convertCellToString,
    detectTableEndRow,
    extractRowValues,
    inferPropertyMapping,
} from 'views/CalculateEmissions/RFQ/utils/utils'

export enum FormKeys {
    SHEET_NAME = 'sheetName',
    HEADER_ROW = 'headerRow',
    NUMBER_OF_LEGS = 'numberOfLegs',
}

interface FileSettingsProps {
    sheetName: string
    headerRow: string
    numberOfLegs: string
}

const required = (value: string | undefined) =>
    value?.trim() ? undefined : 'This is a required field'

const FileSettingsModal: FC<{
    modalOpen: boolean
    onModalClose: () => void
}> = ({ modalOpen, onModalClose }) => {
    const navigate = useNavigate()
    const state = useLocation().state as RFQBaseData & (RFQTemplateOptions | {})

    const sheetName = 'sheetName' in state ? state.sheetName : ''
    const headerRow = 'headerRow' in state ? state.headerRow : ''
    const numberOfLegs = 'numberOfLegs' in state ? state.numberOfLegs : ''
    const availableSheets: string[] = state.workbook.worksheets.map((e) => e.name)

    const [localForm, setLocalForm] = useState<FormApi<FileSettingsProps>>()

    const onSubmit = (values: FileSettingsProps) => {
        const sheet = state.workbook.getWorksheet(values.sheetName)!
        const realHeaderRow = Number(values.headerRow) - 1 // Adapting due to 0 vs 1 based index
        const endRow = detectTableEndRow(state.workbook, values.sheetName, realHeaderRow)

        const templateState: RFQBaseState = {
            workbook: state.workbook,
            sheetName: values.sheetName,
            headerRow: Number(values.headerRow),
            endRow,
            numberOfLegs: Number(values.numberOfLegs),
            sheet,
            headers: extractRowValues(sheet, realHeaderRow).map(convertCellToString),
            exampleRow: extractRowValues(sheet, realHeaderRow + 1).map(convertCellToString),
        }
        const templateData: RFQTemplateState = {
            ...templateState,
            load: inferPropertyMapping(templateState, 'load', 'unit', loadUnitsArray),
            currentLeg: 1,
            legs: [],
            isFullInference: true,
        }
        navigate('/calculate-emissions/rfq/templates/load', {
            state: templateData,
        })
    }

    const FormSelect = useCallback(
        ({ input: { onChange, value }, meta }: any) => (
            <Select
                label={'Select tab to use'}
                error={meta.touched && meta.error}
                value={value}
                onChange={onChange}
                items={availableSheets.map((sheet) => {
                    return { value: sheet, label: sheet }
                })}
            />
        ),
        [availableSheets],
    )

    return (
        <Modal
            maxWidth={'md'}
            fullWidth
            open={modalOpen}
            onClose={() => onModalClose()}
            header={<Text variant={'h5'}>File Settings</Text>}
            actions={
                <Button
                    variant={'contained'}
                    wide
                    onClick={() => localForm?.submit()}
                    disabled={!localForm}
                >
                    Next
                </Button>
            }
        >
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    [FormKeys.SHEET_NAME]: sheetName,
                    [FormKeys.NUMBER_OF_LEGS]: numberOfLegs,
                    [FormKeys.HEADER_ROW]: headerRow,
                }}
                render={({ form }) => {
                    if (!localForm) {
                        setLocalForm(form)
                    }
                    return (
                        <Stack
                            direction="column"
                            sx={{
                                width: '100%',
                            }}
                            spacing={{
                                xs: 6,
                            }}
                        >
                            <LabeledFormSection
                                title={'Tab and header'}
                                subTile={'Select the tab and header row we should analyse'}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        '> div': {
                                            width: '100%',
                                            flex: '0 1 auto',
                                            '> div': {
                                                width: '100%',
                                            },
                                        },
                                    }}
                                >
                                    <Box>
                                        <Field
                                            name={FormKeys.SHEET_NAME}
                                            placeholder={'Select tab to use'}
                                            validate={required}
                                            component={FormSelect}
                                        />
                                    </Box>
                                    <Box>
                                        <Field
                                            name={FormKeys.HEADER_ROW}
                                            placeholder={'Header row to use'}
                                            component={FormInput}
                                            restrictToNumbers={true}
                                            validate={required}
                                        />
                                    </Box>
                                </Box>
                            </LabeledFormSection>

                            <LabeledFormSection
                                title={'Number of legs'}
                                subTile={
                                    'Set the maximum amount of legs you’d like to calculate emissions for'
                                }
                            >
                                <Field
                                    name={FormKeys.NUMBER_OF_LEGS}
                                    placeholder={'Set number of legs'}
                                    component={FormInput}
                                    restrictToNumbers={true}
                                    validate={required}
                                />
                            </LabeledFormSection>
                        </Stack>
                    )
                }}
            />
        </Modal>
    )
}

export default FileSettingsModal
