import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import FieldValidationError from 'lune-ui-lib/src/components/FieldValidationError'

import Field from 'views/CalculateEmissions/RFQ/components/Field'
import { showColumnPickerModal } from 'views/CalculateEmissions/RFQ/modals/ColumnPickerModal'

type ColumnPickerProps = {
    headers: string[]
    exampleRow: string[]
    label: string
    columnIndex: number | undefined
    onUpdate: (columnIndex: number | undefined) => void
    error?: string
}

// TODO handle required/optional data differently? For now everything is seen as optional here
const ColumnPicker = ({
    label,
    headers,
    columnIndex,
    onUpdate,
    exampleRow,
    error,
}: ColumnPickerProps) => {
    return (
        <>
            <Field
                error={error}
                onClick={async () => {
                    const selection = await showColumnPickerModal({
                        propertyName: label,
                        headers,
                        exampleRow,
                        columnIndex,
                    })
                    if (selection) {
                        const newColumnIndex = selection === 0 ? undefined : selection
                        onUpdate(newColumnIndex)
                    }
                }}
                placeholder={`Add column for ${label}`}
                label={columnIndex !== undefined ? headers[columnIndex] : undefined}
                subLabel={columnIndex !== undefined ? exampleRow[columnIndex] : undefined}
                rightButtonIcon={columnIndex !== undefined ? <CloseIcon /> : undefined}
                onRightButtonClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onUpdate(undefined)
                }}
                leftButtonIcon={columnIndex === undefined ? <AddIcon /> : undefined}
                onLeftButtonClick={(e) => {
                    e.preventDefault()
                }}
            />
            {error && <FieldValidationError error={error} />}
        </>
    )
}

export default ColumnPicker
