import { Select } from '@lune-fe/lune-ui-lib'

type UnitPickerProps<T> = {
    units: readonly T[]
    formatUnit: (unit: T) => string
    selectedUnit: T
    onUpdate: (unit: T) => void
}

const UnitPicker = <T,>({ units, formatUnit, selectedUnit, onUpdate }: UnitPickerProps<T>) => {
    const handleChange = (value: string) => {
        const index = Number(value)
        onUpdate(units[index])
    }

    return (
        <Select
            sx={{
                minWidth: '95px',
            }}
            onChange={handleChange}
            value={units.indexOf(selectedUnit).toString()}
            items={units.map((i, index) => ({ label: formatUnit(i), value: index.toString() }))}
        />
    )
}

export default UnitPicker
