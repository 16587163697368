import {
    EmissionFactorEstimate,
    EmissionFactorWithGasEmissions,
    EstimateMass,
    GasEmissions,
} from '@lune-climate/lune'
import { Box } from '@mui/material'
import { Big } from 'big.js'
import { FC, useMemo } from 'react'

import { convertToTonne } from '../../utils/utils'

import EmissionFactorExplanationCard from './EmissionFactorExplanationCard'
import ExplanationChip from './ExplanationChip'
import ExplanationSteps, { ExplanationText } from './ExplanationSteps'

interface EmissionFactorEmissionExplanationProps {
    estimate: EmissionFactorEstimate
    enableEmissionFactorLink?: boolean
}

// Estimate response does not include the emission factor intensity.
// We calculate it instead.
const calculateTransactionEmissionFactorAmount = (
    emissions: EstimateMass,
    estimateRequest: EmissionFactorEstimate['request'],
    emissionFactor: EmissionFactorWithGasEmissions,
): Big => {
    if (emissionFactor.gasEmissions !== null) {
        return Big(emissionFactor.gasEmissions.co2E)
    }
    return convertToTonne(emissions).div(estimateRequest.activity.value)
}

export const EmissionFactorEmissionExplanation: FC<EmissionFactorEmissionExplanationProps> = ({
    estimate,
    enableEmissionFactorLink,
}) => {
    const { request: estimateRequest, mass: emissions, emissionFactor } = estimate

    const emissionFactorExplanation = useMemo((): JSX.Element => {
        return (
            <>
                <ExplanationText text="Selected the" />
                <ExplanationChip text={emissionFactor.name} />
                <ExplanationText text="Emission Factor" />
            </>
        )
    }, [emissionFactor])

    const formulaExplanation = (
        <ExplanationText text="Multiplied the Emission Factor by the amount" />
    )

    const steps: { description?: JSX.Element }[] = [
        { description: emissionFactorExplanation },
        { description: formulaExplanation },
    ]

    const localEmissionFactorAmount = calculateTransactionEmissionFactorAmount(
        emissions,
        estimateRequest,
        emissionFactor,
    )

    return (
        <>
            <ExplanationSteps steps={steps} />
            <Box>
                <EmissionFactorExplanationCard
                    {...(emissionFactor.gasEmissions === null
                        ? {
                              emissionFactor: emissionFactor as Omit<
                                  EmissionFactorWithGasEmissions,
                                  'gasEmissions'
                              > & { gasEmissions: null },
                              amount: localEmissionFactorAmount.mul(1000).toString(),
                          }
                        : {
                              emissionFactor: emissionFactor as Omit<
                                  EmissionFactorWithGasEmissions,
                                  'gasEmissions'
                              > & { gasEmissions: GasEmissions },
                              amount: null,
                          })}
                    label={emissionFactor.name}
                    href={
                        enableEmissionFactorLink
                            ? `/emission-factors/${emissionFactor.id}`
                            : undefined
                    }
                />
            </Box>
        </>
    )
}

export default EmissionFactorEmissionExplanation
