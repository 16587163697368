import { Button, LuneTheme, LuneThemeProvider, Modal, Select, Text } from '@lune-fe/lune-ui-lib'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import { useState } from 'react'
import ReactDOM from 'react-dom/client'

import TradeLane from 'views/CalculateEmissions/Logistics/TradeLane'
import Field from 'views/CalculateEmissions/RFQ/components/Field'
import Labels from 'views/CalculateEmissions/RFQ/components/Labels'

interface MapValuesProps<T> {
    parameter: string
    outputValues: readonly T[]
    formatOutput: (value: T) => string
    mappings: [string, T][]
    isTradelane?: boolean
}

interface MapValuesModalProps<T> extends MapValuesProps<T> {
    modalOpen: boolean
    onModalClose: (selection?: [string, T][]) => void
}

const LocalSelect = ({
    items,
    value,
    onChange,
}: {
    items: { label: string; value: number }[]
    value: number | undefined
    onChange: (e: number) => void
}) => {
    const [localValue, setLocalValue] = useState<number | undefined>(value)
    return (
        <Select
            sx={{
                width: '100%',
            }}
            value={localValue}
            onChange={(val) => {
                // converting toString first because of value 0 which is valid here
                if (val?.toString()) {
                    setLocalValue(val)
                    onChange(val)
                }
            }}
            items={items}
        />
    )
}

const LocalTradeLane = ({
    value,
    onChange,
}: {
    value: string | undefined
    onChange: (e: string) => void
}) => {
    const [localValue, setLocalValue] = useState<string | undefined>(
        value === 'Other' ? 'disaggregated_other' : value,
    )
    return (
        <Box
            sx={{
                width: '100%',
                '> div': {
                    width: '100%',
                },
            }}
        >
            <TradeLane
                value={localValue}
                onChange={(tradeLane) => {
                    if (tradeLane) {
                        setLocalValue(tradeLane)
                        onChange(tradeLane)
                    }
                }}
            />
        </Box>
    )
}

const MapValuesModal = <T,>({
    parameter,
    formatOutput,
    outputValues,
    mappings,
    modalOpen,
    onModalClose,
    isTradelane,
}: MapValuesModalProps<T>) => {
    const [localMappings, setLocalMappings] = useState<[string, T][]>(mappings)
    const handleChange = (index: number, value: number) => {
        const newValueIndex = value
        const newMappings = [...localMappings]
        newMappings[index] = [localMappings.map(([i, _o]) => i)[index], outputValues[newValueIndex]]
        setLocalMappings(newMappings)
    }

    const handleTradeLaneChange = (index: number, selected: string) => {
        const newMappings = [...localMappings]
        newMappings[index] = [localMappings.map(([i, _o]) => i)[index], selected as T]
        setLocalMappings(newMappings)
    }

    return (
        <LuneThemeProvider theme={LuneTheme}>
            <Modal
                maxWidth={'md'}
                fullWidth
                open={modalOpen}
                onClose={() => onModalClose()}
                header={
                    <Text variant={'h5'}>
                        Map values for column:{' '}
                        <span style={{ textTransform: 'capitalize' }}>{parameter}</span>
                    </Text>
                }
                subheader={
                    <Text variant={'body1'}>
                        This is the mapping of the unique values in your spreadsheet to Lune’s
                        values
                    </Text>
                }
                actions={
                    <>
                        <Button variant={'outlined'} wide onClick={() => onModalClose()}>
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            wide
                            onClick={() => onModalClose(localMappings)}
                        >
                            Map values
                        </Button>
                    </>
                }
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Labels customLeft={'Your Unique Value'} customRight={'Lune Values'} />
                    {mappings.map(([input, output], index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                            }}
                        >
                            <Box sx={{ width: '45%' }}>
                                <Field label={input} />
                            </Box>
                            <ArrowForwardIcon />
                            <Box sx={{ width: '45%' }}>
                                {isTradelane ? (
                                    <LocalTradeLane
                                        value={output as string}
                                        onChange={(e) => handleTradeLaneChange(index, e)}
                                    />
                                ) : (
                                    <LocalSelect
                                        items={outputValues.map((opt, index) => ({
                                            label: formatOutput(opt),
                                            value: index,
                                        }))}
                                        value={outputValues.indexOf(output)}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Modal>
        </LuneThemeProvider>
    )
}

export default MapValuesModal

export const showMapValuesModal = <T,>({
    parameter,
    outputValues,
    formatOutput,
    mappings,
    isTradelane,
}: MapValuesProps<T>): Promise<[string, T][] | undefined> => {
    return new Promise((resolve) => {
        const container = document.createElement('div')
        document.body.appendChild(container)
        const root = ReactDOM.createRoot(container)

        const handleClose = (selection?: [string, T][]) => {
            resolve(selection)
            setTimeout(() => {
                root.unmount()
                container.remove()
            }, 0)
        }

        root.render(
            <MapValuesModal
                isTradelane={isTradelane}
                outputValues={outputValues}
                formatOutput={formatOutput}
                mappings={mappings}
                parameter={parameter}
                modalOpen={true}
                onModalClose={handleClose}
            />,
        )
    })
}
