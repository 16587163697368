import image0 from '../../images/logistics-0.png'
import image1 from '../../images/logistics-1.png'
import image2 from '../../images/logistics-2.png'
import image3 from '../../images/logistics-3.png'
import image4 from '../../images/logistics-4.png'
import image5 from '../../images/logistics-5.png'
import image6 from '../../images/logistics-6.png'
import image7 from '../../images/logistics-7.png'
import image8 from '../../images/logistics-8.png'
import image9 from '../../images/logistics-9.png'
import image10 from '../../images/logistics-10.png'
import image11 from '../../images/logistics-11.png'
import image12 from '../../images/logistics-12.png'
import image13 from '../../images/logistics-13.png'
import image14 from '../../images/logistics-14.png'
import image28 from '../../images/logistics-15.png'
import image29 from '../../images/logistics-16.png'
import image30 from '../../images/logistics-17.png'
import image31 from '../../images/logistics-18.png'
import image15 from '../../images/spent-1.png'
import image16 from '../../images/spent-2.png'
import image17 from '../../images/spent-3.png'
import image18 from '../../images/spent-4.png'
import image19 from '../../images/spent-5.png'
import image20 from '../../images/spent-6.png'
import image21 from '../../images/spent-7.png'
import image22 from '../../images/spent-8.png'
import image23 from '../../images/spent-9.png'
import image24 from '../../images/spent-10.png'
import image25 from '../../images/spent-11.png'
import image26 from '../../images/spent-12.png'
import image27 from '../../images/spent-13.png'

export const cardImageAndBackgroundData = [
    // Logistics
    { name: 'Bulk carrier', backgroundColor: 'rgba(226, 235, 244, 1)', image: image0 },
    { name: 'Chemical tanker', backgroundColor: 'rgba(205, 226, 243, 1)', image: image1 },
    { name: 'General cargo ship', backgroundColor: 'rgba(210, 228, 236, 1)', image: image2 },
    { name: 'Liquefied gas tanker', backgroundColor: 'rgba(207, 223, 242, 1)', image: image3 },
    { name: 'Oil tanker', backgroundColor: 'rgba(205, 226, 243, 1)', image: image4 },
    { name: 'Other Liquids tanker', backgroundColor: 'rgba(205, 226, 243, 1)', image: image5 },
    { name: 'Refrigerated bulk carrier', backgroundColor: 'rgba(226, 235, 244, 1)', image: image6 },
    { name: 'Ro-Pax', backgroundColor: 'rgba(221, 233, 249, 1)', image: image7 },
    { name: 'Ro-Ro', backgroundColor: 'rgba(221, 233, 249, 1)', image: image8 },
    { name: 'Vehicle carrier', backgroundColor: 'rgba(221, 239, 246, 1)', image: image9 },
    {
        name: 'Inland Waterways, Container vessels',
        backgroundColor: 'rgba(205, 216, 226, 1)',
        image: image10,
    },
    {
        name: 'Inland Waterways, Motor vessels',
        backgroundColor: 'rgba(208, 222, 215, 1)',
        image: image11,
    },
    {
        name: 'Inland Waterways, Pushed convoy',
        backgroundColor: 'rgba(244, 232, 215, 1)',
        image: image12,
    },
    {
        name: 'Inland Waterways, Tanker vessels',
        backgroundColor: 'rgba(235, 230, 215, 1)',
        image: image13,
    },
    { name: 'Logistics site', backgroundColor: 'rgba(225, 241, 248, 1)', image: image14 },

    // truck
    { name: ['truck'], backgroundColor: 'rgba(253, 226, 214, 1)', image: image28 },
    // cargo plane, passenger plane
    { name: ['plane', 'jet', 'air'], backgroundColor: 'rgba(223, 226, 234, 1)', image: image29 },
    // container ship, IMO, vessel
    {
        name: ['container', 'vessel', 'IMO'],
        backgroundColor: 'rgba(223, 234, 255, 1)',
        image: image31,
    },
    // trains
    { name: 'train', backgroundColor: 'rgba(220, 241, 225, 1)', image: image30 },

    // SPENT - name represents a category
    { name: 'Cropping system', backgroundColor: 'rgba(249, 231, 215, 1)', image: image15 },
    { name: 'Construction work', backgroundColor: 'rgba(247, 229, 210, 1)', image: image16 },
    { name: 'Air transport', backgroundColor: 'rgba(217, 224, 251, 1)', image: image17 },
    {
        name: 'Vehicle rental and leasing',
        backgroundColor: 'rgba(233, 233, 233, 1)',
        image: image18,
    },
    { name: 'Hotel', backgroundColor: 'rgba(252, 237, 228, 1)', image: image19 },
    {
        name: 'Subway, tram, trolley bus',
        backgroundColor: 'rgba(226, 235, 244, 1)',
        image: image20,
    },
    {
        name: 'Utilities buildings and infrastructure',
        backgroundColor: 'rgba(218, 228, 250, 1)',
        image: image21,
    },
    { name: 'Food products', backgroundColor: 'rgba(234, 242, 226, 1)', image: image22 },
    { name: 'Clothing', backgroundColor: 'rgba(255, 240, 200, 1)', image: image23 },
    { name: 'Ceramic', backgroundColor: 'rgba(231, 233, 232, 1)', image: image24 },
    { name: 'Other business services', backgroundColor: 'rgba(235, 235, 235, 1)', image: image25 },
    {
        name: 'Activities of membership organizations',
        backgroundColor: 'rgba(255, 253, 233, 1)',
        image: image26,
    },
    {
        name: 'Public administration and defence services',
        backgroundColor: 'rgba(224, 244, 253, 1)',
        image: image27,
    },
]
