import { SeaEmissionsAggregatedByRouteAndCarrier, SeaShippingMetrics } from '@lune-fe/dapi-models'
import { LoadingWrapper } from '@lune-fe/lune-ui-lib'
import { useEffect, useState } from 'react'

import { getRouteAndCarrierMetrics, getSeaShippingMetrics } from 'endpoints/dapi'
import { EnabledView } from 'views/ReduceEmissions/EnabledView'
import { GatedView } from 'views/ReduceEmissions/GatedView'

export const ReduceEmissions = () => {
    const [headlineMetrics, setHeadlineMetrics] = useState<SeaShippingMetrics | null>(null)
    const [routerAndCarrierMetrics, setRouterAndCarrierMetrics] =
        useState<SeaEmissionsAggregatedByRouteAndCarrier | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const params = new URLSearchParams(window.location.search)
    const [organisationId] = params.getAll('organisation_id')

    const loadMetrics = async () => {
        try {
            const metrics = await getSeaShippingMetrics(organisationId)
            const localRouterAnCarrierMetrics = await getRouteAndCarrierMetrics(organisationId)
            setHeadlineMetrics(metrics)
            setRouterAndCarrierMetrics(localRouterAnCarrierMetrics)
        } catch {
            console.log('Organisation does not have access to Reduce Emissions feature.')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        loadMetrics()
    }, [])

    if (isLoading) {
        return (
            <LoadingWrapper
                loading={true}
                sx={{
                    height: `100vh`,
                    width: `100%`,
                }}
            >
                <></>
            </LoadingWrapper>
        )
    }

    return headlineMetrics && routerAndCarrierMetrics ? (
        <EnabledView
            preview={!!organisationId}
            data={{
                seaShippingMetrics: headlineMetrics,
                routerAndCarrierMetrics,
            }}
        />
    ) : (
        <GatedView />
    )
}
