import { LuneTheme, Switch, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Container from '@mui/material/Container'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'
import { patchOrganisation } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useMixpanel from 'hooks/useMixpanel'
import { useIsAuthorised } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'

const ManageLogoVisibility: FC = () => {
    const isAuthorised = useIsAuthorised()
    const { activeOrg, refetchActiveOrg } = useActiveOrganisation()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { palette } = LuneTheme
    const [externalPagesLogoVisibility, setExternalPagesLogoVisibility] = useState<boolean>(false)
    const mixpanel = useMixpanel()

    const updateOrg = (externalPagesLogoVisibility: boolean) => {
        patchOrganisation(activeOrg?.id || ``, {
            externalPagesLogoVisibility,
        })
            .then(() => {
                refetchActiveOrg()
                snackbar(SnackbarMessages.SETTINGS_SAVED)
                mixpanel.track('settings_visible_logo_saved')
            })
            .catch(() => {
                snackbar(SnackbarMessages.SETTINGS_UPDATE_FAILED)
            })
    }

    useEffect(() => {
        if (activeOrg) {
            setExternalPagesLogoVisibility(activeOrg.externalPagesLogoVisibility)
        }
    }, [activeOrg])

    return (
        <Container
            disableGutters
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mt: 4,
            }}
        >
            <Box>
                <Text variant={'body2'}>Include company logo on public sustainability pages</Text>
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Client accounts will show company logo in addition to the account logo
                </Text>
            </Box>
            <ConditionalTooltip show={!isAuthorised} tooltip={NotAdminTooltip}>
                <Switch
                    disabled={!isAuthorised}
                    defaultChecked={externalPagesLogoVisibility}
                    onChange={(e) => {
                        updateOrg(e.target.checked)
                        // a small delay to make sure we show the switch animation
                        setTimeout(() => {
                            setExternalPagesLogoVisibility(e.target.checked)
                        }, 100)
                    }}
                />
            </ConditionalTooltip>
        </Container>
    )
}

export default ManageLogoVisibility
