import { Button, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Labels from 'views/CalculateEmissions/RFQ/components/Labels'
import ParameterMapping from 'views/CalculateEmissions/RFQ/components/ParameterMapping'
import {
    LoadUnit,
    loadUnitsArray,
    PropertyMapping,
} from 'views/CalculateEmissions/RFQ/utils/sharedTypes'
import { RFQTemplateState } from 'views/CalculateEmissions/RFQ/utils/types'
import { parseBig } from 'views/CalculateEmissions/RFQ/utils/utils'

const ShipmentLoad = () => {
    const navigate = useNavigate()

    const state = useLocation().state as RFQTemplateState
    const [shipmentLoadMapping, setShipmentLoadMapping] = useState<
        PropertyMapping<LoadUnit> | undefined
    >(state.load)

    const [error, setError] = useState<string | undefined>()

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (!shipmentLoadMapping) {
            setError('Required mapping')
            return
        } else if (
            shipmentLoadMapping.source === 'column' &&
            !parseBig(state.exampleRow[shipmentLoadMapping.columnIndex])
        ) {
            setError('Invalid value for load, must be numeric')
            return
        }

        const newState: RFQTemplateState = {
            ...state,
            load: shipmentLoadMapping,
        }
        navigate('/calculate-emissions/rfq/templates/leg', {
            state: newState,
        })
    }

    return (
        <>
            <MainLayoutContainer
                headerComponent={
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 6,
                            }}
                        >
                            <Button
                                variant="text"
                                iconButton
                                leftIcon={<ArrowBackOutlinedIcon />}
                                sx={{
                                    ml: -1.5,
                                }}
                                onClick={() =>
                                    navigate('/calculate-emissions/rfq', { state: null })
                                }
                            />
                        </Box>
                        <Text variant={'h6'}>Step 1/{state.numberOfLegs + 1}</Text>
                        <Text variant={'h4'}>Input data for Load</Text>
                    </Box>
                }
            >
                <form onSubmit={handleSubmit}>
                    <Labels />
                    <ParameterMapping<LoadUnit>
                        error={error}
                        state={state}
                        parameter="load"
                        mappingType="unit"
                        outputValues={loadUnitsArray}
                        formatOutput={String}
                        propertyMapping={shipmentLoadMapping}
                        onUpdate={(_parameterName, data) => {
                            setError(undefined)
                            setShipmentLoadMapping(data)
                        }}
                    />
                    <Button type={'submit'} sx={{ mt: 9 }} rightIcon={<ArrowForwardOutlinedIcon />}>
                        Next: Leg 1
                    </Button>
                </form>
            </MainLayoutContainer>
        </>
    )
}

export default ShipmentLoad
