import { BannerMessage, Text } from '@lune-fe/lune-ui-lib'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'

export const InferredShippingLegBanner = () => {
    return (
        <BannerMessage sx={{ backgroundColor: 'Grey100' }}>
            <RouteOutlinedIcon sx={{ mr: 2 }} />
            <Text>This leg was inferred from your input.</Text>
        </BannerMessage>
    )
}

export default InferredShippingLegBanner
