import { ProposalRequest } from '@lune-fe/dapi-models'
import { Stack } from '@mui/material'
import { FC } from 'react'

import Recap from 'views/Proposals/ProposalRequestForm/Recap'

const Requirements: FC<{ proposalRequest: ProposalRequest }> = ({ proposalRequest }) => {
    return (
        <Stack spacing={9}>
            <Recap formValues={proposalRequest} />
        </Stack>
    )
}

export default Requirements
