import { formatNumbers } from '@lune-fe/lune-components-lib'
import { Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'

type EmissionsByCarrier = {
    carrier: string
    totalTeu: number
    totalEmissions: number
    carbonIntensity: number
}

const Bar = ({
    maxValue,
    value,
    color,
    alignRight,
}: {
    maxValue: number
    value: number
    color: string
    alignRight?: boolean
}) => {
    const percentage = (value * 100) / maxValue

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: alignRight ? 'flex-end' : 'flex-start',
            }}
        >
            <Box
                sx={{
                    maxWidth: `${percentage}%`,
                    backgroundColor: color,
                    borderRadius: '8px',
                    height: '48px',
                    lineHeight: '48px',
                    animation: `fill .3s cubic-bezier(0, 0.21, 0.99, 0.79) forwards`,
                    display: 'flex',
                    justifyContent: alignRight ? 'flex-end' : 'flex-start',
                    alignItems: 'center',
                    pl: 1,
                    pr: 1,
                    whiteSpace: 'nowrap',

                    [`@keyframes fill`]: {
                        '0%': {
                            width: `0%`,
                        },
                        '100%': {
                            width: `100%`,
                        },
                    },
                }}
            >
                <Text variant={'button'}> {formatNumbers(value, 3)}</Text>
            </Box>
        </Box>
    )
}

export const RouterAndCarrierChart = ({
    data,
    highestCarrierByTeu,
    highestCarrierByEmissions,
}: {
    highestCarrierByTeu: number
    highestCarrierByEmissions: number
    data: {
        totalLegs: number
        emissionsByCarrier: Array<EmissionsByCarrier>
    }
}) => {
    const { emissionsByCarrier } = data

    return (
        <Box
            sx={{
                boxSizing: 'borderBox',
                '*': {
                    boxSizing: 'borderBox',
                },
            }}
        >
            {emissionsByCarrier.map((e, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        gap: 3,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '> div': {
                            pt: 1,
                            pb: 1,
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            '&:nth-child(1)': {
                                width: '32%',
                                justifyContent: 'flex-end',
                            },
                            '&:nth-child(2)': {
                                width: '18%',
                                justifyContent: 'center',
                            },
                            '&:nth-child(3)': {
                                width: '32%',
                            },
                            '&:nth-child(4)': {
                                width: '18%',
                                justifyContent: 'flex-end',
                            },
                        },
                    }}
                >
                    <Box>
                        <Bar
                            value={e.totalTeu}
                            maxValue={highestCarrierByTeu}
                            color={'#FF7759'}
                            alignRight={true}
                        />
                    </Box>
                    <Box>
                        <Text
                            variant={'body3'}
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {e.carrier}
                        </Text>
                    </Box>
                    <Box>
                        <Bar
                            value={e.totalEmissions}
                            maxValue={highestCarrierByEmissions}
                            color={'#0099F1'}
                        />
                    </Box>
                    <Box>
                        <Text variant={'body3'}>
                            {formatNumbers(e.carbonIntensity, 3)} gCO₂e/TEU-km
                        </Text>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}
