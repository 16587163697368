import {
    CalculatedRoute,
    ConvertedShipment,
    Distance,
    DistanceCalculationDetails,
    DistanceCalculationMethod,
    ElectricMethodMethodologyDetails,
    EmissionFactorWithGasEmissions,
    EstimateMassUnit,
    EstimateMassWithBreakdowns,
    LogisticsSiteMethod,
    Mass,
    MultiLegShippingEmissionEstimate,
    NullEnum,
    RoadMethodologyDetails,
    RouteInferenceDetails,
    RouteInferenceLegExplanation,
    Shipment,
    ShipmentConversionMethod,
    ShippingCountryCode,
    ShippingMethod,
    ShippingRoute,
    VesselInferenceMethod,
} from '@lune-climate/lune'
import Big from 'big.js'

import { convertToTonne, formatNumbers } from '../../../utils/utils'
import { MethodologyDetails } from '../useExplanationSteps'

import { resolvedLegToApiLeg } from './logisticsUtils'

export const shouldConvertToTwoDecimalPlaces = (value: string) => {
    return Number(value) > 0.01 ? 2 : undefined
}

export const formattedValue = (value: string): string => {
    return Big(value).lt(0.001)
        ? value
        : formatNumbers(value, shouldConvertToTwoDecimalPlaces(value)).toString()
}

export const getDistanceExplanationText = (method: DistanceCalculationMethod) => {
    switch (method) {
        case DistanceCalculationMethod.MAPBOX:
            return 'Mapbox'
        case DistanceCalculationMethod.SEA_DISTANCE_ALGORITHM:
            return 'our sea distance algorithm'
        case DistanceCalculationMethod.GREAT_CIRCLE_DISTANCE:
            return 'Great Circle Distance'
        case DistanceCalculationMethod.GREAT_CIRCLE_DISTANCE_X2:
            return 'Great Circle Distance x2'
        case DistanceCalculationMethod.OSRM:
            return 'Open Street Maps'
        case DistanceCalculationMethod.GOOGLE_MAPS:
            return 'Google Maps'
        case DistanceCalculationMethod.VESSEL_TRACKING:
        default:
            return ''
    }
}

// TODO @festina add a test for this
// exporting for unit test reasons
export const calculateEmissionFactorAmount = (
    convertedLoad: ConvertedShipment | NullEnum,
    emissionFactor: EmissionFactorWithGasEmissions,
    load: Shipment,
    distance: Distance | undefined, // distance can be undefined for logistics sites
    emissions: { amount: string; unit: EstimateMassUnit },
): Big => {
    // Since March 2024, the emission factor contains intensities
    if (emissionFactor.gasEmissions !== null) {
        return Big(emissionFactor.gasEmissions.co2E)
    }

    let localLoad: Big
    if (convertedLoad === null) {
        if (emissionFactor.denominatorUnit === 't*km' || emissionFactor.denominatorUnit === 't') {
            localLoad = convertToTonne(load.mass!)
        } else if (
            (emissionFactor.denominatorUnit === 'TEU*km' ||
                emissionFactor.denominatorUnit === 'TEU') &&
            'containers' in load
        ) {
            localLoad = Big(load.containers)
        } else {
            throw new Error(`Unexpected denominator unit: ${emissionFactor.denominatorUnit}`)
        }
    } else {
        if (convertedLoad.unit === 'TEU') {
            localLoad = Big(convertedLoad.amount)
        } else {
            localLoad = convertToTonne(convertedLoad as Mass)
        }
    }
    const emissionsToTonne = convertToTonne(emissions)
    const totalAmount = Big(emissionsToTonne)
        .div(Big(distance?.amount || 1))
        .div(localLoad)
    // convert to gram
    return totalAmount.mul(1000000)
}

type EmissionFactorSource =
    | 'epa'
    | 'exiobase'
    | 'ecoinvent'
    | 'beis'
    | 'lune'
    | 'glec'
    | 'emsa'
    | 'cbam'
    | 'ademe'
    | 'idemat'
    | 'iata'
export const emissionFactorLabel: { [key in EmissionFactorSource[number]]: string } = {
    epa: 'EPA',
    exiobase: 'EXIOBASE',
    ecoinvent: 'ecoinvent',
    beis: 'BEIS',
    lune: 'Lune',
    glec: 'GLEC',
    emsa: 'EMSA',
    cbam: 'CBAM',
    ademe: 'ADEME',
    idemat: 'IDEMAT',
    iata: 'IATA',
    ember: 'Ember',
}

export interface Leg {
    method: ShippingMethod | LogisticsSiteMethod
    route?: ShippingRoute
    countryCode?: ShippingCountryCode
    distance?: Distance
    adjustedDistance?: Distance
    distanceCalculationMethod: DistanceCalculationMethod | NullEnum
    distanceCalculationDetails: DistanceCalculationDetails | NullEnum
    convertedShipment: ConvertedShipment | NullEnum
    shipmentConversionMethod: ShipmentConversionMethod | NullEnum
    methodology: string[]
    emissionFactor: EmissionFactorWithGasEmissions
    mass: EstimateMassWithBreakdowns
    responseRoute: CalculatedRoute | null
    methodologyDetails:
        | MethodologyDetails
        | RoadMethodologyDetails
        | ElectricMethodMethodologyDetails
        | RouteInferenceDetails
        | null
    vesselInferenceDetails?: VesselInferenceMethod | null
    routeInferenceDetails?: RouteInferenceLegExplanation | null
}

export const flattenLegs = (estimate: MultiLegShippingEmissionEstimate): Leg[] => {
    const legs = []
    const requestLegs = estimate.request.legs
    const responseLegs = estimate.legs

    for (let i = 0; i < responseLegs.length; i++) {
        const responseLeg = responseLegs[i]
        const requestLeg = requestLegs[i]

        if (responseLeg.resolvedLegs) {
            legs.push(
                ...responseLeg.resolvedLegs.map((resolvedLeg, index) => {
                    const requestRoute = 'route' in requestLeg ? requestLeg.route : undefined
                    const legMethodologyDetails =
                        responseLeg.methodologyDetails &&
                        'routeInference' in responseLeg.methodologyDetails
                            ? responseLeg.methodologyDetails.routeInference[index]
                            : null

                    return resolvedLegToApiLeg(
                        resolvedLeg,
                        responseLeg.convertedShipment,
                        responseLeg.shipmentConversionMethod,
                        requestRoute,
                        legMethodologyDetails,
                    )
                }),
            )
            continue
        }
        const { route: responseRoute, ...responseWithoutRoute } = responseLeg
        legs.push({
            ...responseWithoutRoute,
            ...requestLegs[i],
            responseRoute,
        })
    }
    return legs
}
