import { Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'

import { assertType } from 'utils'
import RouteTypes, { RouteType } from 'views/CalculateEmissions/Logistics/RouteTypes'
import Labels from 'views/CalculateEmissions/RFQ/components/Labels'
import ParameterMapping from 'views/CalculateEmissions/RFQ/components/ParameterMapping'
import {
    CountryCode,
    countryCodeArray,
    PropertyMapping,
    Route,
    RouteMapping,
} from 'views/CalculateEmissions/RFQ/utils/sharedTypes'
import { RFQTemplateState } from 'views/CalculateEmissions/RFQ/utils/types'
import { inferPropertyMapping } from 'views/CalculateEmissions/RFQ/utils/utils'

type RouteProps = {
    label: string
    state: RFQTemplateState
    routeMapping: RouteMapping | undefined
    onUpdate: (mapping: RouteMapping | undefined) => void
    error?: string
}

const adaptRouteVal = (val: string): Route | undefined => {
    switch (val) {
        case 'address':
            return 'Address'
        case 'coordinate':
            return 'Geo coordinates'
        case 'portCode':
            return 'Port code'
        case 'airportCode':
            return 'Airport code'
        default:
            return undefined
    }
}

const adaptRouteValReverse = (val: string): RouteType | undefined => {
    switch (val) {
        case 'Address':
            return RouteType.ADDRESS
        case 'Geo coordinates':
            return RouteType.GEO_COORDINATES
        case 'Port code':
            return RouteType.SEAPORT
        case 'Airport code':
            return RouteType.AIRPORT
        default:
            return undefined
    }
}

// eslint-disable-next-line complexity
const RoutePicker = ({ label, state, routeMapping, onUpdate, error }: RouteProps) => {
    const handleRouteChange = (val: string | undefined) => {
        const route: Route | undefined = val ? adaptRouteVal(val) : undefined

        if (route === 'Address') {
            onUpdate({
                type: route,
                countryCode: inferPropertyMapping(
                    state,
                    'countryCode',
                    'mapping',
                    countryCodeArray,
                    label,
                ),
                city: state.isFullInference
                    ? inferPropertyMapping(state, 'city', 'direct', [], label)
                    : undefined,
                streetLine: state.isFullInference
                    ? inferPropertyMapping(state, 'streetLine', 'direct', [], label)
                    : undefined,
                postcode: state.isFullInference
                    ? inferPropertyMapping(state, 'postcode', 'direct', [], label)
                    : undefined,
            })
        } else if (route === 'Airport code') {
            onUpdate({
                type: route,
                airportCode: inferPropertyMapping(state, 'airportCode', 'direct', [], label),
            })
        } else if (route === 'Port code') {
            onUpdate({
                type: route,
                portCode: inferPropertyMapping(state, 'portCode', 'direct', [], label),
            })
        } else if (route === 'Geo coordinates') {
            onUpdate({
                type: route,
                lat: inferPropertyMapping(state, 'lat', 'direct', [], label),
                lon: inferPropertyMapping(state, 'lon', 'direct', [], label),
            })
        } else {
            assertType<undefined>(route)
            onUpdate(undefined)
        }
    }

    const updateRouteParameters = (
        parameterName: string,
        mapping: PropertyMapping<unknown> | undefined,
    ) => {
        onUpdate({
            ...routeMapping!,
            [parameterName]: mapping,
        } as RouteMapping)
    }

    // TODO Can we improve how `propertyMapping` on each is given? How about syncing here and `handleRouteChange` above?
    const routeParameters: Record<Route, any> = {
        Address: (
            <Box>
                <Labels />
                <ParameterMapping<CountryCode>
                    state={state}
                    parameter="countryCode"
                    mappingType="mapping"
                    outputValues={countryCodeArray}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Address'
                            ? routeMapping.countryCode
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
                <ParameterMapping<string>
                    state={state}
                    parameter="streetLine"
                    mappingType="direct"
                    outputValues={[]}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Address'
                            ? routeMapping.streetLine
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
                <ParameterMapping<string>
                    state={state}
                    parameter="city"
                    mappingType="direct"
                    outputValues={[]}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Address'
                            ? routeMapping.city
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
                <ParameterMapping<string>
                    state={state}
                    parameter="postcode"
                    mappingType="direct"
                    outputValues={[]}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Address'
                            ? routeMapping.postcode
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
            </Box>
        ),
        'Airport code': (
            <Box>
                <Labels />
                <ParameterMapping<string>
                    state={state}
                    parameter="airportCode"
                    mappingType="direct"
                    outputValues={[]}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Airport code'
                            ? routeMapping.airportCode
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
            </Box>
        ),
        'Port code': (
            <Box>
                <Labels />
                <ParameterMapping<string>
                    state={state}
                    parameter="portCode"
                    mappingType="direct"
                    outputValues={[]}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Port code'
                            ? routeMapping.portCode
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
            </Box>
        ),
        'Geo coordinates': (
            <Box>
                <Labels />
                <ParameterMapping<number>
                    state={state}
                    parameter="lat"
                    mappingType="direct"
                    outputValues={[]}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Geo coordinates'
                            ? routeMapping.lat
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
                <ParameterMapping<number>
                    state={state}
                    parameter="lon"
                    mappingType="direct"
                    outputValues={[]}
                    formatOutput={String}
                    propertyMapping={
                        routeMapping && routeMapping.type === 'Geo coordinates'
                            ? routeMapping.lon
                            : undefined
                    }
                    onUpdate={updateRouteParameters}
                />
            </Box>
        ),
    }

    return (
        <Stack
            direction="column"
            sx={{
                width: '100%',
            }}
            spacing={{
                xs: 4,
            }}
        >
            <Text variant={'h6'} sx={{ mb: 4 }}>
                {label}
            </Text>
            <Box sx={{ width: '100%', maxWidth: '414px' }}>
                <RouteTypes
                    error={error}
                    customLabel={`Select ${label} input`}
                    showAirportCode={true}
                    showPortCode={true}
                    value={
                        routeMapping?.type
                            ? adaptRouteValReverse(routeMapping.type as string)
                            : undefined
                    }
                    onChange={handleRouteChange}
                />
            </Box>
            {routeMapping !== undefined && routeParameters[routeMapping.type]}
        </Stack>
    )
}

export default RoutePicker
