import { AxiosError } from 'axios'
import { Workbook } from 'exceljs'

import { notify } from 'bugsnag'
import { uploadDataSheet } from 'endpoints/dapi'

type Info = {
    subject: string[]
    description?: string
}

export async function uploadRfqFile(
    workbook: Workbook,
    mappingId: string,
): Promise<{ result: 'error' | 'success'; info: Info }> {
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const file = new File([blob], 'sheet.xlsx', { type: blob.type })

    const formData = new FormData()
    formData.append('sheet', file)
    formData.append('options', JSON.stringify({ rfq_template_id: mappingId }))

    return uploadDataSheet(formData, 'rfq')
        .then(() => {
            return {
                result: 'success' as const,
                info: {
                    subject: [
                        `Upload complete.`,
                        `Processing your file, we’ll email you when ready.`,
                    ],
                    description: 'Should take a few minutes',
                },
            }
        })
        .catch((err) => {
            if (err instanceof AxiosError) {
                if (err.response?.status === 400) {
                    if (err.response.data.error.error_code === 'invalid_file_extension') {
                        return {
                            result: 'error',
                            info: {
                                subject: ['Upload failed', 'Please upload a .XLSX file'],
                            },
                        }
                    }
                    if (err.response.data.error.error_code === 'max_file_size_exceeded') {
                        return {
                            result: 'error',
                            info: {
                                subject: ['Upload failed', 'Max file size of 10MB exceeded'],
                            },
                        }
                    }
                    if (err.response.data.error.error_code === 'file_format_invalid') {
                        return {
                            result: 'error',
                            info: {
                                subject: [
                                    'Upload failed',
                                    'File format is invalid. Please upload a XLSX file.',
                                ],
                            },
                        }
                    }
                }
            }

            notify(err)
            return {
                result: 'error',
                info: {
                    subject: ['Unexpected upload failure'],
                    description: 'Please contact customer support if this keeps happening.',
                },
            }
        })
}
