import { PaymentMethod } from '@lune-fe/dapi-models'
import { styled } from '@mui/system'
import amex from 'payment-icons/min/flat/amex.svg'
import diners from 'payment-icons/min/flat/diners.svg'
import discover from 'payment-icons/min/flat/discover.svg'
import mastercard from 'payment-icons/min/flat/mastercard.svg'
import visa from 'payment-icons/min/flat/visa.svg'
import { FC, useMemo } from 'react'

export enum Brands {
    Visa = 'visa',
    MasterCard = 'mastercard',
    Amex = 'amex',
    Discover = 'discover',
    Diners = 'diners',
}

const PaymentMethodLogo: FC<{ paymentMethod: PaymentMethod }> = ({ paymentMethod }) => {
    const Image = styled('img')(({ theme }) =>
        theme.unstable_sx({
            width: `24px`,
        }),
    )

    const paymentMethodLogo = useMemo(() => {
        switch (paymentMethod.brand) {
            case Brands.Amex:
                return amex
            case Brands.MasterCard:
                return mastercard
            case Brands.Discover:
                return discover
            case Brands.Visa:
                return visa
            case Brands.Diners:
                return diners
            default:
                return ''
        }
    }, [paymentMethod])

    return <Image src={paymentMethodLogo} />
}

export default PaymentMethodLogo
