import { LoadMoreKeys, useLoadMoreState } from '@lune-fe/lune-components-lib'
import { Button, LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { MAIN_NAV_LOCATION_KEY } from 'appConstants'
import { useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import AccountSwitcher from 'components/AccountSwitcher'
import Divider from 'components/Divider'
import LinkTile from 'components/LinkTile'
import SVGLogo from 'components/SVGLogo'
import TestModeSwitch from 'components/TestModeSwitch'
import useAccounts from 'hooks/useAccounts'
import useMixpanel from 'hooks/useMixpanel'
import useScrollRestoration, { ScrollPositionKeys } from 'hooks/useScrollRestoration'
import { AccountType } from 'models/account'

const MainNavigation = () => {
    const location = useLocation()
    const { activeAccount } = useAccounts()
    const { palette, spacing } = LuneTheme
    const navigate = useNavigate()
    const mixpanel = useMixpanel()

    const NavigationSection = useMemo(
        () =>
            styled('div')(({ theme }) =>
                theme.unstable_sx({
                    p: 2,
                }),
            ),
        [],
    )

    const StyledTile = styled(LinkTile)(({ theme }) =>
        theme.unstable_sx({
            height: spacing(6),
            borderRadius: '24px',
        }),
    )

    const { clearScrollSession } = useScrollRestoration()
    const { clearLastCount } = useLoadMoreState()

    return (
        <Box
            data-testid={'navigation-list'}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                overflow: 'hidden',
                backgroundColor:
                    activeAccount?.type === AccountType.TEST ? palette.Yellow50 : `initial`,
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Box
                    sx={{
                        py: 0,
                        px: 3,
                        mb: 2,
                        mt: 4,
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Link
                        to="/"
                        onClick={() => clearScrollSession(ScrollPositionKeys.PROJECT_BUNDLES)}
                    >
                        <SVGLogo />
                    </Link>
                    <AccountSwitcher />
                </Box>
                <Box
                    sx={{
                        p: 3,
                        mb: 0,
                    }}
                >
                    <Button
                        data-testid={`buy-offsets-btn`}
                        leftIcon={<AddOutlinedIcon />}
                        onClick={() => {
                            mixpanel.track('buy_offset_clicked')
                            return navigate('/order')
                        }}
                    >
                        Fund Climate Projects
                    </Button>
                </Box>
                <NavigationSection>
                    <StyledTile
                        data-testid={`navigation-projects-btn`}
                        href={'/'}
                        onClickCallback={() =>
                            clearScrollSession(ScrollPositionKeys.PROJECT_BUNDLES)
                        }
                        icon={<PublicOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    >
                        <Text variant={`body3`}> Projects </Text>
                    </StyledTile>
                    <StyledTile
                        data-testid={`navigation-analytics-btn`}
                        href={'/analytics'}
                        icon={<BarChartOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    >
                        <Text variant={`body3`}> Analytics </Text>
                    </StyledTile>
                    <StyledTile
                        data-testid={`navigation-orders-btn`}
                        href={'/orders'}
                        icon={<ReceiptLongOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    >
                        <Text variant={`body3`}> Orders & Proposals </Text>
                    </StyledTile>
                </NavigationSection>
                <Divider />
                <NavigationSection>
                    <StyledTile
                        data-testid={`navigation-calc-emissions-btn`}
                        href={'/calculate-emissions'}
                        onClickCallback={() => {
                            clearLastCount(LoadMoreKeys.EMISSION_CALCULATION)
                            clearScrollSession(ScrollPositionKeys.EMISSION_CALCULATION)
                        }}
                        icon={<CalculateOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    >
                        <Text variant={`body3`}> Calculate Emissions </Text>
                    </StyledTile>
                    <StyledTile
                        data-testid={`navigation-go-to-market-btn`}
                        href={'/go-to-market'}
                        icon={<CampaignOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    >
                        <Text variant={`body3`}> Go-to-market </Text>
                    </StyledTile>
                    <StyledTile
                        data-testid={`navigation-developers-btn`}
                        href={'/developers'}
                        icon={<CodeOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    >
                        <Text variant={`body3`}> Developers </Text>
                    </StyledTile>
                    <StyledTile
                        data-testid={`navigation-settings-btn`}
                        href={`/settings`}
                        onClickCallback={() => {
                            localStorage.setItem(MAIN_NAV_LOCATION_KEY, location.pathname)
                        }}
                        icon={<SettingsOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    >
                        <Text variant={`body3`}> Settings </Text>
                    </StyledTile>
                </NavigationSection>
            </Box>
            <TestModeSwitch />
        </Box>
    )
}

export default MainNavigation
