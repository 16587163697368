import { TransactionDocumentEmissionEstimate } from '@lune-climate/lune'
import { Snippet, SnippetItem } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { useMemo } from 'react'

import Divider from '../../components/Divider'

import { InputWrapper } from './InputsWrapper'
import { TransactionDocumentEmissionsExplanationFallbackSection } from './TransactionDocumentEmissionsExplanationFallbackSection'

export const TransactionDocumentFallback = ({
    fallback,
    input,
    enableEmissionFactorLink,
}: {
    fallback: NonNullable<TransactionDocumentEmissionEstimate['fallback']>
    input: any
    enableEmissionFactorLink: boolean
}) => {
    const formattedInput = useMemo((): string => {
        return JSON.stringify(input, null, 2)
    }, [input])

    return (
        <>
            <Divider />
            <TransactionDocumentEmissionsExplanationFallbackSection
                fallback={fallback}
                enableEmissionFactorLink={enableEmissionFactorLink}
            />
            <Divider />
            <Box>
                <InputWrapper>
                    <Snippet header="JSON" sx={{ maxHeight: '480px' }}>
                        <SnippetItem language={'plain'} toCopy={formattedInput}>
                            {formattedInput}
                        </SnippetItem>
                    </Snippet>
                </InputWrapper>
            </Box>
        </>
    )
}

export default TransactionDocumentFallback
