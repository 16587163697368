import { ProposalResponse } from '@lune-fe/dapi-models'
import {
    BackButton,
    Button,
    ButtonGroup,
    LoadingWrapper,
    MainLayoutContainer,
    Text,
} from '@lune-fe/lune-ui-lib'
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined'
import { Box, Stack } from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useLocation, useNavigate } from 'react-router-dom'

import Divider from 'components/Divider'
import useActiveAccount from 'hooks/useActiveAccount'
import useBundles from 'hooks/useBundles'
import useMountEffect from 'hooks/useMountEffect'
import useScrollRestoration, { ScrollPositionKeys } from 'hooks/useScrollRestoration'
import ContactErikSection from 'views/Proposals/Proposal/ContactErikSection'
import FailedProposal from 'views/Proposals/Proposal/FailedProposal'
import Options from 'views/Proposals/Proposal/Options'
import Requirements from 'views/Proposals/Proposal/Requirements'
import TrustedByGlobalInnovatorsSection from 'views/Proposals/Proposal/TrustedByGlobalInnovatorsSection'

export enum ViewMode {
    OPTIONS = 'Options',
    REQUIREMENTS = 'Requirements',
}

const ProposalView: FC<{ proposal: ProposalResponse; scrollRef: RefObject<HTMLDivElement> }> = ({
    proposal,
    scrollRef,
}) => {
    const { activeAccount } = useActiveAccount()
    const { loading: loadingBundles } = useBundles()
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.OPTIONS)
    const navigate = useNavigate()
    const location = useLocation()
    const showBackButton = location.state?.fromProposalsList
    const { restoreScroll } = useScrollRestoration()
    const isFailedProposal = !proposal.rankedPortfolios?.length
    const { enqueueSnackbar: snackbar } = useSnackbar()

    useMountEffect(() => {
        restoreScroll(scrollRef, ScrollPositionKeys.PROPOSAL_VIEW)
    })

    return (
        <LoadingWrapper loading={!activeAccount || loadingBundles} sx={{ minHeight: '100vh' }}>
            <MainLayoutContainer
                headerComponent={
                    <>
                        {showBackButton && (
                            <BackButton sx={{ mb: 3, ml: -2 }} onClick={() => navigate(-1)} />
                        )}
                        <Box display="flex" flexDirection={'row'} justifyContent={'space-between'}>
                            <Box>
                                <Text variant={'h6'}>
                                    {moment(proposal.createdAt).format('DD MMMM, YYYY')}
                                </Text>
                                <Text variant={'h4'}>
                                    Portfolio proposal for {activeAccount?.name}
                                </Text>
                            </Box>
                            {!isFailedProposal && (
                                <CopyToClipboard
                                    text={`${window.location.origin}/proposal-preview/${proposal.id}`}
                                    onCopy={() => snackbar(`Public URL copied`)}
                                >
                                    <Button
                                        sx={{ minWidth: '110px' }}
                                        variant={'contained'}
                                        leftIcon={
                                            <ShortcutOutlinedIcon
                                                sx={{
                                                    width: `20px`,
                                                    height: `20px`,
                                                }}
                                            />
                                        }
                                    >
                                        Share externally
                                    </Button>
                                </CopyToClipboard>
                            )}
                        </Box>
                    </>
                }
            >
                <ButtonGroup
                    sx={{
                        mb: 9,
                    }}
                    items={[
                        {
                            label: ViewMode.OPTIONS,
                            value: ViewMode.OPTIONS,
                        },
                        {
                            label: ViewMode.REQUIREMENTS,
                            value: ViewMode.REQUIREMENTS,
                        },
                    ]}
                    onChange={(e) => {
                        setViewMode(e as ViewMode)
                    }}
                    value={viewMode}
                />
                {viewMode === ViewMode.OPTIONS ? (
                    <Stack spacing={9}>
                        {isFailedProposal ? (
                            <FailedProposal />
                        ) : (
                            <>
                                <Options
                                    options={proposal.rankedPortfolios!}
                                    proposalId={proposal.id}
                                    scrollRef={scrollRef}
                                />
                                <TrustedByGlobalInnovatorsSection />
                                <Divider />
                                <ContactErikSection />
                            </>
                        )}
                    </Stack>
                ) : (
                    <Requirements proposalRequest={proposal.userRequirements} />
                )}
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default ProposalView
