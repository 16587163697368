import { RfqTemplate } from '@lune-fe/dapi-models'
import { useQuery } from 'react-query'

import { getRfqTemplates } from 'endpoints/dapi'
import { queryKeys } from 'queryKeys'

const LIMIT = 100

const useMappings = () => {
    const {
        isLoading: loading,
        data: mappings = [],
        refetch,
    } = useQuery<RfqTemplate[]>(queryKeys.GET_MAPPING_TEMPLATE, async () => {
        let hasMoreLocal = true
        let templates: RfqTemplate[] = []
        while (hasMoreLocal) {
            const response = await getRfqTemplates({ limit: LIMIT })
            const { data, hasMore } = response
            hasMoreLocal = hasMore
            templates = [...templates, ...data]
        }
        return templates
    })

    return {
        refetch,
        loading,
        mappings,
    }
}

export default useMappings
