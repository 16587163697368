import {
    TransactionDocumentEmissionEstimateLineItem,
    TransactionDocumentEmissionEstimateLineItems,
} from '@lune-climate/lune'
import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import React, { FC, useState } from 'react'

import { getFormattedAmount } from '../../utils'

import { CategoryIcon } from './CategoryMetadata'
import { LegsScrollableContainer } from './Legs'

const { palette } = LuneTheme

const LineItemBox: FC<{
    lineItem: TransactionDocumentEmissionEstimateLineItem
    itemNumber: number
    active: boolean
    onClick: (legNumber: number, legElement: HTMLButtonElement) => void
    smallBoxes: boolean
}> = ({ lineItem, itemNumber, active, onClick, smallBoxes }) => {
    const lineItemFormattedAmount = (item: TransactionDocumentEmissionEstimateLineItem): string => {
        const formattedAmount = getFormattedAmount(item.mass.amount)
        return `${Number(formattedAmount.amountAsNumber).toFixed(
            2,
        )} ${formattedAmount.amountUnit}CO₂e`
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClick(itemNumber, event.currentTarget)
    }

    return (
        <Box
            data-testid={`lineItem-btn--${itemNumber}`}
            onClick={handleClick}
            component={'button'}
            sx={{
                background: active ? palette.Blue50 : palette.Grey100,
                mt: 1,
                mb: 1,
                height: '160px',
                borderRadius: '16px',
                p: 2,
                minWidth: '120px',
                maxWidth: active ? '320px' : smallBoxes ? '122px' : '160px',
                width: active ? '320px' : '100%',
                flex: '1 0 auto',
                transition: 'background .15s ease-in-out',
                overflow: 'hidden',
                cursor: 'pointer',
                border: 'none',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'space-between',
                justifyContent: 'space-between',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    padding: '10px 14px 10px 12px',
                    borderRadius: '40px',
                    background: active ? palette.Blue500 : palette.Grey300,
                    transition: 'background .15s ease-in-out',
                    color: active ? palette.White : palette.Grey900,
                    svg: {
                        height: '20px',
                        width: '20px',
                    },
                    span: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    },
                }}
            >
                <Text variant={'button'}>
                    <CategoryIcon name={lineItem.emissionFactor!.category ?? ''} /> Line item{' '}
                    {itemNumber}
                </Text>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                <Text
                    variant={'body2'}
                    sx={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {lineItem.item}
                </Text>
                <Text variant={'caption'} data-testid={`leg-emissions-amount-${itemNumber}`}>
                    {lineItemFormattedAmount(lineItem)}
                </Text>
            </Box>
        </Box>
    )
}

export const LineItems: FC<{
    lineItems: TransactionDocumentEmissionEstimateLineItems
    setActiveTab: (tabIndex: number) => void
}> = ({ lineItems, setActiveTab }) => {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(1)

    const handleLegClick = (legNumber: number, legElement: HTMLButtonElement) => {
        setActiveTabIndex(legNumber)
        setActiveTab(legNumber)

        legElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
        })
    }

    return (
        <LegsScrollableContainer>
            <Box
                sx={{
                    pr: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'flex-start',
                        gap: 1,
                        flexWrap: 'nowrap',
                        width: 'max-content',
                    }}
                >
                    {lineItems.map((lineItem, index) => {
                        return (
                            <LineItemBox
                                key={index}
                                lineItem={lineItem}
                                smallBoxes={lineItems.length > 5}
                                itemNumber={index + 1}
                                active={activeTabIndex === index + 1}
                                onClick={handleLegClick}
                            />
                        )
                    })}
                </Box>
            </Box>
        </LegsScrollableContainer>
    )
}
