import { Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC } from 'react'

const Labels: FC<{ customLeft?: string; customRight?: string }> = ({ customLeft, customRight }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 4,
                width: '100%',
            }}
        >
            <Text variant={'button'} sx={{ width: '45%' }}>
                {customLeft || 'Your selected columns'}
            </Text>
            <Box />
            {/* TODO @FESTINA this should be shown conditionally */}
            <Text className={'labels__right'} variant={'button'} sx={{ width: '45%' }}>
                {customRight || 'Lune field and values'}
            </Text>
        </Box>
    )
}

export default Labels
