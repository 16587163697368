import { MassUnit } from '@lune-climate/lune'
import { Text, ToggleTabs } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC } from 'react'

import EmissionPollutants from './EmissionPollutants'
import { EmissionsExplanationSectionProps } from './useExplanationSteps'
import WtwBreakdownExplanation from './WtwBreakdownExplanation'

const EmissionsBreakdowns: FC<{
    emissions: EmissionsExplanationSectionProps['emissions']
    emissionsUnit: MassUnit
    pdfStructure?: boolean
}> = ({ emissions, emissionsUnit, pdfStructure }) => {
    return (
        <>
            {!pdfStructure && emissions.wtwDetails && emissions.pollutants && (
                <Box>
                    <Text variant={'h6'} sx={{ mb: 4 }}>
                        Emissions breakdown
                    </Text>
                    <ToggleTabs>
                        <ToggleTabs.Tab label="By WTW state" value="wtw">
                            <WtwBreakdownExplanation
                                emissions={emissions}
                                emissionsUnit={emissionsUnit}
                                sx={{ mt: 4 }}
                            />
                        </ToggleTabs.Tab>
                        <ToggleTabs.Tab label="By pollutant" value="pollutant">
                            <EmissionPollutants
                                pollutants={emissions.pollutants}
                                emissionsUnit={emissionsUnit}
                                sx={{ mt: 4 }}
                            />
                        </ToggleTabs.Tab>
                    </ToggleTabs>
                </Box>
            )}
            {!pdfStructure && emissions.wtwDetails && !emissions.pollutants && (
                <Box>
                    <Text variant={'h6'} sx={{ mb: 4 }}>
                        Emissions breakdown
                    </Text>
                    <WtwBreakdownExplanation emissions={emissions} emissionsUnit={emissionsUnit} />
                </Box>
            )}
            {!pdfStructure && !emissions.wtwDetails && emissions.pollutants && (
                <Box>
                    <Text variant={'h6'} sx={{ mb: 4 }}>
                        Emissions breakdown
                    </Text>
                    <EmissionPollutants
                        pollutants={emissions.pollutants}
                        emissionsUnit={emissionsUnit}
                    />
                </Box>
            )}
            {pdfStructure && (
                <>
                    {emissions.wtwDetails && (
                        <Box>
                            <Text variant={'h6'} sx={{ mb: 4 }}>
                                Emissions breakdown
                            </Text>
                            <WtwBreakdownExplanation
                                emissions={emissions}
                                emissionsUnit={emissionsUnit}
                                sx={{ mt: 4 }}
                            />
                        </Box>
                    )}
                    {emissions.pollutants && (
                        <Box>
                            <Text variant={'h6'} sx={{ mb: 4 }}>
                                Pollutants
                            </Text>
                            <EmissionPollutants
                                pollutants={emissions.pollutants}
                                emissionsUnit={emissionsUnit}
                            />
                        </Box>
                    )}
                </>
            )}
        </>
    )
}

export default EmissionsBreakdowns
