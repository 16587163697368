import {
    BannerMessage,
    Button,
    DragAndDropFileUpload,
    LuneTheme,
    MainLayoutContainer,
    Text,
} from '@lune-fe/lune-ui-lib'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Stack } from '@mui/material'
import ExcelJS from 'exceljs'
import React, { useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useLocation, useNavigate } from 'react-router-dom'

import useDataSheets from 'hooks/useDataSheets'
import useMappings from 'hooks/useMappings'
import { assertType } from 'utils'
import ErrorsModal from 'views/CalculateEmissions/RFQ/modals/ErrorsModal'
import ExistingOrNewMapping, {
    SelectionResult,
} from 'views/CalculateEmissions/RFQ/modals/ExistingOrNewMapping'
import FileSettingsModal from 'views/CalculateEmissions/RFQ/modals/FileSettingsModal'
import ManageMappingTemplatesModal from 'views/CalculateEmissions/RFQ/modals/ManageMappingTemplatesModal'
import { uploadRfqFile } from 'views/CalculateEmissions/RFQ/utils/operationUtils'
import { RFQBaseData } from 'views/CalculateEmissions/RFQ/utils/types'
import SheetsTable from 'views/CalculateEmissions/Shared/SheetsTable'

const MAX_SIZE_BYTES = 10485760

const RFQ = () => {
    const { spacing } = LuneTheme
    const { refetch } = useDataSheets('rfq')
    const state = useLocation().state as RFQBaseData | undefined
    const navigate = useNavigate()
    const location = useLocation()
    const [isUploading, setUploading] = useState(false)
    const [errorsModal, setErrorsModal] = useState<
        { subject: string[]; description?: string } | undefined
    >(undefined)
    const [fileSettingsModalOpen, setFileSettingsModalOpen] = useState<boolean>(false)
    const [manageMappingsModalOpen, setManageMappingsModalOpen] = useState<boolean>(false)
    const [existingOrNewMappingModalOpen, setExistingOrNewMappingModalOpen] =
        useState<boolean>(false)
    const ref = React.useRef<HTMLDivElement>(null)

    const { mappings } = useMappings()

    const onUploadSheet = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        setUploading(true)
        if (rejectedFiles.length) {
            setErrorsModal({
                subject: ['Upload failed', 'Excel files only'],
            })

            return
        }

        // TODO upload file, maybe infer next properties immediatelly (sheet to use, header row etc.)
        const workbook = new ExcelJS.Workbook()
        acceptedFiles[0].arrayBuffer().then(async (arrayBuffer) => {
            await workbook.xlsx.load(arrayBuffer)
            navigate(location.pathname, {
                replace: true,
                state: { workbook },
            })
            if (mappings.length) {
                setExistingOrNewMappingModalOpen(true)
            } else {
                setFileSettingsModalOpen(true)
            }
        })
    }

    return (
        <>
            {fileSettingsModalOpen && (
                <FileSettingsModal
                    modalOpen={fileSettingsModalOpen}
                    onModalClose={() => {
                        setUploading(false)
                        setFileSettingsModalOpen(false)
                    }}
                />
            )}
            {manageMappingsModalOpen && (
                <ManageMappingTemplatesModal
                    modalOpen={manageMappingsModalOpen}
                    onModalClose={() => {
                        setManageMappingsModalOpen(false)
                    }}
                />
            )}
            {existingOrNewMappingModalOpen && (
                <ExistingOrNewMapping
                    modalOpen={existingOrNewMappingModalOpen}
                    onModalClose={async (result: SelectionResult | undefined) => {
                        if (result) {
                            if (result.newOrExisting === 'new') {
                                setFileSettingsModalOpen(true)
                            } else {
                                assertType<'existing'>(result.newOrExisting)
                                const uploadOperation = await uploadRfqFile(
                                    state!.workbook,
                                    result.selectedExistingMappingId!,
                                )
                                if (uploadOperation.result === 'success') {
                                    refetch()
                                }
                                setUploading(false)
                                setErrorsModal(uploadOperation.info)
                            }
                            setExistingOrNewMappingModalOpen(false)
                        } else {
                            // on Cancel button click
                            setUploading(false)
                        }
                    }}
                />
            )}
            {errorsModal && (
                <ErrorsModal
                    subject={errorsModal.subject}
                    description={errorsModal.description}
                    onClose={() => setErrorsModal(undefined)}
                />
            )}
            <MainLayoutContainer
                headerComponent={
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 6,
                            }}
                        >
                            <Button
                                variant="text"
                                iconButton
                                leftIcon={<ArrowBackOutlinedIcon />}
                                sx={{
                                    ml: -1.5,
                                }}
                                onClick={() => navigate('/calculate-emissions')}
                            />
                        </Box>
                        <Text variant={'h4'}>RFQ calculations</Text>
                        <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }} mt={1}>
                            Upload your RFQ excel data
                        </Text>
                    </Box>
                }
            >
                <Stack spacing={{ xs: 4, sm: 9, md: 9, lg: 9, xl: 9 }}>
                    <Box sx={{ height: spacing(40) }}>
                        <DragAndDropFileUpload
                            /*
              // Property 'ref' does not exist on type 'IntrinsicAttributes & { children?: ReactNode; isUploading?: boolean | undefined; } & Pick<HTMLProps<HTMLElement>, PropTypes> & { ...; }'.
              */
                            /* @ts-ignore: ... */
                            ref={ref}
                            accept={{
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                    ['.xlsx'],
                                'application/vnd.ms-excel': ['.xls'],
                            }}
                            maxSize={MAX_SIZE_BYTES}
                            isUploading={isUploading}
                            onDrop={onUploadSheet}
                            rejectDescription={`Excel files only, up to 2MB`}
                            description={`Drop your Excel file here`}
                        />
                    </Box>

                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                    >
                        <Button
                            leftIcon={<AddOutlinedIcon />}
                            onClick={() => {
                                if (ref.current) {
                                    ref.current.click()
                                }
                            }}
                        >
                            Upload file
                        </Button>
                        {!!mappings.length && (
                            <Button
                                variant={'outlined'}
                                leftIcon={<EditIcon />}
                                onClick={() => setManageMappingsModalOpen(true)}
                            >
                                Manage mappings
                            </Button>
                        )}
                        <Button
                            target="_blank"
                            leftIcon={<AutoStoriesOutlinedIcon />}
                            variant="outlined"
                            href="https://docs.lune.co/rfq"
                        >
                            Read guide
                        </Button>
                    </Stack>

                    <BannerMessage
                        message={`Calculations will count towards your tier limit, one per row`}
                    />

                    <SheetsTable type={'rfq'} />
                </Stack>
            </MainLayoutContainer>
        </>
    )
}

export default RFQ
