import { ShippingEmissionTimeseries, WtwBreakdownTimeseries } from '@lune-fe/dapi-models'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import * as icons from '@mui/icons-material'
import { Chart as ChartJS, TooltipItem } from 'chart.js'
import { toJpeg } from 'html-to-image'

import { CategoryName } from '../emissionsCalculationExplanation/CategoryMetadata'

export type IEmissionsTimeseries = ShippingEmissionTimeseries[0]
export type IWtwBreakdownTimeseries = WtwBreakdownTimeseries[0]
export type LogisticsEmissionsCategories = keyof Omit<IEmissionsTimeseries, 'date'>
export type WtwStage = keyof Omit<IWtwBreakdownTimeseries, 'date'>

export const chartColors: string[] = [
    '#0099F1',
    '#31B5FF',
    '#3BC9F0',
    '#3EDADA',
    '#69DFC9',
    '#A0EB9E',
    '#C4EB9E',
    '#FDF07D',
    '#FFE175',
    '#FFB967',
    '#FF9559',
    '#FF7759',
    '#FF4343',
    '#BDBDBD',
    '#FF627F',
    '#FF75AF',
    '#FF8BD8',
    '#E57EFF',
    '#B77EFF',
    '#787DF8',
    '#0075FF',
]

const _businessAnalyticsTonnes = [
    'agricultureTonnes',
    'contractorsTonnes',
    'airlinesTonnes',
    'carRentalsTonnes',
    'hotelsTonnes',
    'transportationTonnes',
    'utilitiesTonnes',
    'retailTonnes',
    'clothingTonnes',
    'otherShopsTonnes',
    'businessServicesTonnes',
    'professionalServicesTonnes',
    'governmentTonnes',
    'uncategorizedTonnes',
    'otherServicesTonnes',
    'hospitalityServicesTonnes',
    'storageTonnes',
    'materialsManufactureTonnes',
    'landConstructionsTonnes',
    'electronicsTonnes',
    'wasteAndGassesTonnes',
] as const
export type BusinessEmissionsCategories = (typeof _businessAnalyticsTonnes)[number]

export const mccToBusinessCategory = (mcc: number): BusinessEmissionsCategories => {
    if (mcc <= 1499) {
        return 'agricultureTonnes'
    } else if (mcc <= 2999) {
        return 'contractorsTonnes'
    } else if (mcc <= 3299) {
        return 'airlinesTonnes'
    } else if (mcc <= 3499) {
        return 'carRentalsTonnes'
    } else if (mcc <= 3999) {
        return 'hotelsTonnes'
    } else if (mcc <= 4799) {
        return 'transportationTonnes'
    } else if (mcc <= 4999) {
        return 'utilitiesTonnes'
    } else if (mcc <= 5599) {
        return 'retailTonnes'
    } else if (mcc <= 5699) {
        return 'clothingTonnes'
    } else if (mcc <= 7299) {
        return 'otherShopsTonnes'
    } else if (mcc <= 7999) {
        return 'businessServicesTonnes'
    } else if (mcc <= 8999) {
        return 'professionalServicesTonnes'
    } else if (mcc <= 9999) {
        return 'governmentTonnes'
    } else {
        throw new Error(`Invalid MCC ${mcc}`)
    }
}
export const businessCategoryToChartColour: Record<BusinessEmissionsCategories, string> = {
    agricultureTonnes: chartColors[0],
    contractorsTonnes: chartColors[1],
    airlinesTonnes: chartColors[2],
    carRentalsTonnes: chartColors[3],
    hotelsTonnes: chartColors[4],
    transportationTonnes: chartColors[5],
    utilitiesTonnes: chartColors[6],
    retailTonnes: chartColors[7],
    clothingTonnes: chartColors[8],
    otherShopsTonnes: chartColors[9],
    businessServicesTonnes: chartColors[10],
    professionalServicesTonnes: chartColors[11],
    governmentTonnes: chartColors[12],
    uncategorizedTonnes: chartColors[13],
    otherServicesTonnes: chartColors[14],
    hospitalityServicesTonnes: chartColors[15],
    storageTonnes: chartColors[16],
    materialsManufactureTonnes: chartColors[17],
    landConstructionsTonnes: chartColors[18],
    electronicsTonnes: chartColors[19],
    wasteAndGassesTonnes: chartColors[20],
}

export const businessCategoryToChartLabels: Record<BusinessEmissionsCategories, string> = {
    agricultureTonnes: 'Agriculture & farming',
    contractorsTonnes: 'Contractors',
    airlinesTonnes: 'Airlines',
    carRentalsTonnes: 'Car rentals',
    hotelsTonnes: 'Hotels',
    transportationTonnes: 'Transportation',
    utilitiesTonnes: 'Utilities',
    retailTonnes: 'Retail & grocery',
    clothingTonnes: 'Clothing',
    otherShopsTonnes: 'Other shops',
    businessServicesTonnes: 'Business services',
    professionalServicesTonnes: 'Professional services & orgs.',
    governmentTonnes: 'Government',
    uncategorizedTonnes: 'Not categorised',
    otherServicesTonnes: 'Other services',
    hospitalityServicesTonnes: 'Hospitality services',
    storageTonnes: 'Storage & warehousing',
    materialsManufactureTonnes: 'Materials & manufacturing',
    landConstructionsTonnes: 'Land use & construction',
    electronicsTonnes: 'Electronics',
    wasteAndGassesTonnes: 'Waste & gases',
}

export const businessCategoryToIcons: Record<BusinessEmissionsCategories, icons.SvgIconComponent> =
    {
        agricultureTonnes: icons.AgricultureOutlined,
        contractorsTonnes: icons.HandymanOutlined,
        airlinesTonnes: icons.FlightOutlined,
        carRentalsTonnes: icons.DirectionsCarOutlined,
        hotelsTonnes: icons.HotelOutlined,
        transportationTonnes: icons.RouteOutlined,
        utilitiesTonnes: icons.BoltOutlined,
        retailTonnes: icons.ShoppingCartOutlined,
        clothingTonnes: icons.CheckroomOutlined,
        otherShopsTonnes: icons.StorefrontOutlined,
        businessServicesTonnes: icons.BusinessOutlined,
        professionalServicesTonnes: icons.BadgeOutlined,
        governmentTonnes: icons.AccountBalanceOutlined,
        uncategorizedTonnes: icons.CategoryOutlined,
        otherServicesTonnes: icons.BusinessOutlined,
        hospitalityServicesTonnes: icons.HotelOutlined,
        storageTonnes: icons.WarehouseOutlined,
        materialsManufactureTonnes: icons.PrecisionManufacturingOutlined,
        landConstructionsTonnes: icons.HandymanOutlined,
        electronicsTonnes: icons.DevicesOutlined,
        wasteAndGassesTonnes: icons.DeleteOutlined,
    }

export const chartAreaBorder = {
    id: 'chartAreaBorder',
    afterDatasetDraw(chart: ChartJS) {
        const {
            ctx,
            chartArea: { top, bottom, left, right, width, height },
        } = chart
        ctx.save()

        ctx.strokeStyle = '#e9e9e9'
        ctx.strokeRect(right - 1, top, 0, height)
        ctx.strokeRect(left - 1, top, width, 0)
        ctx.strokeRect(left - 1, bottom, width, 0)
        ctx.strokeRect(left - 1, top, 0, height)
        ctx.restore()
    },
}

export const emissionsChartColors: Record<LogisticsEmissionsCategories, string> = {
    sea: '#0099F1',
    air: '#3BC9F0',
    road: '#69DFC9',
    rail: '#C4EB9E',
    inlandWaterway: '#FDF07D',
    logisticSite: '#FFE175',
}

export const wtwBreakdownChartColors: Record<WtwStage, string> = {
    wtt: '#0099F1',
    ttw: '#3BC9F0',
    unknown: '#BDBDBD',
}

export const wtwBreakdownChartLabels: Record<WtwStage, string> = {
    wtt: 'WTT',
    ttw: 'TTW',
    unknown: 'Unknown',
}

export const emissionsChartLabels: Record<LogisticsEmissionsCategories, string> = {
    sea: 'Sea',
    air: 'Air',
    road: 'Road',
    rail: 'Rail',
    inlandWaterway: 'Inland waterway',
    logisticSite: 'Logistics sites',
}
export const logisticsChartOrdering: Record<LogisticsEmissionsCategories, number> = {
    sea: 0,
    air: 1,
    road: 2,
    rail: 3,
    inlandWaterway: 4,
    logisticSite: 5,
}

export const wtwBreakdownChartOrdering: Record<WtwStage, number> = {
    wtt: 0,
    ttw: 1,
    unknown: 2,
}

export const downloadImageFromHtml = (element: HTMLElement, fileName?: string) => {
    toJpeg(element, {
        backgroundColor: 'white',
        skipAutoScale: false,
        type: 'image/jpeg',
    })
        .then((dataUrl) => {
            console.log(dataUrl)
            const link = document.createElement('a')
            link.download = fileName || 'image.jpeg'
            link.href = dataUrl
            link.click()
        })
        .catch((err) => {
            console.log(err)
        })
}

export const padLeftNumber = (arr: number[]): number[] => [0, ...arr]
export const padLeftString = (arr: string[]): string[] => ['', ...arr]

export interface DoughnutDatapoint {
    label: BusinessEmissionsCategories
    value: number
    percentage: string
}

const { palette } = LuneTheme
export const doughnutChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            backgroundColor: palette.Grey900,
            displayColors: false,
            callbacks: {
                title: () => '',
                label: (tooltipItem: TooltipItem<any>) => {
                    const data = tooltipItem.dataset.data[
                        tooltipItem.dataIndex
                    ] as DoughnutDatapoint
                    return `${data.percentage}% ${businessCategoryToChartLabels[data.label]}, ${
                        tooltipItem.formattedValue
                    } tCO₂e`
                },
            },
        },
    },
}

// eslint-disable-next-line complexity
export function getLuneCategoryEmissionsGroup(category: CategoryName): BusinessEmissionsCategories {
    // Agriculture Sector
    if (
        [
            'agriculture',
            'livestock',
            'plants',
            'fishing activities',
            'forestry activities',
        ].includes(category)
    ) {
        return 'agricultureTonnes'
    }
    // Business Services
    if (
        [
            'advertising services',
            'cloud computing',
            'legal services',
            'publishing services',
            'business services',
        ].includes(category)
    ) {
        return 'businessServicesTonnes'
    }
    // Clothing
    if (['clothing', 'footwear', 'textiles'].includes(category)) {
        return 'clothingTonnes'
    }
    // Electronics
    if (['electrical equipment', 'electronic products'].includes(category)) {
        return 'electronicsTonnes'
    }
    // Governmental Services
    if (['government services', 'cultural services', 'education services'].includes(category)) {
        return 'governmentTonnes'
    }
    // Hospitality Services
    if (['accommodation', 'catering services', 'restaurants'].includes(category)) {
        return 'governmentTonnes'
    }
    // Land Use and Construction
    if (
        ['mining activities', 'land use change', 'construction', 'infrastructure'].includes(
            category,
        )
    ) {
        return 'landConstructionsTonnes'
    }
    // Materials and Manufacturing
    if (
        [
            'construction materials',
            'metals',
            'metal manufacturing',
            'natural materials',
            'wood materials',
            'food processing',
            'ceramic products',
            'chemicals and chemical products',
            'machinery',
            'paint',
            'glass',
            'plastics',
            'packaging',
        ].includes(category)
    ) {
        return 'materialsManufactureTonnes'
    }
    // Other Services
    if (
        [
            'domestic services',
            'vehicle maintenance and services',
            'recreational services',
            'gardening services',
        ].includes(category)
    ) {
        return 'otherServicesTonnes'
    }
    // Professional Services
    if (
        [
            'financial services',
            'information and communication services',
            'medical services',
        ].includes(category)
    ) {
        return 'professionalServicesTonnes'
    }
    // Retail and Groceries
    if (
        [
            'consumer goods rental',
            'sports products',
            'beverages: alcoholic',
            'beverages: non-alcoholic',
            'dairy products',
            'fish and seafood',
            'fruits and vegetables',
            'grains and grain products',
            'meat, poultry and eggs',
            'nuts and seeds',
            'oils and fats',
            'ready-made food and other food products',
            'sugar, confectionery and desserts',
            'furniture',
            'home appliances',
            'medical products',
            'office supplies',
            'paper products',
            'personal care products',
            'pharmaceutical products',
            'tobacco products',
        ].includes(category)
    ) {
        return 'retailTonnes'
    }
    // Storage and Warehousing
    if (['storage'].includes(category)) {
        return 'storageTonnes'
    }
    // Transportation
    if (
        [
            'air transportation',
            'rail transportation',
            'road transportation',
            'water transportation',
            'vehicle parts',
        ].includes(category)
    ) {
        return 'transportationTonnes'
    }
    // Utilities
    if (['fuel', 'heat and steam', 'utilities', 'water'].includes(category)) {
        return 'utilitiesTonnes'
    }
    // Waste and Gasses
    if (
        [
            'refrigerants and fugitive gases',
            'commercial and industrial waste',
            'construction waste',
            'household waste',
            'waste management',
        ].includes(category)
    ) {
        return 'wasteAndGassesTonnes'
    }

    // Should never reach, all category names should be covered above.
    throw new Error('uncategorised_category')
}
