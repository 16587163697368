import { Organisation } from '@lune-fe/dapi-models'
import { Button, ListItemLayout, Tile } from '@lune-fe/lune-ui-lib'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import { useMemo } from 'react'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'

const BillingInfoTile = ({
    organisation,
    isReadOnly,
    onEdit,
}: {
    organisation: Organisation
    isReadOnly: boolean
    onEdit: () => void
}) => {
    const isExistingBillingConfig = useMemo(
        () => organisation.billingEmail && organisation.billingName && organisation.countryName,
        [organisation],
    )

    const getInvoiceSubtitle = useMemo(() => {
        if (!isExistingBillingConfig) {
            return
        }

        const o = organisation
        const st = [o.legalName, o.street1, o.street2, o.postcode, o.city, o.countryName, o.vat]
            .filter((e) => e)
            .join(', ')

        return (
            <div>
                {st}
                <br />
                {o.billingEmail}
            </div>
        )
    }, [isExistingBillingConfig, organisation])

    return isExistingBillingConfig ? (
        <Tile
            button={
                <ConditionalTooltip show={isReadOnly} tooltip={NotAdminTooltip}>
                    <Button
                        data-testid={`changeBilling`}
                        variant={'text'}
                        onClick={onEdit}
                        disabled={isReadOnly}
                    >
                        Change
                    </Button>
                </ConditionalTooltip>
            }
        >
            <ListItemLayout
                image={<ReceiptLongOutlinedIcon />}
                title={`Invoice to ${organisation.billingName}`}
                subTitle={getInvoiceSubtitle}
            />
        </Tile>
    ) : (
        <Tile
            button={
                <ConditionalTooltip show={isReadOnly} tooltip={NotAdminTooltip}>
                    <Button
                        data-testid={`setupBilling`}
                        variant={'text'}
                        onClick={onEdit}
                        disabled={isReadOnly}
                    >
                        Set up
                    </Button>
                </ConditionalTooltip>
            }
        >
            <ListItemLayout
                image={<ReceiptLongOutlinedIcon />}
                title={'Set up organisation billing info'}
            />
        </Tile>
    )
}

export default BillingInfoTile
