import { Organisation, PatchOrganisationRequest } from '@lune-fe/dapi-models'
import { Button, LoadingWrapper, Modal, StandardModalHeader } from '@lune-fe/lune-ui-lib'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

import { patchOrganisation } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import { useIsAuthorised } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'
import BillingInfoForm from 'views/BillingInfo/BillingInfoForm'
import BillingInfoTile from 'views/BillingInfo/BillingInfoTile'

export interface BillingInfoPayload {
    billingName: string
    billingEmail: string
    legalName?: string
    street1?: string
    street2?: string
    postcode?: string
    city?: string
    country?: string
    vat?: string
}

export enum FormKeys {
    BILLING_NAME = 'billingName',
    BILLING_EMAIL = 'billingEmail',
    LEGAL_NAME = 'legalName',
    ADDRESS_LINE_1 = 'street1',
    ADDRESS_LINE_2 = 'street2',
    POSTCODE = 'postcode',
    CITY = 'city',
    COUNTRY = 'countryCode',
    VAT = 'vat',
}

const BillingInfo = () => {
    const isAuthorised = useIsAuthorised()
    const [openInvoiceTo, setOpenInvoiceTo] = useState<boolean>(false)
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { activeOrg, refetchActiveOrg, loadingActiveOrg } = useActiveOrganisation()

    const onUpdateBillingInfo = (formValues: BillingInfoPayload) => {
        const existingBillingValues = activeOrg!

        // Handle case where user set existing value back to empty (null)
        const explicitlyEmptyFormValues = Object.values(FormKeys).reduce(
            (acc, curr: keyof Organisation) => {
                if (existingBillingValues[curr] && !formValues[curr as keyof BillingInfoPayload]) {
                    acc[curr] = null
                }
                return acc
            },
            {} as Record<string, string | null>,
        )

        const updatedDetails: PatchOrganisationRequest = {
            ...formValues,
            ...explicitlyEmptyFormValues,
        }

        if (activeOrg) {
            patchOrganisation(activeOrg.id, updatedDetails)
                .then(() => {
                    refetchActiveOrg()
                    setOpenInvoiceTo(false)
                    snackbar(SnackbarMessages.SETTINGS_SAVED)
                })
                .catch(() => {
                    snackbar(SnackbarMessages.SETTINGS_UPDATE_FAILED)
                })
        } else {
            snackbar(SnackbarMessages.SETTINGS_UPDATE_FAILED)
        }
    }

    return (
        <>
            <LoadingWrapper
                sx={{
                    height: `97px`,
                }}
                loading={loadingActiveOrg || !activeOrg}
            >
                {activeOrg && (
                    <BillingInfoTile
                        organisation={activeOrg}
                        isReadOnly={isAuthorised === undefined ? true : !isAuthorised}
                        onEdit={() => setOpenInvoiceTo(true)}
                    />
                )}
            </LoadingWrapper>

            <Modal
                disableEscapeKeyDown
                maxWidth={'sm'}
                fullWidth
                open={openInvoiceTo}
                onClose={() => {}}
                header={
                    <StandardModalHeader
                        title={`Organisation billing info`}
                        subTitle={`Applies to all purchases made with this organisation`}
                    />
                }
            >
                <LoadingWrapper loading={loadingActiveOrg || !activeOrg}>
                    {activeOrg && (
                        <BillingInfoForm
                            onSubmit={onUpdateBillingInfo}
                            cancelButton={
                                <Button
                                    variant={'outlined'}
                                    wide
                                    onClick={() => setOpenInvoiceTo(false)}
                                >
                                    Cancel
                                </Button>
                            }
                            organisation={activeOrg}
                        />
                    )}
                </LoadingWrapper>
            </Modal>
        </>
    )
}

export default BillingInfo
