import { Layout } from '@lune-fe/lune-ui-lib'
import OnDashboardInitialRender from 'app/OnDashboardInitialRender'
import PrivateRoutes from 'PrivateRoutes'
import { useRef } from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
    RouterProvider,
    Routes,
} from 'react-router-dom'

import DevelopmentBanner from 'components/DevelopmentBanner'
import MainNavigation from 'components/MainNavigation'
import RouteChangeInterceptor from 'components/RouteChangeInterceptor'
import SettingsNavigation from 'components/SettingsNavigation'
import useTitle from 'hooks/useTitle'
import useToken from 'hooks/useToken'
import ForgotPassword from 'views/Account/ForgotPassword/ForgotPassword'
import Login from 'views/Account/Login/Login'
import ResetPassword from 'views/Account/ResetPassword/ResetPassword'
import Signup from 'views/Account/Signup/Signup'
import VerifyEmailConfirmation from 'views/Account/VerifyEmailConfirmation/VerifyEmailConfirmation'
import BuildPortfolio from 'views/BuyOffsets/BuildPortfolio'
import BuyOffsets from 'views/BuyOffsets/BuyOffsets'
import OrderSummary from 'views/BuyOffsets/OrderSummary/OrderSummary'
import {
    CalculateEmissions,
    CompanyCarbonFootprint,
    CompanyCarbonFootprintResults,
    EverydayPurchases,
    FintechSheets,
    LogisticsEmissions,
    LogisticsResults,
    LogisticsSheets,
    TravelEmissions,
} from 'views/CalculateEmissions'
import EmissionFactorEstimateResults from 'views/CalculateEmissions/EmissionFactorEstimate/EmissionFactorEstimateResults'
import EverydayPurchasesResults from 'views/CalculateEmissions/EverydayPurchases/EverydayPurchasesResults'
import MultiLegLogisticsResults from 'views/CalculateEmissions/MultiLegLogistics/MultiLegLogisticsResults'
import PassengerTransportationEstimateResults from 'views/CalculateEmissions/PassengerTransportation/PassengerTransportationEstimateResults'
import RFQ from 'views/CalculateEmissions/RFQ/RFQ'
import ShipmentLoad from 'views/CalculateEmissions/RFQ/ShipmentLoad'
import TemplateLeg from 'views/CalculateEmissions/RFQ/TemplateLeg'
import TransactionDocumentResults from 'views/CalculateEmissions/TransactionDocument/TransactionDocumentResults'
import Dashboard from 'views/Dashboard/Dashboard'
import CreateApiKey from 'views/Developers/APIKeys/CreateApiKey'
import Developers from 'views/Developers/Developers'
import CreateWebhook from 'views/Developers/Webhooks/CreateWebhook'
import BrowseEmissionFactors from 'views/EmissionFactors/BrowseEmissionFactors'
import EmissionFactorDetails from 'views/EmissionFactors/EmissionFactor/EmissionFactorDetails'
import NotFoundPage from 'views/ErrorPages/404Page'
import ErrorPage from 'views/ErrorPages/ErrorPage'
import GoToMarket from 'views/GoToMarket/GoToMarket'
import Order from 'views/Orders/Order/Order'
import Orders from 'views/Orders/Orders'
import Project from 'views/Projects/Project/Project'
import ProjectBundles from 'views/Projects/ProjectBundles/ProjectBundles'
import CreateProposalRequest from 'views/Proposals/CreateProposalRequest'
import Proposal from 'views/Proposals/Proposal/Proposal'
import Proposals from 'views/Proposals/Proposals'
import ProposalPreview from 'views/Proposals/PublicProposal/ProposalPreview'
import { ReduceEmissions } from 'views/ReduceEmissions/ReduceEmissions'
import Account from 'views/Settings/Account/Account'
import Billing from 'views/Settings/Billing/Billing'
import ClientAccount from 'views/Settings/ClientAccount/ClientAccount'
import ClientAccounts from 'views/Settings/ClientAccounts/ClientAccounts'
import CreateAccount from 'views/Settings/CreateAccount/CreateAccount'
import OrganisationSettings from 'views/Settings/OrganisationSettings/OrganisationSettings'
import SustainabilityPageSettings from 'views/SustainabilityPage/SustainabilityPageSettings'
import Widgets from 'views/Widgets/Widgets'

import 'app/DashboardApp.css'

export const MAIN_CONTENT_CONTAINER_ID = `mainContent`

function App() {
    const { setToken } = useToken()
    useTitle('Lune - Dashboard')
    const scrollRef = useRef<HTMLDivElement>(null)
    const settingsPagePath = '/settings'
    const defaultPagePath = ''

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                path={'*'}
                element={
                    <RouteChangeInterceptor>
                        <Routes>
                            <Route path="/login" element={<Login setToken={setToken} />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password/:id" element={<ResetPassword />} />
                            <Route path="/signup" element={<Signup setToken={setToken} />} />
                            <Route
                                path="/proposal-preview/:proposalId"
                                element={<ProposalPreview />}
                            />
                            <Route
                                path="/verify-email/:token"
                                element={<VerifyEmailConfirmation />}
                            />

                            <Route
                                path={'*'}
                                element={
                                    <PrivateRoutes>
                                        <>
                                            <OnDashboardInitialRender />
                                            <Routes>
                                                <Route
                                                    path={`${settingsPagePath}/*`}
                                                    element={
                                                        <Layout
                                                            ref={scrollRef}
                                                            sideBar={<SettingsNavigation />}
                                                            isResponsive
                                                        >
                                                            <Routes>
                                                                <Route
                                                                    path={``}
                                                                    element={
                                                                        <OrganisationSettings />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`billing`}
                                                                    element={<Billing />}
                                                                />
                                                                <Route
                                                                    path={`payment-methods`}
                                                                    element={
                                                                        <Navigate to={'billing'} />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`client-accounts`}
                                                                    element={<ClientAccounts />}
                                                                />
                                                                <Route
                                                                    path={`client-accounts/:accountId`}
                                                                    element={<ClientAccount />}
                                                                />
                                                                <Route
                                                                    path={`accounts/new`}
                                                                    element={<CreateAccount />}
                                                                />
                                                                <Route
                                                                    path={`accounts/:accountId`}
                                                                    element={<Account />}
                                                                />
                                                            </Routes>
                                                        </Layout>
                                                    }
                                                />

                                                <Route
                                                    path={`${defaultPagePath}/*`}
                                                    element={
                                                        <Layout
                                                            ref={scrollRef}
                                                            sideBar={<MainNavigation />}
                                                            isResponsive
                                                        >
                                                            <Routes>
                                                                <Route
                                                                    path={''}
                                                                    element={
                                                                        <ProjectBundles
                                                                            scrollRef={scrollRef}
                                                                        />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`projects`}
                                                                    element={
                                                                        <ProjectBundles
                                                                            scrollRef={scrollRef}
                                                                        />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`dashboard`}
                                                                    element={<Dashboard />}
                                                                />
                                                                <Route
                                                                    path={`analytics`}
                                                                    element={<Dashboard />}
                                                                />
                                                                <Route
                                                                    path={`orders`}
                                                                    element={
                                                                        <>
                                                                            <Proposals />
                                                                            <Orders />
                                                                        </>
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`orders/:orderId`}
                                                                    element={<Order />}
                                                                />
                                                                <Route
                                                                    path={`developers`}
                                                                    element={<Developers />}
                                                                />
                                                                <Route
                                                                    path={`developers/new-api-key`}
                                                                    element={<CreateApiKey />}
                                                                />
                                                                <Route
                                                                    path={`developers/new-webhook`}
                                                                    element={<CreateWebhook />}
                                                                />
                                                                <Route
                                                                    path={`order`}
                                                                    element={
                                                                        <BuyOffsets
                                                                            scrollRef={scrollRef}
                                                                        />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`proposal-request`}
                                                                    element={
                                                                        <CreateProposalRequest
                                                                            scrollRef={scrollRef}
                                                                        />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`proposal/:proposalId`}
                                                                    element={
                                                                        <Proposal
                                                                            scrollRef={scrollRef}
                                                                        />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`order/build-portfolio`}
                                                                    element={<BuildPortfolio />}
                                                                />
                                                                <Route
                                                                    path={`order/summary`}
                                                                    element={
                                                                        <OrderSummary
                                                                            scrollRef={scrollRef}
                                                                        />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`projects/:projectSlug`}
                                                                    element={
                                                                        <Project
                                                                            scrollRef={scrollRef}
                                                                        />
                                                                    }
                                                                />

                                                                <Route
                                                                    path={`calculate-emissions/*`}
                                                                    element={
                                                                        <Routes>
                                                                            <Route
                                                                                path={''}
                                                                                element={
                                                                                    <CalculateEmissions
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/company-carbon-footprint'
                                                                                }
                                                                                element={
                                                                                    <CompanyCarbonFootprint
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/company-carbon-footprint/:id'
                                                                                }
                                                                                element={
                                                                                    <CompanyCarbonFootprint
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/company-carbon-footprint/:id/results`}
                                                                                element={
                                                                                    <CompanyCarbonFootprintResults
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/logistics-emissions'
                                                                                }
                                                                                element={
                                                                                    <LogisticsEmissions
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/logistics-emissions/:id'
                                                                                }
                                                                                element={
                                                                                    <LogisticsEmissions
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/logistics-sheets'
                                                                                }
                                                                                element={
                                                                                    <LogisticsSheets />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`logistics-sheets/:logisticsSheetId/download`}
                                                                                element={
                                                                                    <LogisticsSheets />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/fintech-sheets'
                                                                                }
                                                                                element={
                                                                                    <FintechSheets />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`fintech-sheets/:fintechSheetId/download`}
                                                                                element={
                                                                                    <FintechSheets />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={'/rfq'}
                                                                                element={<RFQ />}
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/rfq/templates/load'
                                                                                }
                                                                                element={
                                                                                    <ShipmentLoad />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={
                                                                                    '/rfq/templates/leg'
                                                                                }
                                                                                element={
                                                                                    <TemplateLeg />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/logistics-emissions/:id/results`}
                                                                                element={
                                                                                    <LogisticsResults
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/everyday-purchases`}
                                                                                element={
                                                                                    <EverydayPurchases
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/everyday-purchases/:id`}
                                                                                element={
                                                                                    <EverydayPurchases
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/everyday-purchases/:id/results`}
                                                                                element={
                                                                                    <EverydayPurchasesResults
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/transaction-documents/:id/results`}
                                                                                element={
                                                                                    <TransactionDocumentResults
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/activity/:id/results`}
                                                                                element={
                                                                                    <EmissionFactorEstimateResults
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/passenger-transportation/:id/results`}
                                                                                element={
                                                                                    <PassengerTransportationEstimateResults
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/multi-leg-shipping/:id/results`}
                                                                                element={
                                                                                    <MultiLegLogisticsResults
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />

                                                                            <Route
                                                                                path={`/travel-emissions`}
                                                                                element={
                                                                                    <TravelEmissions
                                                                                        scrollRef={
                                                                                            scrollRef
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />
                                                                        </Routes>
                                                                    }
                                                                />

                                                                <Route
                                                                    path={`emission-factors`}
                                                                    element={
                                                                        <BrowseEmissionFactors />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`emission-factors/:id`}
                                                                    element={
                                                                        <EmissionFactorDetails />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`go-to-market`}
                                                                    element={<GoToMarket />}
                                                                />
                                                                <Route
                                                                    path={`share-your-impact`}
                                                                    element={
                                                                        <SustainabilityPageSettings />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={`widgets`}
                                                                    element={<Widgets />}
                                                                />
                                                                <Route
                                                                    path={`/reduce-emissions`}
                                                                    element={<ReduceEmissions />}
                                                                />
                                                                <Route
                                                                    path="*"
                                                                    element={<NotFoundPage />}
                                                                />
                                                            </Routes>
                                                        </Layout>
                                                    }
                                                />
                                            </Routes>
                                        </>
                                    </PrivateRoutes>
                                }
                            />
                        </Routes>
                    </RouteChangeInterceptor>
                }
                errorElement={<ErrorPage />}
            />,
        ),
    )

    return <RouterProvider router={router} />
}

export default function DashboardApp() {
    return (
        <>
            {process.env.NODE_ENV === 'development' && <DevelopmentBanner />}
            <App />
        </>
    )
}
