import { TransactionDocumentEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponseWithMeta } from '@lune-climate/lune/esm/core/SuccessResponse'
import {
    CalculationResults,
    RequestAndResponse,
    TransactionDocumentLineItemsOrFallback,
} from '@lune-fe/lune-components-lib'
import { Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Divider from 'components/Divider'
import { asSuccessResponseWithMeta } from 'endpoints/util'
import useIsTestMode from 'hooks/useIsTestMode'
import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import { getTestApiKey } from 'utils'
import { EstimateTypeEnum, EstimateTypeName, ResultsWrapper } from 'views/CalculateEmissions/index'

const TransactionDocumentResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({
    scrollRef,
}) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { id } = useParams<{ id: string }>()
    const [estimate, setEstimate] =
        useState<SuccessResponseWithMeta<TransactionDocumentEmissionEstimate>>()
    const luneClient = useLuneClient()
    const isTestMode = useIsTestMode()
    const testApiKey = getTestApiKey()

    useEffect(() => {
        if (id) {
            luneClient
                .getTransactionDocumentEstimate(id)
                .then((res) => {
                    if (res.isOk()) {
                        const e = asSuccessResponseWithMeta(res.value)
                        setEstimate(e)
                    }
                })
                .catch(() => snackbar(SnackbarMessages.GENERIC_ERROR))
        }
    }, [id, snackbar, luneClient])

    return (
        <>
            {estimate && (
                <ResultsWrapper scrollRef={scrollRef} calculatedAmount={estimate.mass.amount}>
                    <CalculationResults
                        title={
                            estimate.request.name ||
                            EstimateTypeName(EstimateTypeEnum.TRANSACTION_DOCUMENT)
                        }
                        amount={estimate.mass.amount}
                    />
                    <Stack direction={'column'} spacing={9} mt={9}>
                        <TransactionDocumentLineItemsOrFallback
                            lineItems={estimate.lineItems}
                            fallback={estimate.fallback}
                            input={estimate._meta.response.request}
                            enableEmissionFactorLink={true}
                        />
                        <Divider />
                        <RequestAndResponse
                            path={`/v1/estimates/transaction-documents`}
                            request={estimate._meta.response.request}
                            response={estimate._meta.response}
                            idempotencyKey={
                                (estimate._meta.response as { idempotency_key?: string })
                                    .idempotency_key
                            }
                            metadata={
                                (
                                    estimate._meta.response as {
                                        metadata?: Record<string, string>
                                    }
                                ).metadata
                            }
                            isTestMode={isTestMode}
                            testApiKey={testApiKey}
                        />
                    </Stack>
                </ResultsWrapper>
            )}
        </>
    )
}

export default TransactionDocumentResults
