import { TransactionDocumentEmissionEstimate } from '@lune-climate/lune'

import { TransactionDocumentFallback } from './TransactionDocumentFallback'
import { TransactionDocumentLineItems } from './TransactionDocumentLineItems'

export const TransactionDocumentLineItemsOrFallback = ({
    lineItems,
    fallback,
    input,
    enableEmissionFactorLink,
}: {
    lineItems: TransactionDocumentEmissionEstimate['lineItems']
    fallback: TransactionDocumentEmissionEstimate['fallback']
    input: any
    enableEmissionFactorLink: boolean
}) => {
    return (
        <>
            {lineItems !== null ? (
                <TransactionDocumentLineItems
                    lineItems={lineItems}
                    input={input}
                    enableEmissionFactorLink={enableEmissionFactorLink}
                />
            ) : (
                <TransactionDocumentFallback
                    fallback={fallback!}
                    input={input}
                    enableEmissionFactorLink={enableEmissionFactorLink}
                />
            )}
        </>
    )
}

export default TransactionDocumentLineItemsOrFallback
