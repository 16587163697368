import { Button, LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box, SxProps } from '@mui/material'
import React, { FC } from 'react'

type FieldProps = {
    placeholder?: string
    label?: string
    subLabel?: string
    leftButtonIcon?: JSX.Element
    rightButtonIcon?: JSX.Element
    onClick?: () => void
    onLeftButtonClick?: (e: React.MouseEvent<HTMLElement>) => void
    onRightButtonClick?: (e: React.MouseEvent<HTMLElement>) => void
    sx?: SxProps
    error?: string
}
const { palette } = LuneTheme

const Field: FC<FieldProps> = ({
    onClick,
    placeholder,
    label,
    subLabel,
    leftButtonIcon,
    rightButtonIcon,
    onLeftButtonClick,
    onRightButtonClick,
    sx,
    error,
}) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                border: '1px solid',
                borderRadius: '8px',
                pl: 3 / 2,
                pr: 3 / 2,
                maxWidth: '100%',
                cursor: onClick ? 'pointer' : 'default',
                height: '56px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderColor: label ? palette.Grey100 : palette.Grey300,
                backgroundColor: label ? palette.Grey100 : 'white',
                width: '100%',
                overflow: 'hidden',
                svg: {
                    color: palette.Grey600,
                },
                '.MuiTypography-root': {
                    color: `${label ? palette.Grey900 : palette.Grey600}!important`,
                },
                'p, span': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                },
                boxSizing: 'border-box',
                '*': {
                    boxSizing: 'border-box',
                },
                ...(error && {
                    borderColor: palette.Red50,
                    backgroundColor: palette.Red50,
                }),
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1,
                    width: '100%',
                    overflow: 'hidden',
                }}
            >
                {leftButtonIcon && (
                    <Button
                        variant={'text'}
                        sx={{
                            p: 0,
                            maxWidth: '24px',
                            maxHeight: '24px',
                            svg: {
                                width: '20px',
                                height: '20px',
                            },
                        }}
                        iconButton
                        leftIcon={leftButtonIcon}
                        onClick={onLeftButtonClick}
                    />
                )}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        gap: 1 / 2,
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Text variant={'body2'}>{label || placeholder}</Text>
                    {subLabel && (
                        <Text variant={'caption'} sx={{ color: `Grey600` }}>
                            {subLabel}
                        </Text>
                    )}
                </Box>
            </Box>
            {rightButtonIcon && (
                <Button
                    variant={'text'}
                    sx={{
                        p: 0,
                        maxWidth: '24px',
                        maxHeight: '24px',
                        svg: {
                            width: '20px',
                            height: '20px',
                        },
                    }}
                    iconButton
                    leftIcon={rightButtonIcon}
                    onClick={onRightButtonClick}
                />
            )}
        </Box>
    )
}

export default Field
