import { Divider } from '@lune-fe/lune-components-lib'
import { Button, MainLayoutContainer, Modal, Text } from '@lune-fe/lune-ui-lib'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { createRfqTemplate } from 'endpoints/dapi'
import useMappings from 'hooks/useMappings'
import RoutePicker from 'views/CalculateEmissions/RFQ/components/RoutePicker'
import TransportModePicker from 'views/CalculateEmissions/RFQ/components/TransportModePicker'
import SaveTemplateModal from 'views/CalculateEmissions/RFQ/modals/SaveTemplateModal'
import { uploadRfqFile } from 'views/CalculateEmissions/RFQ/utils/operationUtils'
import {
    RfqTemplateData,
    RouteMapping,
    TemplateLegInfo,
    TransportModeMapping,
} from 'views/CalculateEmissions/RFQ/utils/sharedTypes'
import { RFQTemplateState } from 'views/CalculateEmissions/RFQ/utils/types'

const TemplateLeg = () => {
    const navigate = useNavigate()
    const { refetch } = useMappings()
    const [transportModeMapping, setTransportModeMapping] = useState<
        TransportModeMapping | undefined
    >(undefined)
    const [originRouteMapping, setOriginRouteMapping] = useState<RouteMapping | undefined>(
        undefined,
    )
    const [destinationRouteMapping, setDestinationRouteMapping] = useState<
        RouteMapping | undefined
    >(undefined)
    const [modal, setModal] = useState<{ subject: string[]; description?: string } | undefined>(
        undefined,
    )
    const state = useLocation().state as RFQTemplateState

    const [transportError, setTransportError] = useState<string | undefined>()
    const [originError, setOriginError] = useState<string | undefined>()
    const [destinationError, setDestinationError] = useState<string | undefined>()
    const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false)

    const getUpdatedLegs = (): TemplateLegInfo[] | undefined => {
        // TODO verification of data filled and valid would come here
        if (!transportModeMapping || !originRouteMapping || !destinationRouteMapping) {
            setTransportError(!transportModeMapping ? 'Transport Mode mapping required' : undefined)
            setOriginError(!originRouteMapping ? 'Origin mapping required' : undefined)
            setDestinationError(
                !destinationRouteMapping ? 'Destination mapping required' : undefined,
            )
            return
        }

        const updatedLegs = [...state.legs]
        updatedLegs[state.currentLeg - 1] = {
            transportMode: transportModeMapping,
            origin: originRouteMapping,
            destination: destinationRouteMapping,
        }
        return updatedLegs
    }

    const handleNextLeg = async () => {
        const updatedLegs = getUpdatedLegs()
        if (!updatedLegs) {
            return
        }
        navigate('/calculate-emissions/rfq/templates/leg', {
            state: {
                ...state,
                currentLeg: state.currentLeg + 1,
                legs: updatedLegs,
            } as RFQTemplateState,
        })

        const legMapping =
            state.currentLeg < state.legs.length ? state.legs[state.currentLeg] : undefined
        setTransportModeMapping(legMapping?.transportMode)
        setOriginRouteMapping(legMapping?.origin)
        setDestinationRouteMapping(legMapping?.destination)
    }

    const handleSave = async (templateName: string) => {
        const updatedLegs = getUpdatedLegs()
        if (!updatedLegs) {
            return
        }
        const mappingTemplateData: RfqTemplateData = {
            sheetName: state.sheetName,
            headerRow: state.headerRow,
            endRow: state.endRow,
            load: state.load!,
            legs: updatedLegs,
        }
        const rfqTemplate = await createRfqTemplate(templateName, mappingTemplateData)
        refetch()
        const uploadOperation = await uploadRfqFile(state.workbook, rfqTemplate.id)
        if (uploadOperation.result === 'success') {
            navigate('/calculate-emissions/rfq')
        } else {
            setModal(uploadOperation.info)
        }
        setSaveModalOpen(false)
    }

    const handleCancel = () => {
        navigate('/calculate-emissions/rfq')
    }

    const handlePreviousLeg = () => {
        if (state.currentLeg > 1) {
            const legMapping = state.legs[state.currentLeg - 2]
            setTransportModeMapping(legMapping.transportMode)
            setOriginRouteMapping(legMapping.origin)
            setDestinationRouteMapping(legMapping.destination)

            navigate('/calculate-emissions/rfq/templates/leg', {
                state: { ...state, currentLeg: state.currentLeg - 1 },
            })
        }
    }

    return (
        <>
            <Modal
                fullWidth
                open={modal !== undefined}
                onClose={() => {}}
                header={
                    <Box>
                        {(modal?.subject ?? []).map((e, i) => (
                            <Text
                                key={i}
                                variant={'h5'}
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                {e}
                            </Text>
                        ))}
                    </Box>
                }
                subheader={
                    <Text variant={'body1'} sx={{ whiteSpace: 'pre-wrap' }}>
                        {modal?.description}
                    </Text>
                }
                actions={
                    <Button variant={'contained'} onClick={() => setModal(undefined)} wide>
                        OK
                    </Button>
                }
            />
            <MainLayoutContainer
                headerComponent={
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 6,
                            }}
                        >
                            <Button
                                variant="text"
                                iconButton
                                leftIcon={<ArrowBackOutlinedIcon />}
                                sx={{
                                    ml: -1.5,
                                }}
                                onClick={() =>
                                    navigate('/calculate-emissions/rfq/templates/load', { state })
                                }
                            />
                        </Box>
                        <Text variant={'h6'}>
                            Step {state.currentLeg + 1}/{state.numberOfLegs + 1}
                        </Text>
                        <Text variant={'h4'}>Input data for Leg {state.currentLeg}</Text>
                    </Box>
                }
            >
                <Stack
                    direction="column"
                    sx={{
                        mb: 9,
                        width: '100%',
                    }}
                    spacing={{
                        xs: 9,
                    }}
                >
                    <TransportModePicker
                        error={transportError}
                        state={state}
                        transportModeMapping={transportModeMapping}
                        onUpdate={(val) => {
                            setTransportError(undefined)
                            setTransportModeMapping(val)
                        }}
                    />
                    <Divider />
                    <RoutePicker
                        error={originError}
                        label="Origin"
                        state={state}
                        routeMapping={originRouteMapping}
                        onUpdate={(val) => {
                            setOriginError(undefined)
                            setOriginRouteMapping(val)
                        }}
                    />
                    <Divider />
                    <RoutePicker
                        error={destinationError}
                        label="Destination"
                        state={state}
                        routeMapping={destinationRouteMapping}
                        onUpdate={(val) => {
                            setDestinationError(undefined)
                            setDestinationRouteMapping(val)
                        }}
                    />
                </Stack>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="contained"
                        onClick={handlePreviousLeg}
                        disabled={state.currentLeg === 1}
                    >
                        Previous Leg
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            state.currentLeg < state.numberOfLegs
                                ? handleNextLeg()
                                : setSaveModalOpen(true)
                        }}
                    >
                        {state.currentLeg < state.numberOfLegs
                            ? `Next: Leg${state.currentLeg + 1}`
                            : 'Next'}
                    </Button>
                </Box>
            </MainLayoutContainer>
            <SaveTemplateModal
                modalOpen={saveModalOpen}
                onModalClose={(res) => {
                    if (res) {
                        handleSave(res)
                    } else {
                        handleCancel()
                    }
                }}
            />
        </>
    )
}

export default TemplateLeg
