import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'

import { assertType } from 'utils'
import ColumnPicker from 'views/CalculateEmissions/RFQ/components/ColumnPicker'
import Field from 'views/CalculateEmissions/RFQ/components/Field'
import UnitPicker from 'views/CalculateEmissions/RFQ/components/UnitPicker'
import ValueMapping from 'views/CalculateEmissions/RFQ/components/ValueMapping'
import {
    ParameterName,
    PropertyMapping,
    ValueMappingSource,
} from 'views/CalculateEmissions/RFQ/utils/sharedTypes'
import { RFQBaseState } from 'views/CalculateEmissions/RFQ/utils/types'
import { convertCellToString, inferColumnMapping } from 'views/CalculateEmissions/RFQ/utils/utils'

type ParameterMappingProps<T> = {
    state: RFQBaseState
    parameter: ParameterName
    mappingType: ValueMappingSource
    outputValues: readonly T[]
    formatOutput: (value: T) => string
    propertyMapping: PropertyMapping<T> | undefined
    onUpdate: (parameterName: ParameterName, data: PropertyMapping<T> | undefined) => void
    label?: string
    isTradelane?: boolean
    error?: string
}

// TODO allow constant value choice, not only column selection
const ParameterMapping = <T,>({
    state,
    parameter,
    mappingType,
    outputValues,
    formatOutput,
    propertyMapping,
    onUpdate,
    label,
    isTradelane,
    error,
}: ParameterMappingProps<T>) => {
    function generateExample(mapping: PropertyMapping<T> | undefined): string | undefined {
        if (mapping === undefined) {
            return undefined
        } else if (mapping.source === 'constant') {
            return formatOutput(mapping.value)
        } else {
            assertType<'column'>(mapping.source)
            const rawExampleValue = convertCellToString(state.exampleRow[mapping.columnIndex])
            if (mapping.valueMapping.source === 'direct') {
                return rawExampleValue
            } else if (mapping.valueMapping.source === 'unit') {
                return `${rawExampleValue}`
            } else {
                assertType<'mapping'>(mapping.valueMapping.source)
                const mappedExample = mapping.valueMapping.mappings.find(
                    ([i, _o]) => i === rawExampleValue,
                )
                return mappedExample !== undefined ? formatOutput(mappedExample[1]) : undefined
            }
        }
    }

    const exampleToShow = generateExample(propertyMapping)

    const handleColumnChange = (index: number | undefined) => {
        onUpdate(
            parameter,
            index !== undefined
                ? inferColumnMapping(state, parameter, mappingType, outputValues, index)
                : undefined,
        )
    }

    const handleUnitChange = (unit: T) => {
        onUpdate(parameter, {
            ...propertyMapping,
            valueMapping: { source: 'unit', unit },
        } as PropertyMapping<T>)
    }

    const handleValueMappingChange = (mappings: [string, T][]) => {
        onUpdate(parameter, {
            ...propertyMapping,
            valueMapping: { source: 'mapping', mappings },
        } as PropertyMapping<T>)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                flexDirection: 'row',
                gap: 2,
                mb: 1 / 2,
            }}
        >
            <Box
                sx={{
                    width: '45%',
                }}
            >
                <ColumnPicker
                    {...state}
                    error={error}
                    label={label ?? parameter}
                    columnIndex={
                        propertyMapping?.source === 'column'
                            ? propertyMapping.columnIndex
                            : undefined
                    }
                    onUpdate={handleColumnChange}
                />
            </Box>
            {exampleToShow !== undefined && (
                <>
                    <Box
                        sx={{
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowForwardIcon />
                    </Box>
                    <Box sx={{ width: '45%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                gap: 1 / 2,
                            }}
                        >
                            {!(
                                propertyMapping?.source === 'column' &&
                                propertyMapping.valueMapping.source === 'mapping'
                            ) && <Field label={exampleToShow} />}
                            {propertyMapping?.source === 'column' &&
                                propertyMapping.valueMapping.source === 'unit' && (
                                    <UnitPicker
                                        units={outputValues}
                                        formatUnit={formatOutput}
                                        selectedUnit={propertyMapping.valueMapping.unit}
                                        onUpdate={handleUnitChange}
                                    />
                                )}
                            {/* TODO @FESTINA check this */}
                            {propertyMapping?.source === 'column' &&
                                propertyMapping.valueMapping.source === 'mapping' && (
                                    <ValueMapping
                                        isTradelane={isTradelane}
                                        parameter={parameter}
                                        outputValues={outputValues}
                                        formatOutput={formatOutput}
                                        mappings={propertyMapping.valueMapping.mappings}
                                        onUpdate={handleValueMappingChange}
                                    />
                                )}
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default ParameterMapping
