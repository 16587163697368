import { PaginatedEstimatesWithAccountInfo } from '@lune-fe/dapi-models'
import { LoadMoreKeys, useLoadMoreState } from '@lune-fe/lune-components-lib'
import { Button, LoadingWrapper, LuneTheme, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Range } from 'react-date-range'

import FiltersBox from 'components/FiltersBox'
import FiltersWithQueryParamsWrapper, {
    onLoadPropsWithType,
} from 'components/FiltersWithQueryParamsWrapper'
import { getEstimates } from 'endpoints/dapi'
import useScrollRestoration, { ScrollPositionKeys } from 'hooks/useScrollRestoration'
import { SnackbarMessages } from 'SnackbarMessages'
import { EstimateTypeEnum, EstimateTypeName } from 'views/CalculateEmissions'
import EmissionCalculationsTable from 'views/CalculateEmissions/Table/EmissionCalculationsTable'

const EmissionCalculations = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const MAX_LOAD_LIMIT = 100
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { palette } = LuneTheme
    const { restoreScroll, saveScrollPosition, clearScrollSession } = useScrollRestoration()
    const { saveLastCount, getLastCount, clearLastCount } = useLoadMoreState()

    const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>()
    const [selectedTypes, setSelectedTypes] = useState<EstimateTypeEnum[]>([])
    const [dateRange, setDateRange] = useState<Range>({
        startDate: undefined,
        endDate: undefined,
    })

    const [loading, setLoading] = useState(true)
    const [loadingMore, setLoadingMore] = useState(true)
    const [paginatedEstimates, setPaginatedEstimates] =
        useState<PaginatedEstimatesWithAccountInfo>()
    const [loadingLastLoadMoreState, setLoadingLastLoadMoreState] = useState<boolean>(true)
    const [lastCount, setLastCount] = useState<number>()
    const [queryParams, setQueryParams] = useState<string>()
    const [loadedItemsCount, setLoadedItemsCount] = useState<number>(0)

    useEffect(() => {
        const lastCount = getLastCount(LoadMoreKeys.EMISSION_CALCULATION)
        setLastCount(lastCount ? Number(lastCount) : 10)
        setLoadingLastLoadMoreState(false)
        // disabling this to make sure it's triggered only on component init
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadEstimations = useCallback(
        async (
            { selectedAccountIds, types, dateRange }: onLoadPropsWithType<EstimateTypeEnum>,
            page?: string,
        ) => {
            try {
                let shouldLoad = true
                let count = lastCount || 10

                while (shouldLoad) {
                    const paginatedResult = await getEstimates({
                        limit: count > MAX_LOAD_LIMIT ? MAX_LOAD_LIMIT : count,
                        after: page,
                        accountId: selectedAccountIds,
                        from: dateRange.startDate?.toISOString(),
                        through: dateRange.endDate?.toISOString(), // add one to the date
                        type: types,
                    })
                    setPaginatedEstimates((prevValue) => ({
                        hasMore: paginatedResult.hasMore,
                        nextCursor: paginatedResult.nextCursor,
                        data: page
                            ? [...(prevValue ? prevValue.data : []), ...paginatedResult.data]
                            : paginatedResult.data,
                    }))
                    shouldLoad = !!lastCount && !!count && count > MAX_LOAD_LIMIT && count !== 0
                    if (shouldLoad) {
                        page = paginatedResult.data.at(-1)?.id
                        count = count - MAX_LOAD_LIMIT
                    }
                }
                setLoading(false)
                setLoadingMore(false)
                clearLastCount(LoadMoreKeys.EMISSION_CALCULATION)
                setLastCount(undefined)
                setTimeout(() => {
                    restoreScroll(scrollRef, ScrollPositionKeys.EMISSION_CALCULATION)
                    clearScrollSession(ScrollPositionKeys.EMISSION_CALCULATION)
                }, 0)
            } catch {
                setPaginatedEstimates({
                    hasMore: false,
                    nextCursor: null,
                    data: [],
                })
                snackbar(SnackbarMessages.GENERIC_ERROR)
                setLoading(false)
                setLoadingMore(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedTypes, selectedAccountIds, dateRange, snackbar],
    )

    useEffect(() => {
        const t = paginatedEstimates?.data.length
        setLoadedItemsCount(t || 0)
    }, [paginatedEstimates])

    const onLoadMoreButtonClick = () => {
        setLoadingMore(true)
        const page = paginatedEstimates?.data.at(-1)?.id
        if (selectedAccountIds) {
            loadEstimations(
                {
                    selectedAccountIds,
                    types: selectedTypes,
                    dateRange,
                },
                page,
            )
        }
    }

    useEffect(() => {
        if (selectedAccountIds) {
            loadEstimations({
                selectedAccountIds,
                types: selectedTypes,
                dateRange,
            })
        }
    }, [loadEstimations, dateRange, selectedAccountIds, selectedTypes])

    return !loadingLastLoadMoreState ? (
        <>
            <FiltersWithQueryParamsWrapper<EstimateTypeEnum>
                dropDownFilterName={'type'}
                setDateRange={setDateRange}
                dateRange={dateRange}
                setSelectedAccountIds={setSelectedAccountIds}
                selectedAccountIds={selectedAccountIds}
                setSelectedTypes={setSelectedTypes}
                selectedTypes={selectedTypes}
                setQueryParams={setQueryParams}
            />
            <Box
                sx={{
                    mt: 9,
                    mb: 9,
                    width: '100%',
                    height: '1px',
                    background: palette.Grey300,
                }}
            />
            {selectedAccountIds && (
                <FiltersBox<EstimateTypeEnum>
                    preselectedDateRange={dateRange}
                    preselectedAccountIds={selectedAccountIds}
                    preselectedTypes={selectedTypes}
                    setSelectedAccountIds={
                        setSelectedAccountIds as Dispatch<SetStateAction<string[]>>
                    }
                    setDateRange={setDateRange}
                    setSelectedTypes={setSelectedTypes}
                    types={[
                        EstimateTypeEnum.COMPANY,
                        EstimateTypeEnum.SHIPPING,
                        EstimateTypeEnum.MULTI_LEG_SHIPPING,
                        EstimateTypeEnum.TRANSACTION,
                        EstimateTypeEnum.FLIGHT,
                        EstimateTypeEnum.ELECTRICITY,
                        EstimateTypeEnum.INDIVIDUAL,
                        EstimateTypeEnum.PASSENGER_TRANSPORT,
                    ]}
                    transformDropDownLabel={(label) => EstimateTypeName(label as any)}
                    dropDownFilterName={'type'}
                />
            )}
            <LoadingWrapper sx={{ height: '300px' }} loading={loading}>
                {!paginatedEstimates?.data.length && !loading ? (
                    <Box sx={{ height: `554px`, mt: 4 }}>
                        <NoResultsPlaceholder
                            message={
                                <Text variant={`h6`} color={`Grey900`}>
                                    No estimates calculated yet
                                </Text>
                            }
                        />
                    </Box>
                ) : (
                    <Box sx={{ mt: 4 }}>
                        {paginatedEstimates && (
                            <EmissionCalculationsTable
                                queryParams={queryParams}
                                scrollRef={scrollRef}
                                saveLastCount={saveLastCount}
                                saveScrollPosition={saveScrollPosition}
                                loadedItemsCount={loadedItemsCount}
                                paginatedEstimates={paginatedEstimates}
                            />
                        )}
                        <>
                            {paginatedEstimates?.hasMore && (
                                <Button
                                    loading={loadingMore}
                                    disabled={loadingMore}
                                    variant={`outlined`}
                                    leftIcon={<ArrowDownwardIcon />}
                                    onClick={() => onLoadMoreButtonClick()}
                                    sx={{ mt: 9, minWidth: '128px' }}
                                >
                                    Load more
                                </Button>
                            )}
                        </>
                    </Box>
                )}
            </LoadingWrapper>
        </>
    ) : (
        <></>
    )
}

export default EmissionCalculations
