import { DataSheet } from '@lune-fe/dapi-models'
import { Button, LoadingWrapper, Table, Tooltip } from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import { AxiosError } from 'axios'
import fileDownload from 'js-file-download'
import { flatten } from 'lodash'
import moment from 'moment/moment'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { downloadDataSheet } from 'endpoints/dapi'
import useDataSheets from 'hooks/useDataSheets'
import { assertType } from 'utils'

const FIRST_COL_SIZE = 4

const formatDate = (date: string): string => {
    return moment(date).format('ll')
}

const formatSheetType = (type: DataSheet['type']): string => {
    if (type === 'lune') {
        return 'Lune'
    } else if (type === 'ecotransit') {
        return 'EcoTransIT'
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (type === 'lune_logistics_v2') {
        return 'Lune v2'
    } else if (type === 'lune_fintech') {
        return 'Spend'
    } else if (type === 'rfq') {
        return 'RFQ'
    } else {
        assertType<null>(type)
        return '-'
    }
}

const SheetsTable = ({ type }: { type: 'fintech' | 'logistics' | 'rfq' }) => {
    const { loading, paginatedDataSheets, hasNextPage, fetchNextPage, refetch } =
        useDataSheets(type)
    const { sheetId } = useParams<{ sheetId?: string }>()
    useEffect(() => {
        refetch()
    }, [type])

    const downloadSheet = async (id: string) => {
        try {
            const data = await downloadDataSheet(id)
            fileDownload(data, `${id}.csv`, 'text/csv')
        } catch (err) {
            if (err instanceof AxiosError && err.status === 404) {
                // swallow, users can change the page url to trigger this error
                return
            }
            throw err
        }
    }

    const dataSheets = useMemo(
        () => flatten((paginatedDataSheets?.pages ?? []).map(({ data }) => data)),
        [paginatedDataSheets],
    )

    useEffect(() => {
        if (!sheetId) {
            return
        }

        downloadSheet(sheetId)
    }, [sheetId])

    return dataSheets.length ? (
        <>
            <Divider />
            <LoadingWrapper loading={loading}>
                {dataSheets.length > 0 && (
                    <Table.TableContainer>
                        <Table sx={{ minWidth: 650 }}>
                            <Table.TableHead>
                                <Table.TableRow>
                                    <Table.TableCell colSize={FIRST_COL_SIZE}>
                                        Upload date
                                    </Table.TableCell>
                                    {type !== 'fintech' && type !== 'rfq' && (
                                        <Table.TableCell>Type</Table.TableCell>
                                    )}
                                    {type === 'rfq' && <Table.TableCell>Mapping</Table.TableCell>}
                                    <Table.TableCell>Status</Table.TableCell>
                                    <Table.TableCell align="right" alignEnd>
                                        # of calculations
                                    </Table.TableCell>
                                    <Table.TableCell align="right" alignEnd>
                                        # of errors
                                    </Table.TableCell>
                                    <Table.TableCell align="right" alignEnd>
                                        tCO₂
                                    </Table.TableCell>
                                    <Table.TableCell align="right" alignEnd />
                                </Table.TableRow>
                            </Table.TableHead>
                            <Table.TableBody>
                                {dataSheets.map((sheet, i) => (
                                    <Table.TableRow key={i}>
                                        <Table.TableCell colSize={FIRST_COL_SIZE}>
                                            <DescriptionOutlinedIcon sx={{ mr: 3 }} />
                                            <span>{formatDate(sheet.createdAt)}</span>
                                        </Table.TableCell>
                                        {type !== 'fintech' && type !== 'rfq' && (
                                            <Table.TableCell>
                                                {formatSheetType(sheet.type)}
                                            </Table.TableCell>
                                        )}
                                        {type === 'rfq' && (
                                            <Table.TableCell
                                                sx={{
                                                    '> div': {
                                                        maxWidth: '150px',
                                                        overflow: 'hidden',
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        maxWidth: '100%',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    {
                                                        // the non-null assertion is safe since no RFQ datasheet can be created without it.
                                                        // types for datasheets don't reflect this at this point.
                                                        sheet.options!.rfqTemplateName
                                                    }
                                                </Box>
                                            </Table.TableCell>
                                        )}
                                        <Table.TableCell>
                                            {sheet.processedAt ? 'Completed' : 'Processing'}
                                        </Table.TableCell>
                                        <Table.TableCell align="right" alignEnd>
                                            {sheet.processedAt ? sheet.numCalculations : '-'}
                                        </Table.TableCell>
                                        <Table.TableCell align="right" alignEnd>
                                            {sheet.processedAt ? sheet.numErrors : '-'}
                                        </Table.TableCell>
                                        <Table.TableCell align="right" alignEnd>
                                            {sheet.processedAt ? sheet.quantity : '-'}
                                        </Table.TableCell>
                                        <Table.TableCell align="right" alignEnd>
                                            {sheet.processedAt ? (
                                                <Button
                                                    variant="text"
                                                    iconButton
                                                    rightIcon={<FileDownloadOutlinedIcon />}
                                                    onClick={() => downloadSheet(sheet.id)}
                                                />
                                            ) : (
                                                <Tooltip
                                                    placement="top"
                                                    title="File is being processed"
                                                >
                                                    <Button
                                                        variant="text"
                                                        disabled
                                                        iconButton
                                                        rightIcon={<FileDownloadOutlinedIcon />}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Table.TableCell>
                                    </Table.TableRow>
                                ))}
                            </Table.TableBody>
                        </Table>
                    </Table.TableContainer>
                )}
            </LoadingWrapper>

            {hasNextPage && (
                <Button
                    leftIcon={<ArrowDownwardIcon />}
                    variant="outlined"
                    onClick={() => fetchNextPage()}
                >
                    Load more
                </Button>
            )}
        </>
    ) : (
        <></>
    )
}

export default SheetsTable
