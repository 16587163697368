import {
    EmissionFactorWithGasEmissions,
    GasEmissions,
    ShippingRoute,
    ShippingSourceDestination,
} from '@lune-climate/lune'
import { Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, useMemo } from 'react'

import { getFormattedAmount } from '../../utils/utils'
import MapWithRoute from '..//map/MapWithRoute'
import Divider from '../Divider'

import { CalculateEmissionsIcons } from './calculateEmissionsIcons'
import EmissionFactorExplanationCard from './EmissionFactorExplanationCard'
import EmissionsBreakdowns from './EmissionsBreakdowns'
import ExplanationSteps from './ExplanationSteps'
import StepWithInfoIcon from './StepWithInfoIcon'
import {
    EmissionsExplanationSectionProps,
    getDistanceExplanation,
    useExplanationSteps,
} from './useExplanationSteps'
import { calculateEmissionFactorAmount, isAirShippingMethod } from './utils'

export const EmissionsExplanationSection: FC<EmissionsExplanationSectionProps> = (props) => {
    const {
        distance,
        adjustedDistance,
        distanceCalculationMethod,
        load,
        convertedLoad,
        emissionFactor,
        emissions,
        emissionsUnit,
        responseRoute,
        requestRoute,
        requestMethod,
        mapboxAccessToken,
        hideEmissionFactorUrl,
        distanceCalculationDetails,
        vesselInference,
        pdfStructure,
    } = props

    const steps = useExplanationSteps(props)
    const showMap =
        (distanceCalculationMethod === 'vessel_tracking' || isAirShippingMethod(requestMethod)) &&
        responseRoute

    const localEmissionFactorAmount = calculateEmissionFactorAmount(
        convertedLoad,
        emissionFactor,
        load,
        adjustedDistance,
        emissions,
    )
    const wtwUnit = emissionsUnit ?? getFormattedAmount(emissions.amount).amountUnit

    /**
     * To be called in the context where `responseRoute` is defined. If it's defined we know
     * we have to have provided source and destination to the API so let's verify that and,
     * if it's the case, return a type-narrowed version of the `route`.
     *
     * Calling this function when `responseRoute` is null is a programming error and will
     * result in an exception.
     */
    function expectSourceDestinationRoute(route: ShippingRoute | null): ShippingSourceDestination {
        if (responseRoute === null) {
            throw new Error(`This function can only be called when responseRoute is non-null`)
        }
        if (route === null || !('source' in route)) {
            throw new Error(
                `We expect the request route to be defined and to have source and destination`,
            )
        }
        return route
    }

    const distanceExplanation = useMemo(() => {
        return getDistanceExplanation(
            distance,
            adjustedDistance,
            distanceCalculationMethod,
            distanceCalculationDetails,
            responseRoute,
            vesselInference,
        )
    }, [
        distance,
        adjustedDistance,
        distanceCalculationMethod,
        distanceCalculationDetails,
        responseRoute,
        vesselInference,
    ])

    return (
        <>
            <Box>
                <Text variant={'h5'} sx={{ mb: 4 }}>
                    Methodology
                </Text>
                <ExplanationSteps steps={steps} pdfStructure={pdfStructure} />
            </Box>
            <EmissionsBreakdowns
                emissions={emissions}
                emissionsUnit={wtwUnit}
                pdfStructure={pdfStructure}
            />
            <EmissionFactorExplanationCard
                {...(emissionFactor.gasEmissions === null
                    ? {
                          emissionFactor: emissionFactor as Omit<
                              EmissionFactorWithGasEmissions,
                              'gasEmissions'
                          > & { gasEmissions: null },
                          amount: localEmissionFactorAmount.toString(),
                      }
                    : {
                          emissionFactor: emissionFactor as Omit<
                              EmissionFactorWithGasEmissions,
                              'gasEmissions'
                          > & { gasEmissions: GasEmissions },
                          amount: null,
                      })}
                label={emissionFactor.name}
                href={hideEmissionFactorUrl ? undefined : `/emission-factors/${emissionFactor.id}`}
            />
            {showMap && mapboxAccessToken && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 9,
                        flexDirection: 'column',
                    }}
                >
                    {!pdfStructure && (
                        <>
                            <Divider />
                            <Box>
                                <Text variant={'h5'} sx={{ mb: 4 }}>
                                    Routing
                                </Text>
                                {distanceExplanation.description && (
                                    <Box
                                        sx={{
                                            mb: 4,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 3,
                                        }}
                                    >
                                        {CalculateEmissionsIcons.route}
                                        <StepWithInfoIcon
                                            index={1}
                                            key={1}
                                            step={distanceExplanation}
                                            info={distanceExplanation.info ?? null}
                                        />
                                    </Box>
                                )}
                                <MapWithRoute
                                    requestMethod={requestMethod}
                                    // SAFETY: The environment variable may not be defined and then
                                    // we'll just crash.
                                    mapboxAccessToken={mapboxAccessToken}
                                    source={expectSourceDestinationRoute(requestRoute).source}
                                    destination={
                                        expectSourceDestinationRoute(requestRoute).destination
                                    }
                                    route={[
                                        responseRoute.source.coordinates,
                                        ...responseRoute.legs.map((l) => l.location.coordinates),
                                    ]}
                                    // SAFETY: The types don't express this but we know that when we get a route
                                    // in response then we also have to get distance. Hence the type assertion.
                                    distance={distance!}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </>
    )
}

export default EmissionsExplanationSection
