import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import React, { FC, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react'

import { getFormattedAmount } from '../../utils'

import LegMethodIcon from './LegMethodIcon'
import { getSourceOrDestination, Leg } from './utils'

const { palette } = LuneTheme

export const LegsScrollableContainer: FC<{
    children: ReactNode
}> = ({ children }) => {
    const wrapper: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    // distance between wrapper and window edge
    const [rightDistance, setRightDistance] = useState(0)

    useEffect(() => {
        function handleResize() {
            const rightDistance =
                window.innerWidth - (wrapper.current?.getBoundingClientRect().right || 0) - 15
            setRightDistance(rightDistance)
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const getStyles = useMemo(() => {
        return {
            boxSizing: 'border-box',
            width: `calc(100% + ${2 * rightDistance}px)`,
            paddingLeft: `${rightDistance}px`,
            marginLeft: `${-rightDistance}px`,
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            position: 'relative',
            overflowY: 'none !important',
            overflowX: 'auto',
        }
    }, [rightDistance])

    return (
        <Box ref={wrapper} sx={{ width: '100%' }}>
            <Box sx={getStyles}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        zIndex: 9,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

const LegBox: FC<{
    leg: Leg
    legNumber: number
    active: boolean
    onClick: (legNumber: number, legElement: HTMLButtonElement) => void
    smallBoxes: boolean
}> = ({ leg, legNumber, active, onClick, smallBoxes }) => {
    const legFormattedAmount = (leg: Leg): string => {
        const formattedAmount = getFormattedAmount(leg.mass.amount)
        return `${Number(formattedAmount.amountAsNumber).toFixed(
            2,
        )} ${formattedAmount.amountUnit}CO₂e`
    }

    const sourceLabel = leg.routeInferenceDetails?.route.source.label
    const destinationLabel = leg.routeInferenceDetails?.route.destination.label

    const inferredRoute = leg.routeInferenceDetails
        ? {
              source: leg.routeInferenceDetails.route.source.coordinates,
              destination: leg.routeInferenceDetails.route.destination.coordinates,
          }
        : null

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClick(legNumber, event.currentTarget)
    }

    return (
        <Box
            data-testid={`leg-btn-${legNumber}`}
            onClick={handleClick}
            component={'button'}
            sx={{
                background: active ? palette.Blue50 : palette.Grey100,
                mt: 1,
                mb: 1,
                height: '160px',
                borderRadius: '16px',
                p: 2,
                minWidth: '120px',
                maxWidth: active ? '320px' : smallBoxes ? '122px' : '160px',
                width: active ? '320px' : '100%',
                flex: '1 0 auto',
                transition: 'background .15s ease-in-out',
                overflow: 'hidden',
                cursor: 'pointer',
                border: 'none',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'space-between',
                justifyContent: 'space-between',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    padding: '10px 14px 10px 12px',
                    borderRadius: '40px',
                    background: active ? palette.Blue500 : palette.Grey300,
                    transition: 'background .15s ease-in-out',
                    color: active ? palette.White : palette.Grey900,
                    svg: {
                        height: '20px',
                        width: '20px',
                    },
                    span: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    },
                }}
            >
                <Text variant={'button'}>
                    <LegMethodIcon method={leg.method} /> Leg {legNumber}
                </Text>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                {leg.route && (
                    <>
                        {'amount' in leg.route ? (
                            <Text variant={'body2'}>
                                ${leg.route.amount} ${leg.route.unit}
                            </Text>
                        ) : (
                            <Text
                                variant={'body2'}
                                sx={{
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {
                                    getSourceOrDestination(
                                        sourceLabel ?? inferredRoute?.source ?? leg.route.source,
                                        '',
                                    ).label as string
                                }
                                {
                                    getSourceOrDestination(
                                        destinationLabel ??
                                            inferredRoute?.destination ??
                                            leg.route.destination,
                                        ' to ',
                                    ).label as string
                                }
                            </Text>
                        )}
                    </>
                )}
                <Text variant={'caption'} data-testid={`leg-emissions-amount-${legNumber}`}>
                    {legFormattedAmount(leg)}
                </Text>
            </Box>
        </Box>
    )
}

export const Legs: FC<{ legs: Leg[]; setActiveTab: (tabIndex: number) => void }> = ({
    legs,
    setActiveTab,
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(1)

    const handleLegClick = (legNumber: number, legElement: HTMLButtonElement) => {
        setActiveTabIndex(legNumber)
        setActiveTab(legNumber)

        legElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
        })
    }

    return (
        <LegsScrollableContainer>
            <Box
                sx={{
                    pr: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'flex-start',
                        gap: 1,
                        flexWrap: 'nowrap',
                        width: 'max-content',
                    }}
                >
                    {legs.map((leg, index) => {
                        return (
                            <LegBox
                                key={index}
                                leg={leg}
                                smallBoxes={legs.length > 5}
                                legNumber={index + 1}
                                active={activeTabIndex === index + 1}
                                onClick={handleLegClick}
                            />
                        )
                    })}
                </Box>
            </Box>
        </LegsScrollableContainer>
    )
}
