import { CalculateEmissionsIcons } from '@lune-fe/lune-components-lib'

export const getShipmentGroupIcon = (group: string) => {
    switch (group) {
        case 'ship': {
            return CalculateEmissionsIcons.ship
        }
        case 'plane': {
            return CalculateEmissionsIcons.plane
        }
        case 'truck': {
            return CalculateEmissionsIcons.truck
        }
        case 'train': {
            return CalculateEmissionsIcons.train
        }
    }
}
