import { SingleShippingEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponseWithMeta } from '@lune-climate/lune/esm/core/SuccessResponse'
import {
    buildLoadTiles,
    buildLogisticsTypeTiles,
    buildRouteTiles,
    EmissionsExplanationSection,
    InputsWrapper,
    RequestAndResponse,
    ResultLabeledSectionAndTiles,
} from '@lune-fe/lune-components-lib'
import { Box } from '@mui/material'

import Divider from 'components/Divider'
import useIsTestMode from 'hooks/useIsTestMode'
import { getTestApiKey } from 'utils'

const SingleLegEstimate = ({
    estimate,
}: {
    estimate: SuccessResponseWithMeta<SingleShippingEmissionEstimate>
}) => {
    const isTestMode = useIsTestMode()
    const testApiKey = getTestApiKey()

    return (
        <>
            <Divider />
            <EmissionsExplanationSection
                emissions={estimate.mass}
                emissionFactor={estimate.emissionFactor}
                methodology={estimate.methodology}
                distance={estimate.distance}
                adjustedDistance={estimate.adjustedDistance}
                load={estimate.request.shipment}
                distanceCalculationMethod={estimate.distanceCalculationMethod}
                distanceCalculationDetails={estimate.distanceCalculationDetails}
                loadConversionMethod={estimate.shipmentConversionMethod}
                convertedLoad={estimate.convertedShipment}
                requestRoute={'route' in estimate.request ? estimate.request.route : null}
                requestMethod={estimate.request.method}
                responseRoute={estimate.route}
                methodologyDetails={estimate.methodologyDetails}
                vesselInference={estimate.vesselInferenceDetails ?? null}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            />
            <Divider />
            <Box>
                <InputsWrapper>
                    <ResultLabeledSectionAndTiles
                        dataTestId={'shipment-method'}
                        tiles={[
                            ...buildLogisticsTypeTiles(
                                estimate.request.method,
                                estimate.emissionFactor,
                            ),
                            ...('route' in estimate.request
                                ? buildRouteTiles(estimate.request.route)
                                : []),
                            ...buildLoadTiles(
                                estimate.request.shipment,
                                estimate.emissionFactor.denominatorUnit,
                            ),
                        ]}
                    />
                </InputsWrapper>
            </Box>
            <Divider />
            <RequestAndResponse
                path={`/v1/estimates/shipping`}
                request={estimate._meta.response.request}
                response={estimate._meta.response}
                source={estimate.source}
                idempotencyKey={
                    (estimate._meta.response as { idempotency_key?: string }).idempotency_key
                }
                metadata={
                    (estimate._meta.response as { metadata?: Record<string, string> }).metadata
                }
                isTestMode={isTestMode}
                testApiKey={testApiKey}
            />
        </>
    )
}

export default SingleLegEstimate
