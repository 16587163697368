import {
    Button,
    Input,
    Loading,
    LuneTheme,
    LuneThemeProvider,
    Modal,
    Text,
} from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { FC, useState } from 'react'
import ReactDOM from 'react-dom/client'

const SaveTemplateModal: FC<{
    modalOpen: boolean
    onModalClose: (mappingName?: string) => void
}> = ({ modalOpen, onModalClose }) => {
    const [calculating, setCalculating] = useState<boolean>(false)

    const { palette } = LuneTheme
    const [name, setName] = useState<string>()

    return (
        <LuneThemeProvider theme={LuneTheme}>
            <Modal
                maxWidth={'sm'}
                fullWidth
                open={modalOpen}
                onClose={() => onModalClose()}
                header={<Text variant={'h5'}>Save Mapping</Text>}
                subheader={
                    <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                        Calculate emissions and save this mapping to reuse in the future
                    </Text>
                }
                actions={
                    <>
                        <Button variant={'outlined'} wide onClick={() => onModalClose()}>
                            Cancel
                        </Button>
                        <Button
                            loading={calculating ? <Loading light={true} /> : undefined}
                            variant={'contained'}
                            wide
                            onClick={() => {
                                setCalculating(true)
                                onModalClose(name)
                            }}
                            disabled={!name}
                        >
                            Calculate emissions
                        </Button>
                    </>
                }
            >
                <Stack
                    direction="column"
                    sx={{
                        width: '100%',
                    }}
                    spacing={{
                        xs: 6,
                    }}
                >
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={'Mapping name'}
                    />
                </Stack>
            </Modal>
        </LuneThemeProvider>
    )
}

export default SaveTemplateModal

export const showSaveTemplateModal = (): Promise<string | undefined> => {
    return new Promise((resolve) => {
        const container = document.createElement('div')
        document.body.appendChild(container)
        const root = ReactDOM.createRoot(container)

        const handleClose = (name?: string) => {
            resolve(name)
            setTimeout(() => {
                root.unmount()
                container.remove()
            }, 0)
        }

        root.render(<SaveTemplateModal modalOpen={true} onModalClose={handleClose} />)
    })
}
