import Big from 'big.js'
import { CellValue, Workbook, Worksheet } from 'exceljs'
import levenshtein from 'fast-levenshtein'

import { assertType } from 'utils'
import {
    ParameterName,
    PropertyMapping,
    ValueMappingInformation,
} from 'views/CalculateEmissions/RFQ/utils/sharedTypes'
import {
    inputAliases,
    parameterNameAliases,
    RFQBaseState,
    unitAliases,
} from 'views/CalculateEmissions/RFQ/utils/types'

export function convertCellToString(cell: CellValue): string {
    if (typeof cell === 'object' && cell && 'richText' in cell) {
        return cell.richText.map((e) => e.text).join('')
    } else if (typeof cell === 'object' && cell && 'formula' in cell && cell.formula) {
        return cell.formula
    } else if (typeof cell === 'string') {
        return cell
    } else if (typeof cell === 'number') {
        return cell.toString()
    } else if (typeof cell === 'object' && cell && 'toString' in cell) {
        return cell.toString()
    } else if (!cell) {
        return 'NO_DATA'
    } else {
        alert(
            `Error converting cell to string: unsupported cell type: ${JSON.stringify(cell, null, 2)}`,
        )
        throw new Error(`unsupported cell type: ${JSON.stringify(cell, null, 2)}`)
    }
}

export function parseBig(input: string): Big | undefined {
    try {
        return Big(input)
    } catch {
        return undefined
    }
}

export function detectTableEndRow(
    workbook: Workbook,
    sheetName: string,
    headerRow: number,
): number {
    const sheet = workbook.getWorksheet(sheetName)!
    // TODO improve logic here, what if the first column is not part of the data
    // TODO remove this hardcoded stuff for Forto. handle it correctly.
    return (
        headerRow +
        sheet.getColumn(1).values.slice(headerRow + 1).length +
        (sheetName === 'FCL horizontal' ? -2 : 0)
    )
}

export function extractRowValues(sheet: Worksheet, rowIndex: number): CellValue[] {
    return (sheet.getRow(rowIndex + 1).values as CellValue[]).slice(1)
}

export function extractDifferentColumnValues(
    sheet: Worksheet,
    columnIndex: number,
    startingRow: number,
    endRow: number,
): string[] {
    const allValues = sheet
        .getColumn(columnIndex + 1)
        .values.slice(Number(startingRow) + 1, Number(endRow) + 2) // endRow needs extra increment due to slice `end` limit behaviour
        .filter((e) => e !== undefined)
    return Array.from(new Set(allValues.map(convertCellToString)))
}

// TODO we can probably remove from consideration data that isn't valid (column with wrong value types for example)
function findBestStringMatchIndex(
    keywordsToSearch: string[],
    data: string[],
): { index: number; score: number } {
    let inferrence: { index: number; score: number } | undefined

    // Look for a substring match first which indicates a strong match
    data.forEach((data, index) => {
        const cleanedData = data.trim().toLowerCase()
        for (const keyword of keywordsToSearch) {
            const lowerKeyword = keyword.trim().toLowerCase()
            // TODO Make sure a full word being matched is better than half word
            const matchIndex = data.trim().toLowerCase().indexOf(lowerKeyword)
            if (matchIndex !== -1) {
                // 1. Prefer early occurrences (alias appearing at the start = stronger match)
                // 2. Prefer shorter names (fewer extra words = stronger match)
                const score = matchIndex + (cleanedData.length - lowerKeyword.length)
                if (!inferrence || score < inferrence.score) {
                    inferrence = { score, index }
                }
            }
        }
    })

    // Fallback to Levenshtein distance if no substring was matched
    if (!inferrence) {
        data.forEach((data, index) => {
            for (const keyword of keywordsToSearch) {
                // Add offset to score to guarantee that any score on previous logic is better than the fallback
                const score =
                    levenshtein.get(keyword.trim().toLowerCase(), data.trim().toLowerCase()) + 1000

                if (!inferrence || score < inferrence.score) {
                    inferrence = { score, index }
                }
            }
        })
    }

    return inferrence! // Fallback above will always return a valid column
}

export function inferColumnMapping<T>(
    state: RFQBaseState,
    _parameter: ParameterName,
    mappingType: ValueMappingInformation<unknown>['source'],
    outputValues: readonly T[],
    column: number,
): PropertyMapping<T> {
    const baseMapping = {
        source: 'column' as const,
        columnIndex: column,
    }

    const inputValues = extractDifferentColumnValues(
        state.sheet,
        column,
        state.headerRow,
        state.endRow - 1,
    )
    if (mappingType === 'direct') {
        return { ...baseMapping, valueMapping: { source: 'direct' } }
    } else if (mappingType === 'unit') {
        // For unit auto-complete the easiest for now is just look at the column name and units involved
        const columnName = state.headers[column]
        const bestUnitMatch = outputValues
            .map((e, index) => {
                // TODO receive method to convert to string T instead of casting
                const aliases = [String(e), ...(unitAliases[String(e)] ?? [])]
                const r = findBestStringMatchIndex(aliases, [columnName])
                return { score: r.score, index }
            })
            .reduce((a, b) => (a.score < b.score ? a : b))

        return {
            ...baseMapping,
            valueMapping: { source: 'unit', unit: outputValues[bestUnitMatch.index] },
        }
    } else {
        assertType<'mapping'>(mappingType)
        const mappings = inputValues.map((input) => {
            const aliases = [input, ...(inputAliases[input] ?? [])]
            const bestMatch = findBestStringMatchIndex(aliases, outputValues.map(String))
            return [input, outputValues[bestMatch.index]] as [string, T]
        })
        return { ...baseMapping, valueMapping: { source: 'mapping', mappings } }
    }
}

export function inferPropertyMapping<T>(
    state: RFQBaseState,
    parameter: ParameterName,
    mappingType: ValueMappingInformation<unknown>['source'],
    outputValues: readonly T[],
    extraContext?: string,
): PropertyMapping<T> {
    const parameterAliases = [parameter, ...parameterNameAliases[parameter]]
    // We use the extraContext to create even more aliases. Use it as prefix, suffix and by itself
    const fullAliases = extraContext
        ? [
              ...parameterAliases,
              ...parameterAliases.map((alias) => `${extraContext} ${alias}`),
              ...parameterAliases.map((alias) => `${alias} ${extraContext}`),
              extraContext,
          ]
        : parameterAliases
    const pickedColumn = findBestStringMatchIndex(fullAliases, state.headers).index
    return inferColumnMapping(state, parameter, mappingType, outputValues, pickedColumn)
}
