import { EmissionFactor, EmissionFactorWithGasEmissions, GasEmissions } from '@lune-climate/lune'
import { EmissionFactor as DapiEmissionFactor } from '@lune-fe/dapi-models'
import { Chip, staticImagePath, Text } from '@lune-fe/lune-ui-lib'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import { Box } from '@mui/material'
import { Big } from 'big.js'
import { FC, useMemo } from 'react'

import { formatNumbers } from '../../utils'

import { cardImageAndBackgroundData } from './EmissionFactorExplanationCardData'
import { emissionFactorLabel } from './utils'

export const EmissionFactorExplanationCard: FC<
    (
        | {
              emissionFactor: Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                  gasEmissions: null
              }
              amount: string
          }
        | {
              emissionFactor: Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                  gasEmissions: GasEmissions
              }
              amount: null
          }
        | {
              emissionFactor: EmissionFactor & {
                  gasEmissions: null
              }
              amount: string
          }
        | {
              emissionFactor: DapiEmissionFactor
              amount: null
          }
    ) & {
        label?: string
        href?: string
    }
> = ({ emissionFactor, amount, label, href }) => {
    const {
        region,
        source,
        publicationYear,
        numeratorUnit,
        denominatorUnit,
        gasEmissions,
        category,
    } = emissionFactor

    const formattedAmount = useMemo(() => {
        if (gasEmissions === 'emission_factor_gated') {
            return '**********'
        }

        function _format(intesity: string) {
            return Big(intesity).lt(0.001) ? formatNumbers(intesity, 6) : formatNumbers(intesity)
        }

        // The API has started returning `gasEmissions` since March 2024.
        return _format(amount === null ? gasEmissions!.co2E : amount)
    }, [amount, gasEmissions])

    const getBackgroundColorAndImage = () => {
        let found = cardImageAndBackgroundData.find((i) => {
            if (typeof i.name === 'string') {
                return label?.toLowerCase().includes(i.name.toLowerCase())
            } else {
                return i.name.some((name) => label?.toLowerCase().includes(name))
            }
        })
        if (!found && category) {
            found = cardImageAndBackgroundData.find((i) => {
                if (typeof i.name === 'string') {
                    return category.includes(i.name)
                } else {
                    return i.name.some((name) => category.toLowerCase().includes(name))
                }
            })
        }

        return found
    }

    const bgColor = getBackgroundColorAndImage()?.backgroundColor || 'rgba(245, 245, 245, 1)'
    const image = getBackgroundColorAndImage()?.image || undefined

    const tiles = [
        {
            label: `${formattedAmount} ${numeratorUnit}CO₂e / ${denominatorUnit}`,
            icon: <Co2OutlinedIcon />,
        },
        {
            label: `${emissionFactorLabel[source]}, ${publicationYear}`,
            icon: <AutoStoriesOutlinedIcon />,
        },
        {
            label: `${region}`,
            icon: <PublicOutlinedIcon />,
        },
    ]

    return (
        <Box>
            <Text variant={'h6'} sx={{ mb: 4 }}>
                Emission Factor
            </Text>
            <Box
                data-testid={'emission-factor-card'}
                component={href ? 'a' : 'div'}
                href={href}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    textDecoration: 'none !important',
                    minHeight: '168px',
                    gap: 3,
                    borderRadius: '32px',
                    p: 4,
                    background: bgColor,
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <Text
                        data-testid={'emission-factor-title'}
                        variant={'h5'}
                        sx={{
                            mb: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                        }}
                    >
                        {label} {href && <ArrowForwardIosIcon sx={{ height: '16px' }} />}
                    </Text>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                        }}
                    >
                        {tiles.map((tile, index) => (
                            <Chip
                                testId={`emission-factor-chip-${index}`}
                                key={tile.label}
                                sx={{ background: 'rgba(255, 255, 255, 1)' }}
                                label={tile.label}
                                icon={tile.icon}
                            />
                        ))}
                    </Box>
                </Box>
                {image && (
                    <Box
                        sx={{
                            width: '104px',
                            height: '104px',
                            borderRadius: '24px',
                            overflow: 'hidden',
                            img: {
                                width: '100%',
                            },
                        }}
                    >
                        <img src={staticImagePath(image)} alt={label || 'Emission factor image'} />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default EmissionFactorExplanationCard
