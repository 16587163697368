import { DataSheet } from '@lune-fe/dapi-models'
import { InfiniteData, useInfiniteQuery } from 'react-query'

import { getDataSheets } from 'endpoints/dapi'
import { queryKeys } from 'queryKeys'

// TODO: Run the codegen script to update types once dapi is up to date
type PaginatedDataSheets = { hasMore: boolean; data: DataSheet[] }

const useDataSheets = (
    type: 'fintech' | 'logistics' | 'rfq',
): {
    paginatedDataSheets: InfiniteData<PaginatedDataSheets> | undefined
    loading: boolean
    fetchNextPage: () => void
    refetch: () => void
    hasNextPage: boolean | undefined
} => {
    const {
        data: paginatedDataSheets,
        isLoading,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery<PaginatedDataSheets>({
        queryKey: queryKeys.GET_DATA_SHEETS,
        queryFn: async ({ pageParam = { cursor: undefined } }) => {
            return getDataSheets({ after: pageParam.cursor }, type)
        },
        getNextPageParam: (lastPage) => {
            return lastPage.hasMore
                ? { cursor: lastPage.data[lastPage.data.length - 1].id }
                : undefined
        },
        refetchOnMount: true,
    })

    return {
        paginatedDataSheets,
        loading: isLoading,
        fetchNextPage,
        refetch,
        hasNextPage,
    }
}

export default useDataSheets
