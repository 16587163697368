import { PublicProposalResponse } from '@lune-fe/dapi-models'
import { ButtonGroup, LoadingWrapper, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import { Stack } from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import Divider from 'components/Divider'
import { getPublicProposalById } from 'endpoints/dapi'
import { ALWAYS_CHECK_FOR_NEW_DATA, queryKeys } from 'queryKeys'
import ContactErikSection from 'views/Proposals/Proposal/ContactErikSection'
import { ViewMode } from 'views/Proposals/Proposal/ProposalView'
import Requirements from 'views/Proposals/Proposal/Requirements'
import TrustedByGlobalInnovatorsSection from 'views/Proposals/Proposal/TrustedByGlobalInnovatorsSection'
import Option from 'views/Proposals/PublicProposal/Option'

const ProposalPreview = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { proposalId } = useParams<{ proposalId: string }>()
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.OPTIONS)

    const getProposal = async (id: string): Promise<PublicProposalResponse | undefined> => {
        try {
            return await getPublicProposalById(id)
        } catch {
            navigate(`/proposal-request`)
            snackbar(`Requested proposal does not exist!`)
            return undefined
        }
    }

    const { isLoading: loadingProposal, data: proposal } = useQuery<
        PublicProposalResponse | undefined
    >(
        [queryKeys.GET_PUBLIC_PROPOSAL_BY_ID, proposalId],
        () => (proposalId ? getProposal(proposalId) : undefined),
        {
            ...ALWAYS_CHECK_FOR_NEW_DATA,
        },
    )

    return (
        <LoadingWrapper loading={loadingProposal} sx={{ minHeight: '100vh' }}>
            {proposal && (
                <MainLayoutContainer
                    headerComponent={
                        <>
                            <Text variant={'h6'}>
                                {moment(proposal.createdAt).format('DD MMMM, YYYY')}
                            </Text>
                            <Text variant={'h4'}>
                                Portfolio proposal for {proposal.accountName}
                            </Text>
                        </>
                    }
                >
                    <ButtonGroup
                        sx={{
                            mb: 9,
                        }}
                        items={[
                            {
                                label: ViewMode.OPTIONS,
                                value: ViewMode.OPTIONS,
                            },
                            {
                                label: ViewMode.REQUIREMENTS,
                                value: ViewMode.REQUIREMENTS,
                            },
                        ]}
                        onChange={(e) => {
                            setViewMode(e as ViewMode)
                        }}
                        value={viewMode}
                    />
                    {viewMode === ViewMode.OPTIONS ? (
                        <Stack spacing={9} sx={{ mb: 9 }}>
                            {proposal.rankedPortfolios?.map((option, index) => {
                                return (
                                    <>
                                        <Option
                                            proposalId={proposal.id}
                                            key={`proposal-option-${index}`}
                                            index={index}
                                            option={option}
                                            currency={proposal.currency}
                                        />
                                        <Divider />
                                    </>
                                )
                            })}
                            <TrustedByGlobalInnovatorsSection />
                            <Divider />
                            <ContactErikSection />
                        </Stack>
                    ) : (
                        <Requirements proposalRequest={proposal.userRequirements} />
                    )}
                </MainLayoutContainer>
            )}
        </LoadingWrapper>
    )
}

export default ProposalPreview
