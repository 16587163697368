import {
    EmissionFactorWithGasEmissions,
    GasEmissions,
    TransactionDocumentEmissionEstimateFallback,
} from '@lune-climate/lune'
import { Text, TooltipList } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Big from 'big.js'
import moment from 'moment'
import { FC, useMemo } from 'react'

import { formatToCurrency, getCountryNameByThreeLetterCode } from '../../utils/utils'

import EmissionFactorExplanationCard from './EmissionFactorExplanationCard'
import ExplanationChip from './ExplanationChip'
import ExplanationSteps, { ExplanationStep, ExplanationText } from './ExplanationSteps'

interface TransactionDocumentEmissionsExplanationFallbackSectionProps {
    fallback: TransactionDocumentEmissionEstimateFallback
    enableEmissionFactorLink: boolean
}

export const TransactionDocumentEmissionsExplanationFallbackSection: FC<
    TransactionDocumentEmissionsExplanationFallbackSectionProps
> = ({ fallback, enableEmissionFactorLink }) => {
    const {
        countryCode,
        category,
        emissionFactor,
        value,
        dietFactor,
        exchangeRateDate,
        exchangeRate,
    } = fallback

    const fallbackStep: JSX.Element = (
        <ExplanationText text="Failed to calculate line item emissions, using document-level emissions calculation instead" />
    )

    const categoryStep = useMemo((): JSX.Element | undefined => {
        if (!category) {
            return undefined
        }
        return (
            <>
                <ExplanationText text="Extracted the" />
                <ExplanationChip text={category} />
                <ExplanationText text="category based on your input" />
            </>
        )
    }, [category])

    const genericTextExtractionInfo: JSX.Element = (
        <>
            <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                Extracted using AI:
            </Text>
            <TooltipList items={['Model: Llama 3.1:8b']} />
        </>
    )

    const amountStep = useMemo((): JSX.Element => {
        const amountInInputCurrency = formatToCurrency(value.value, value.currency)
        return (
            <>
                <ExplanationText text="Extracted the amount of" />
                <ExplanationChip text={amountInInputCurrency} />
                <ExplanationText text="based on your input" />
            </>
        )
    }, [value])

    const countryStep = useMemo((): JSX.Element => {
        return (
            <>
                <ExplanationText text="Selected the" />
                <ExplanationChip
                    text={getCountryNameByThreeLetterCode(countryCode) ?? countryCode}
                />
                <ExplanationText text="country based on your input" />
            </>
        )
    }, [countryCode])

    const countryTextExtractionInfo: JSX.Element = (
        <>
            <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                {`We matched your input where, to Lune's country:`}
            </Text>
            <TooltipList items={['Model: Llama 3.1:8b']} />
        </>
    )

    const emissionFactorStep = useMemo((): JSX.Element => {
        return (
            <>
                <ExplanationText text="Selected the" />
                <ExplanationChip text={emissionFactor.name} />
                <ExplanationText text="Emission Factor" />
            </>
        )
    }, [emissionFactor])

    const emissionFactorInfo: JSX.Element = (
        <>
            <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                Based on category
            </Text>
            <TooltipList items={['Model: GTE-small']} />
        </>
    )

    const exchangeRateStep = useMemo((): JSX.Element | undefined => {
        if (!exchangeRate || !exchangeRateDate) {
            return undefined
        }

        const amountInInputCurrency = formatToCurrency(
            fallback.value.value,
            fallback.value.currency,
        )
        const amountInConvertedCurrency = formatToCurrency(
            Big(fallback.value.value).mul(exchangeRate).toString(),
            emissionFactor.denominatorUnit,
        )
        const formattedDate = moment(exchangeRateDate).format('D MMMM YYYY')

        return (
            <>
                <ExplanationText text="Converted" />
                <ExplanationChip text={amountInInputCurrency} />
                <ExplanationText text="to" />
                <ExplanationChip text={amountInConvertedCurrency} />
                <ExplanationText text="based on the" />
                <ExplanationChip text={formattedDate} />
                <ExplanationText text="exchange rate of" />
                <ExplanationChip text={exchangeRate} />
            </>
        )
    }, [exchangeRate, exchangeRateDate, emissionFactor, value])

    const multiplicationStep = useMemo((): JSX.Element => {
        return dietFactor ? (
            <ExplanationText text="Multiplied the adjusted Emission Factor by the amount" />
        ) : (
            <ExplanationText text="Multiplied the Emission Factor by the amount" />
        )
    }, [dietFactor])

    const steps: ExplanationStep[] = [
        { description: fallbackStep },
        { description: categoryStep, info: genericTextExtractionInfo },
        { description: amountStep, info: genericTextExtractionInfo },
        { description: countryStep, info: countryTextExtractionInfo },
        { description: emissionFactorStep, info: emissionFactorInfo },
        ...(exchangeRateStep ? [{ description: exchangeRateStep }] : []),
        { description: multiplicationStep },
    ]

    return (
        <>
            <Box>
                <Text variant={'h5'} sx={{ mb: 4 }}>
                    Methodology
                </Text>
                <ExplanationSteps steps={steps} />
            </Box>
            <Box>
                <EmissionFactorExplanationCard
                    emissionFactor={
                        emissionFactor as Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                            gasEmissions: GasEmissions
                        }
                    }
                    amount={null}
                    label={emissionFactor.name}
                    {...(enableEmissionFactorLink
                        ? {
                              href: `/emission-factors/${emissionFactor.id}`,
                          }
                        : {})}
                />
            </Box>
        </>
    )
}
