import { Button, Modal, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC } from 'react'

const ErrorsModal: FC<{
    subject: string[]
    description?: string
    onClose: () => void
}> = ({ subject, description, onClose }) => {
    return (
        <Modal
            fullWidth
            open={true}
            onClose={() => {}}
            header={
                <Box>
                    {subject.map((e, i) => (
                        <Text
                            key={i}
                            variant={'h5'}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            {e}
                        </Text>
                    ))}
                </Box>
            }
            subheader={<Text variant={'body1'}>{description}</Text>}
            actions={
                <Button variant={'contained'} onClick={onClose} wide>
                    OK
                </Button>
            }
        />
    )
}

export default ErrorsModal
