import EditIcon from '@mui/icons-material/Edit'

import Field from 'views/CalculateEmissions/RFQ/components/Field'
import { showMapValuesModal } from 'views/CalculateEmissions/RFQ/modals/MapValuesModal'

type ValueMappingProps<T> = {
    outputValues: readonly T[]
    formatOutput: (value: T) => string
    mappings: [string, T][]
    onUpdate: (mappings: [string, T][]) => void
    parameter: string
    isTradelane?: boolean
}

const labelsMap = new Map<string, string>([
    ['countryCode', 'Country code'],
    ['tradelane', 'Trade Lane'],
    ['refrigeration', 'Refrigeration'],
    ['aircraftType', 'Aircraft Type'],
    ['vehicleType', 'Vehicle Type'],
    ['fuel', 'Fuel'],
])

const ValueMapping = <T,>({
    outputValues,
    formatOutput,
    mappings,
    onUpdate,
    parameter,
    isTradelane,
}: ValueMappingProps<T>) => {
    const localLabel = labelsMap.get(parameter)
    return (
        <Field
            label={localLabel ?? parameter}
            subLabel={`${mappings.length} values mapped`}
            rightButtonIcon={<EditIcon />}
            onClick={async () => {
                const selection = await showMapValuesModal({
                    parameter,
                    outputValues,
                    formatOutput,
                    mappings,
                    isTradelane,
                })
                if (selection) {
                    onUpdate(selection)
                }
            }}
        />
    )
}

export default ValueMapping
