// =======================================================================
//
// THIS FILE SHOULD BE KEPT IN SYNC WITH THE BE.
//
// Any changes to this file should be reflected in it's copy in the FE
// The file currently sits in:
// src/rfq/sharedTypes.ts
//
// =======================================================================

import { AircraftTypeMethod, RoadFuel, RoadShippingMethod } from '@lune-climate/lune'

// TODO fix/unify tradelane with API. Extend country codes.
export type RfqTemplateData = {
    sheetName: string
    headerRow: number
    endRow: number
    load: PropertyMapping<LoadUnit>
    legs: TemplateLegInfo[]
}

export type TemplateLegInfo = {
    transportMode: TransportModeMapping
    origin: RouteMapping
    destination: RouteMapping
}

export const loadUnitsArray = ['t', 'kg', 'TEU'] as const
export type LoadUnit = (typeof loadUnitsArray)[number]

export const transportModeArray = [
    'Container Ship',
    'Cargo Plane',
    'Truck',
    'Electric truck',
    'Electric van',
    'Electric train',
    'Diesel train',
] as const
export type TransportMode = (typeof transportModeArray)[number]

export const routeArray = ['Address', 'Airport code', 'Port code', 'Geo coordinates'] as const
export type Route = (typeof routeArray)[number]

export const countryCodeArray = ['GBR', 'PRT'] as const
export type CountryCode = (typeof countryCodeArray)[number]

export const tradelanesArray = ['Panama', 'Other'] as const
export type Tradelane = (typeof tradelanesArray)[number]

export type AircraftType = `${AircraftTypeMethod.aircraft_type}`
export const aircraftTypesArray: AircraftType[] = Object.values(AircraftTypeMethod.aircraft_type)

export type RoadVehicleType = `${RoadShippingMethod.vehicle_type}`
export const roadVehiclesArray: RoadVehicleType[] = Object.values(RoadShippingMethod.vehicle_type)

export type RoadFuelType = `${RoadFuel}`
export const roadFuelTypesArray: RoadFuelType[] = Object.values(RoadFuel)

export type PropertyMapping<T> =
    | {
          source: 'constant'
          value: T
      }
    | {
          source: 'column'
          columnIndex: number
          valueMapping: ValueMappingInformation<T>
      }

export type ValueMappingInformation<T> =
    | { source: 'direct' }
    | { source: 'unit'; unit: T }
    | {
          source: 'mapping'
          mappings: [string, T][]
      }

export type ValueMappingSource = ValueMappingInformation<unknown>['source']

type TransportModeMappingData = {
    'Container Ship': {
        refrigeration?: PropertyMapping<boolean>
        tradelane?: PropertyMapping<Tradelane>
    }
    'Cargo Plane': {
        aircraftType?: PropertyMapping<AircraftType>
    }
    Truck: {
        vehicleType?: PropertyMapping<RoadVehicleType>
        fuel?: PropertyMapping<RoadFuelType>
    }
    'Electric truck': {
        refrigeration?: PropertyMapping<boolean>
    }
    'Electric van': {
        refrigeration?: PropertyMapping<boolean>
    }
    'Electric train': {}
    'Diesel train': {}
}

export type TransportModeMapping = {
    [K in TransportMode]: { type: K } & TransportModeMappingData[K]
}[TransportMode]

type RouteMappingData = {
    Address: {
        countryCode: PropertyMapping<CountryCode>
        streetLine?: PropertyMapping<string>
        city?: PropertyMapping<string>
        postcode?: PropertyMapping<string>
    }
    'Airport code': {
        airportCode?: PropertyMapping<string>
    }
    'Port code': {
        portCode?: PropertyMapping<string>
    }
    'Geo coordinates': {
        lat?: PropertyMapping<number>
        lon?: PropertyMapping<number>
    }
}

export type RouteMapping = {
    [K in Route]: { type: K } & RouteMappingData[K]
}[Route]

export type ParameterName =
    | {
          [K in keyof (RouteMappingData & TransportModeMappingData)]: keyof (RouteMappingData &
              TransportModeMappingData)[K]
      }[keyof (RouteMappingData & TransportModeMappingData)]
    | 'load'
