import { Text } from '@lune-fe/lune-ui-lib'
import { ReactNode } from 'react'

export const InputsWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <Text variant={'h5'} sx={{ mb: 4 }}>
                Your inputs
            </Text>
            {children}
        </>
    )
}

export const InputWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <Text variant={'h5'} sx={{ mb: 4 }}>
                Your input
            </Text>
            {children}
        </>
    )
}

export default InputsWrapper
