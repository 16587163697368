import { Button, Chip, MainLayoutContainer, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import BrowseGalleryOutlinedIcon from '@mui/icons-material/BrowseGalleryOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Box, Stack } from '@mui/material'
import { useState } from 'react'

export const GatedView = () => {
    const [accessRequested, setAccessRequested] = useState(false)
    const [disabled, setDisabled] = useState(false)

    return (
        <MainLayoutContainer
            headerComponent={
                <>
                    <Box
                        sx={{
                            mb: 2,
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <Text variant={'h4'}>Reduce Emissions</Text>
                        <Chip
                            label="Beta"
                            sx={{ backgroundColor: 'Grey100', height: '32px', mt: 1 }}
                            icon={<LockOutlinedIcon sx={{ width: '16px', height: '16px' }} />}
                        />
                    </Box>
                    <Text variant="body3" sx={{ color: 'Grey700', display: 'block', mb: 1 }}>
                        Gain insights into shipment emissions and identify reduction opportunities.
                    </Text>
                    <Text variant="body3" sx={{ color: 'Grey700', display: 'block', mb: 4 }}>
                        This feature helps you pinpoint emission hotspots in your shipments and
                        provides actionable recommendations on how to reduce them.
                    </Text>
                </>
            }
        >
            <Box sx={{ height: `554px`, mt: 3 }}>
                <NoResultsPlaceholder
                    message={
                        <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                            <Button
                                leftIcon={
                                    <BrowseGalleryOutlinedIcon
                                        sx={{ width: '16px', height: '16px' }}
                                    />
                                }
                                sx={{ backgroundColor: `white` }}
                                variant={'outlined'}
                                disabled={disabled}
                                onClick={async () => {
                                    // TODO: uncomment when we are ready to ship
                                    // await requestAccessToReduceEmissions()
                                    setAccessRequested(true)
                                    setDisabled(true)
                                }}
                            >
                                Request access
                            </Button>
                            {accessRequested ? (
                                <Text variant="caption" sx={{ color: `Grey700` }}>
                                    Your request is being reviewed
                                </Text>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    }
                />
            </Box>
        </MainLayoutContainer>
    )
}
