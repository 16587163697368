import { PaymentMethod } from '@lune-fe/dapi-models'
import { useQuery } from 'react-query'

import { getPaymentMethods } from 'endpoints/dapi'
import { queryKeys } from 'queryKeys'

function usePaymentMethod() {
    const {
        isLoading,
        refetch,
        data: paymentMethods,
    } = useQuery<PaymentMethod[]>(queryKeys.PAYMENT_METHODS_KEY, getPaymentMethods)

    return { isLoading, refetch, paymentMethod: paymentMethods?.[0], paymentMethods }
}

export default usePaymentMethod
