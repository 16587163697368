import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'

export const ExplanationChip = ({ text, testId }: { text: string; testId?: string }) => {
    const { palette } = LuneTheme
    return (
        <Text
            className={'steps_explanation-chip'}
            variant={'body2'}
            sx={{
                backgroundColor: `${palette.Grey100}`,
                marginX: 1,
                paddingX: 1,
                paddingY: '6px',
                borderRadius: '20px',
                whiteSpace: 'nowrap',
            }}
            data-testid={testId}
        >
            {text}
        </Text>
    )
}

export default ExplanationChip
