import { Button, Loading, Modal, Select } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import { FC, useState } from 'react'

import useMappings from 'hooks/useMappings'

export type SelectionResult = {
    newOrExisting: 'new' | 'existing'
    selectedExistingMappingId?: string
}

const ExistingOrNewMapping: FC<{
    modalOpen: boolean
    onModalClose: (val?: SelectionResult) => void
}> = ({ modalOpen, onModalClose }) => {
    const [selectedMappingId, setSelectedMappingId] = useState<string>()
    const [calculating, setCalculating] = useState<boolean>(false)
    const { mappings } = useMappings()

    return (
        <Modal
            maxWidth={'sm'}
            fullWidth
            open={modalOpen}
            onClose={() => onModalClose()}
            header={<Text variant={'h5'}>Choose mapping</Text>}
            subheader={<Text variant={'body3'}>Select the mapping to use for this file</Text>}
            actions={
                <>
                    <Button
                        variant={'outlined'}
                        wide
                        onClick={() => onModalClose({ newOrExisting: 'new' })}
                    >
                        Start new mapping
                    </Button>
                    <Button
                        loading={calculating ? <Loading light={true} /> : undefined}
                        variant={'contained'}
                        disabled={!selectedMappingId}
                        wide
                        onClick={() => {
                            setCalculating(true)
                            onModalClose({
                                newOrExisting: 'existing',
                                selectedExistingMappingId: selectedMappingId,
                            })
                        }}
                    >
                        Calculate emissions
                    </Button>
                </>
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                    '.MuiRadioGroup-root': {
                        width: '100%',
                    },
                }}
            >
                <Select
                    label={'Mapping'}
                    sx={{
                        width: '100%',
                    }}
                    value={selectedMappingId}
                    items={mappings.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }))}
                    onChange={(e) => {
                        setSelectedMappingId(e)
                    }}
                />
            </Box>
        </Modal>
    )
}

export default ExistingOrNewMapping
