import { Button, LuneTheme, LuneThemeProvider, Modal, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import React, { FC, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'

interface ColumnPickerProps {
    propertyName: string
    headers: string[]
    exampleRow: string[]
    columnIndex: number | undefined
}

interface ColumnPickerModalProps extends ColumnPickerProps {
    modalOpen: boolean
    onModalClose: (selection?: number) => void
}

const RadioButton: FC<{ selected: boolean }> = ({ selected }) => {
    return (
        <Box
            sx={{
                width: '24px',
                height: '24px',
                border: '2px solid black',
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    borderRadius: '100%',
                    width: '10px',
                    height: '10px',
                    transform: selected ? 'scale(1)' : 'scale(0)',
                    transition: 'transform .1s ease-in-out',
                    background: 'black',
                }}
            />
        </Box>
    )
}

const Columns: FC<{
    headers: string[]
    exampleRow: string[]
    selectedIndex: number | undefined
    onSelect: (index: number) => void
}> = ({ headers, selectedIndex, exampleRow, onSelect }) => {
    const [localSelectedIndex, setLocalSelectedIndex] = useState<number | undefined>(selectedIndex)

    const itemRefs = React.useRef<(HTMLButtonElement | null)[]>([])

    useEffect(() => {
        if (selectedIndex) {
            itemRefs.current[selectedIndex]?.scrollIntoView({ inline: 'center', block: 'nearest' })
        }
    }, [selectedIndex])

    const style = {
        width: '80px',
        height: '114px',
        p: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: localSelectedIndex ? 'rgba(250, 250, 250, 1)' : 'white',
        transition: 'background .1s ease-in-out',
        span: {
            maxWidth: '100%',
            whiteSpace: 'break-spaces',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            wordBreak: 'break-word',
            WebkitLineClamp: 5,
            WebkitBoxOrient: 'vertical',
            fontSize: '12px',
        },
    }

    return (
        <Box
            sx={{
                width: 'calc(100% + 96px)',
                overflow: 'auto',
                ml: -6,
                mr: -6,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                }}
            >
                {headers.map((header, index) => {
                    return (
                        <Box
                            component={'button'}
                            key={index}
                            ref={(el) => {
                                itemRefs.current[index] = el as HTMLButtonElement
                            }}
                            sx={{
                                '*': {
                                    boxSizing: 'border-box',
                                },
                                cursor: 'pointer',
                                border: 'none',
                                background: 'none',
                                padding: 0,
                            }}
                            onClick={() => {
                                onSelect(index)
                                setLocalSelectedIndex(index)
                            }}
                        >
                            <Box
                                sx={{
                                    width: '80px',
                                    height: '72px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <RadioButton selected={index === localSelectedIndex} />
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid rgba(224, 224, 224, 1)',
                                    mr: '-1px',
                                }}
                            >
                                <Box
                                    sx={{
                                        ...style,
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        ...(index === localSelectedIndex && {
                                            background: 'white',
                                        }),
                                    }}
                                >
                                    <Text
                                        variant={'caption'}
                                        sx={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        {header}
                                    </Text>
                                </Box>
                                <Box
                                    sx={{
                                        ...style,
                                        ...(index === localSelectedIndex && {
                                            background: 'white',
                                        }),
                                    }}
                                >
                                    <Text variant={'caption'}>{exampleRow[index]}</Text>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

const ColumnPickerModal: FC<ColumnPickerModalProps> = ({
    propertyName,
    modalOpen,
    onModalClose,
    headers,
    exampleRow,
    columnIndex,
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(columnIndex)

    return (
        <LuneThemeProvider theme={LuneTheme}>
            <Modal
                maxWidth={'md'}
                fullWidth
                open={modalOpen}
                onClose={() => onModalClose()}
                header={
                    <Text variant={'h5'}>
                        Select relevant column for:{' '}
                        <span style={{ textTransform: 'capitalize' }}>{propertyName}</span>
                    </Text>
                }
                subheader={
                    <Text variant={'body1'}>
                        We’ll use data in these columns to set the load of your shipments
                    </Text>
                }
                actions={
                    <>
                        <Button variant={'outlined'} wide onClick={() => onModalClose()}>
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            wide
                            onClick={() => onModalClose(selectedIndex)}
                        >
                            Map values
                        </Button>
                    </>
                }
            >
                <Columns
                    headers={headers}
                    selectedIndex={columnIndex}
                    exampleRow={exampleRow}
                    onSelect={(newIndex) => setSelectedIndex(newIndex)}
                />
            </Modal>
        </LuneThemeProvider>
    )
}

export default ColumnPickerModal

export const showColumnPickerModal = ({
    propertyName,
    headers,
    exampleRow,
    columnIndex,
}: ColumnPickerProps): Promise<number> => {
    return new Promise((resolve) => {
        const container = document.createElement('div')
        document.body.appendChild(container)
        const root = ReactDOM.createRoot(container)

        const handleClose = (selection?: any) => {
            resolve(selection)
            setTimeout(() => {
                root.unmount()
                container.remove()
            }, 0)
        }

        root.render(
            <ColumnPickerModal
                propertyName={propertyName}
                headers={headers}
                exampleRow={exampleRow}
                modalOpen={true}
                onModalClose={handleClose}
                columnIndex={columnIndex}
            />,
        )
    })
}
