import { NumberSteps, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { ReactNode } from 'react'

import StepWithInfoIcon from './StepWithInfoIcon'

export const ExplanationText = ({ text }: { text: string }) => {
    return (
        <Text
            className={'steps_explanation-text'}
            variant={'body2'}
            component={'span'}
            sx={{ whiteSpace: 'nowrap' }}
        >
            {text}
        </Text>
    )
}

export const ExplanationWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <Text
            className={'steps_explanation-wrapper'}
            variant={'body2'}
            component={'span'}
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            {children}
        </Text>
    )
}

export type ExplanationStep = { description?: JSX.Element; info?: string | JSX.Element | null }

export const ExplanationSteps = ({
    steps,
    pdfStructure,
}: {
    steps: ExplanationStep[]
    pdfStructure?: boolean
}) => {
    return (
        <>
            {!!steps.length && (
                <Box>
                    <Text variant={'h6'} sx={{ mb: 4 }}>
                        How we calculated these emissions
                    </Text>
                    <Box data-testid={`calculation-steps-wrapper`} sx={{ overflowX: 'auto' }}>
                        <NumberSteps
                            steps={steps.map(({ description, info }, index) => {
                                return (
                                    <StepWithInfoIcon
                                        index={index}
                                        key={index}
                                        step={{ description }}
                                        info={pdfStructure ? null : (info ?? null)}
                                    />
                                )
                            })}
                        />
                    </Box>
                </Box>
            )}
        </>
    )
}

export default ExplanationSteps
