import { Button, LoadingWrapper, Modal, Table, Text } from '@lune-fe/lune-ui-lib'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { FC } from 'react'

import { deleteRfqTemplate } from 'endpoints/dapi'
import useMappings from 'hooks/useMappings'

interface ManageMappingTemplatesModalProps {
    modalOpen: boolean
    onModalClose: () => void
}

const TemplatesModal: FC = () => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { mappings, loading, refetch } = useMappings()

    return (
        <LoadingWrapper loading={loading} sx={{ minHeight: '200px', width: '100%' }}>
            <Table.TableContainer data-testId={'cart-table'}>
                <Table sx={{ minWidth: 550 }}>
                    <Table.TableHead>
                        <Table.TableRow>
                            <Table.TableCell colSize={4}>Name</Table.TableCell>
                            <Table.TableCell colSize={3}>Created by</Table.TableCell>
                            <Table.TableCell colSize={3}>Created date</Table.TableCell>
                            <Table.TableCell colSize={1} />
                            <Table.TableCell colSize={1} />
                        </Table.TableRow>
                    </Table.TableHead>
                    <Table.TableBody>
                        {mappings.map((item, index) => (
                            <Table.TableRow
                                key={index}
                                sx={{
                                    '.MuiTableCell-root > div': {
                                        minHeight: '56px !important',
                                    },
                                }}
                            >
                                <Table.TableCell colSize={5}>{item.name}</Table.TableCell>
                                <Table.TableCell colSize={3}>{item.createdBy}</Table.TableCell>
                                <Table.TableCell colSize={2}>
                                    {moment(item.createdAt).format('MMMM DD, YYYY')}
                                </Table.TableCell>
                                <Table.TableCell colSize={1}>
                                    <Button
                                        onClick={() => {
                                            // TODO: implement edit here
                                            console.log('Delete mapping with id: ', item.id)
                                            // then:
                                            snackbar('Mapping deleted successfully')
                                        }}
                                        variant="text"
                                        iconButton
                                        rightIcon={<EditIcon />}
                                    />
                                </Table.TableCell>
                                <Table.TableCell colSize={1}>
                                    <Button
                                        onClick={async () => {
                                            await deleteRfqTemplate(item.id)
                                            snackbar('Mapping deleted successfully')
                                            refetch()
                                        }}
                                        variant="text"
                                        iconButton
                                        rightIcon={<DeleteIcon />}
                                    />
                                </Table.TableCell>
                            </Table.TableRow>
                        ))}
                    </Table.TableBody>
                </Table>
            </Table.TableContainer>
        </LoadingWrapper>
    )
}

const ManageMappingTemplatesModal: FC<ManageMappingTemplatesModalProps> = ({
    modalOpen,
    onModalClose,
}) => {
    return (
        <Modal
            maxWidth={'md'}
            fullWidth
            open={modalOpen}
            onClose={() => onModalClose()}
            header={<Text variant={'h5'}>Manage mappings</Text>}
            actions={
                <Button variant={'contained'} wide onClick={() => onModalClose()}>
                    Close
                </Button>
            }
        >
            <TemplatesModal />
        </Modal>
    )
}

export default ManageMappingTemplatesModal
