import { getTradeLaneLabel } from '@lune-fe/lune-components-lib'
import { AutocompleteSelect, LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'

import { assertType } from 'utils'
import Labels from 'views/CalculateEmissions/RFQ/components/Labels'
import ParameterMapping from 'views/CalculateEmissions/RFQ/components/ParameterMapping'
import {
    AircraftType,
    aircraftTypesArray,
    PropertyMapping,
    RoadFuelType,
    roadFuelTypesArray,
    roadVehiclesArray,
    RoadVehicleType,
    Tradelane,
    tradelanesArray,
    TransportMode,
    transportModeArray,
    TransportModeMapping,
} from 'views/CalculateEmissions/RFQ/utils/sharedTypes'
import { RFQTemplateState } from 'views/CalculateEmissions/RFQ/utils/types'
import { inferPropertyMapping } from 'views/CalculateEmissions/RFQ/utils/utils'
import { getShipmentGroupIcon } from 'views/CalculateEmissions/Shared/utils'

type TransportModeProps = {
    state: RFQTemplateState
    transportModeMapping: TransportModeMapping | undefined
    onUpdate: (mapping: TransportModeMapping | undefined) => void
    error?: string
}

type ListOption = {
    group: string
    label: string
    value: TransportMode
}

const optionsGroup = ['ship', 'plane', 'truck', 'truck', 'truck', 'train', 'train']

const options: ListOption[] = transportModeArray.map((mode, index) => ({
    label: mode,
    value: mode,
    group: optionsGroup[index],
}))

const { palette } = LuneTheme

// eslint-disable-next-line complexity
const TransportModePicker = ({
    state,
    transportModeMapping,
    onUpdate,
    error,
}: TransportModeProps) => {
    const handleTransportModeChange = (transportMode: TransportMode | undefined) => {
        if (transportMode === undefined) {
            onUpdate(undefined)
        } else if (transportMode === 'Container Ship') {
            onUpdate({
                type: transportMode,
                refrigeration: state.isFullInference
                    ? inferPropertyMapping(state, 'refrigeration', 'mapping', [true, false])
                    : undefined,
                tradelane: state.isFullInference
                    ? inferPropertyMapping(state, 'tradelane', 'mapping', tradelanesArray)
                    : undefined,
            })
        } else if (transportMode === 'Cargo Plane') {
            onUpdate({
                type: transportMode,
                aircraftType: state.isFullInference
                    ? inferPropertyMapping(state, 'aircraftType', 'mapping', aircraftTypesArray)
                    : undefined,
            })
        } else if (transportMode === 'Truck') {
            onUpdate({
                type: transportMode,
                vehicleType: state.isFullInference
                    ? inferPropertyMapping(state, 'vehicleType', 'mapping', roadVehiclesArray)
                    : undefined,
                fuel: state.isFullInference
                    ? inferPropertyMapping(state, 'fuel', 'mapping', roadFuelTypesArray)
                    : undefined,
            })
        } else if (transportMode === 'Electric truck') {
            onUpdate({
                type: transportMode,
                refrigeration: state.isFullInference
                    ? inferPropertyMapping(state, 'refrigeration', 'mapping', [true, false])
                    : undefined,
            })
        } else if (transportMode === 'Electric van') {
            onUpdate({
                type: transportMode,
                refrigeration: state.isFullInference
                    ? inferPropertyMapping(state, 'refrigeration', 'mapping', [true, false])
                    : undefined,
            })
        } else {
            assertType<'Electric train' | 'Diesel train'>(transportMode)
            onUpdate({ type: transportMode })
        }
    }

    const updateTransportModeParameters = (
        parameterName: string,
        mapping: PropertyMapping<unknown> | undefined,
    ) => {
        onUpdate({
            ...transportModeMapping!,
            [parameterName]: mapping,
        } as TransportModeMapping)
    }

    // TODO How will this work with required properties
    // TODO Can we improve how `propertyMapping` on each is given?
    const transportModeParameters: Record<TransportMode, any> = {
        'Container Ship': (
            <Box>
                <Labels />
                <ParameterMapping<boolean>
                    state={state}
                    parameter="refrigeration"
                    mappingType="mapping"
                    outputValues={[false, true]}
                    formatOutput={String}
                    propertyMapping={
                        transportModeMapping && transportModeMapping.type === 'Container Ship'
                            ? transportModeMapping.refrigeration
                            : undefined
                    }
                    onUpdate={updateTransportModeParameters}
                />
                <ParameterMapping<Tradelane>
                    isTradelane={true}
                    state={state}
                    parameter="tradelane"
                    mappingType="mapping"
                    outputValues={tradelanesArray}
                    formatOutput={(val) => getTradeLaneLabel(val)}
                    propertyMapping={
                        transportModeMapping && transportModeMapping.type === 'Container Ship'
                            ? transportModeMapping.tradelane
                            : undefined
                    }
                    onUpdate={updateTransportModeParameters}
                />
            </Box>
        ),
        'Cargo Plane': (
            <Box>
                <Labels />
                <ParameterMapping<AircraftType>
                    state={state}
                    parameter="aircraftType"
                    mappingType="mapping"
                    outputValues={aircraftTypesArray}
                    formatOutput={String}
                    propertyMapping={
                        transportModeMapping && transportModeMapping.type === 'Cargo Plane'
                            ? transportModeMapping.aircraftType
                            : undefined
                    }
                    onUpdate={updateTransportModeParameters}
                />
            </Box>
        ),
        Truck: (
            <Box>
                <Labels />
                <ParameterMapping<RoadVehicleType>
                    state={state}
                    parameter="vehicleType"
                    mappingType="mapping"
                    outputValues={roadVehiclesArray}
                    formatOutput={String}
                    propertyMapping={
                        transportModeMapping && transportModeMapping.type === 'Truck'
                            ? transportModeMapping.vehicleType
                            : undefined
                    }
                    onUpdate={updateTransportModeParameters}
                />
                <ParameterMapping<RoadFuelType>
                    state={state}
                    parameter="fuel"
                    mappingType="mapping"
                    outputValues={roadFuelTypesArray}
                    formatOutput={String}
                    propertyMapping={
                        transportModeMapping && transportModeMapping.type === 'Truck'
                            ? transportModeMapping.fuel
                            : undefined
                    }
                    onUpdate={updateTransportModeParameters}
                />
            </Box>
        ),
        'Electric truck': (
            <Box>
                <Labels />
                <ParameterMapping<boolean>
                    state={state}
                    parameter="refrigeration"
                    mappingType="mapping"
                    outputValues={[false, true]}
                    formatOutput={String}
                    propertyMapping={
                        transportModeMapping && transportModeMapping.type === 'Electric truck'
                            ? transportModeMapping.refrigeration
                            : undefined
                    }
                    onUpdate={updateTransportModeParameters}
                />
            </Box>
        ),
        'Electric van': (
            <Box>
                <Labels />
                <ParameterMapping<boolean>
                    state={state}
                    parameter="refrigeration"
                    mappingType="mapping"
                    outputValues={[false, true]}
                    formatOutput={String}
                    propertyMapping={
                        transportModeMapping && transportModeMapping.type === 'Electric van'
                            ? transportModeMapping.refrigeration
                            : undefined
                    }
                    onUpdate={updateTransportModeParameters}
                />
            </Box>
        ),
        'Electric train': <Box></Box>,
        'Diesel train': <Box></Box>,
    }

    return (
        <Stack
            direction="column"
            sx={{
                width: '100%',
            }}
            spacing={{
                xs: 4,
            }}
        >
            <Text variant={'h6'} sx={{ mb: 4 }}>
                Transport mode
            </Text>
            <Box sx={{ width: '45%' }}>
                <AutocompleteSelect
                    error={error}
                    label={'Select type of shipment'}
                    onChange={(selected: ListOption | undefined) =>
                        handleTransportModeChange(selected?.value)
                    }
                    value={
                        (transportModeMapping
                            ? options.find((o) => o.value === transportModeMapping.type)
                            : { label: '', value: '', group: '' }) as ListOption
                    }
                    items={options}
                    groupBy={(option: ListOption) => option.group}
                    showGroupName={false}
                    customOptions={(option) => (
                        <Tile
                            icon={getShipmentGroupIcon(option.group)}
                            sx={{ '*': { color: palette.Grey900 } }}
                        >
                            <Text variant={'body3'}>{option.label}</Text>
                        </Tile>
                    )}
                />
            </Box>
            {transportModeMapping && transportModeParameters[transportModeMapping.type]}
        </Stack>
    )
}

export default TransportModePicker
