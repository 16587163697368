import { MultiLegShippingEmissionEstimate, Shipment, ShippingRoute } from '@lune-climate/lune'
import {
    buildLoadTiles,
    buildLogisticsTypeTiles,
    buildRouteTiles,
    EmissionsExplanationSection,
    getFormattedAmount,
    InferredShippingLegBanner,
    InputsWrapper,
    Leg,
    Legs,
    RequestAndResponse,
    ResultLabeledSectionAndTiles,
} from '@lune-fe/lune-components-lib'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'

import Divider from 'components/Divider'
import useIsTestMode from 'hooks/useIsTestMode'
import { getTestApiKey } from 'utils'

const MultiLegEstimate = ({
    legs,
    load,
    meta,
    requestRoute,
    source,
}: {
    legs: Leg[]
    load: Shipment
    meta?: any
    requestRoute?: ShippingRoute
    source?: MultiLegShippingEmissionEstimate['source']
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState<string>('1')

    const activeLeg = useMemo(() => {
        if (activeTabIndex && legs.length) {
            return legs[Number(activeTabIndex) - 1]
        }
        return null
    }, [activeTabIndex, legs])

    const isTestMode = useIsTestMode()
    const testApiKey = getTestApiKey()

    const activeLegFormattedAmount = useMemo(() => {
        if (activeTabIndex && legs.length) {
            const leg = legs[Number(activeTabIndex) - 1]
            return {
                amountAsNumber: getFormattedAmount(leg.mass.amount).amountAsNumber,
                amountUnit: getFormattedAmount(leg.mass.amount).amountUnit,
            }
        }
        return null
    }, [activeTabIndex, legs])

    return (
        <>
            <Legs
                legs={legs}
                setActiveTab={(activeIndex) => setActiveTabIndex(activeIndex.toString())}
            />
            {activeLeg && activeLegFormattedAmount && legs.length && (
                <>
                    {activeLeg.routeInferenceDetails && <InferredShippingLegBanner />}
                    <Divider />
                    <EmissionsExplanationSection
                        emissions={activeLeg.mass}
                        emissionsUnit={activeLegFormattedAmount.amountUnit}
                        emissionFactor={activeLeg.emissionFactor}
                        methodology={activeLeg.methodology}
                        distance={activeLeg.distance}
                        adjustedDistance={activeLeg.adjustedDistance}
                        load={load}
                        distanceCalculationMethod={activeLeg.distanceCalculationMethod}
                        distanceCalculationDetails={activeLeg.distanceCalculationDetails}
                        loadConversionMethod={activeLeg.shipmentConversionMethod}
                        convertedLoad={activeLeg.convertedShipment}
                        requestRoute={activeLeg.route ?? null}
                        requestMethod={activeLeg.method}
                        responseRoute={activeLeg.responseRoute}
                        methodologyDetails={activeLeg.methodologyDetails}
                        vesselInference={activeLeg.vesselInferenceDetails ?? null}
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    />
                    <Divider />
                    <Box>
                        <InputsWrapper>
                            <ResultLabeledSectionAndTiles
                                tiles={[
                                    ...buildLogisticsTypeTiles(
                                        activeLeg.method,
                                        activeLeg.emissionFactor,
                                    ),
                                    ...(requestRoute ? buildRouteTiles(requestRoute) : []),
                                    ...(activeLeg.route && !requestRoute
                                        ? buildRouteTiles(activeLeg.route)
                                        : []),
                                    ...buildLoadTiles(
                                        load,
                                        activeLeg.emissionFactor.denominatorUnit,
                                    ),
                                ]}
                                dataTestId={'leg-shipping-method'}
                            />
                        </InputsWrapper>
                    </Box>
                    <Divider />
                    <RequestAndResponse
                        path={`/v1/estimates/shipping/multi-leg`}
                        request={meta.response.request}
                        response={meta.response}
                        source={source}
                        idempotencyKey={
                            (meta.response as { idempotency_key?: string }).idempotency_key
                        }
                        metadata={
                            (
                                meta.response as {
                                    metadata?: Record<string, string>
                                }
                            ).metadata
                        }
                        isTestMode={isTestMode}
                        testApiKey={testApiKey}
                    />
                </>
            )}
        </>
    )
}

export default MultiLegEstimate
