import { ProposalResponse } from '@lune-fe/dapi-models'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { Table } from '@lune-fe/lune-ui-lib'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import moment from 'moment'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import useAccountCurrency from 'hooks/useAccountCurrency'
import useMixpanel from 'hooks/useMixpanel'

export const ProposalsTableRow = ({ proposal }: { proposal: ProposalResponse }) => {
    const navigate = useNavigate()
    const mixpanel = useMixpanel()
    const toCurrency = useAccountCurrency()

    const isFailedProposal = proposal.status === 'sent' && !proposal.rankedPortfolios?.length

    return (
        <Table.TableBody>
            <Table.TableRow
                data-testid={proposal.id}
                onClick={() => {
                    mixpanel.track('proposals_page_clicked')
                    navigate(`/proposal/${proposal.id}`, {
                        state: {
                            fromProposalsList: true,
                        },
                    })
                }}
                hover
            >
                <Table.TableCell>
                    {isFailedProposal ? (
                        <ReportProblemOutlinedIcon sx={{ mr: 3 }} />
                    ) : (
                        <DescriptionOutlinedIcon sx={{ mr: 3 }} />
                    )}
                    {moment(proposal.createdAt).format('MMMM DD, YYYY')}
                </Table.TableCell>
                <Table.TableCell>
                    {isFailedProposal ? (
                        'Failed'
                    ) : (
                        <> {proposal.status === 'sent' ? 'Ready to review' : 'Being prepared'}</>
                    )}
                </Table.TableCell>
                <Table.TableCell alignEnd>
                    {proposal.userRequirements.quantity
                        ? `${formatNumbers(proposal.userRequirements.quantity.amount, 2)} tCO₂`
                        : '-'}
                </Table.TableCell>
                <Table.TableCell alignEnd>
                    {proposal.userRequirements.price
                        ? `${toCurrency?.(proposal.userRequirements.price)}`
                        : proposal.userRequirements.pricePerTonne
                          ? `${toCurrency?.(proposal.userRequirements.pricePerTonne)} / tCO₂`
                          : '-'}
                </Table.TableCell>
                <Table.TableCell alignEnd>
                    {proposal.status === 'sent' && proposal.rankedPortfolios?.length
                        ? `${toCurrency?.(proposal.rankedPortfolios[0]?.estimatedTotalCost)}`
                        : '-'}
                </Table.TableCell>
            </Table.TableRow>
        </Table.TableBody>
    )
}

const ProposalsTable: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Table.TableContainer>
            <Table>
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell colSize={4}>Date</Table.TableCell>
                        <Table.TableCell colSize={2}>Status</Table.TableCell>
                        <Table.TableCell colSize={2}>
                            <div style={{ textAlign: `right`, width: `100%` }}>
                                {' '}
                                Requested volume
                            </div>
                        </Table.TableCell>
                        <Table.TableCell colSize={2}>
                            <div style={{ textAlign: `right`, width: `100%` }}>Budget</div>
                        </Table.TableCell>
                        <Table.TableCell colSize={2}>
                            <div style={{ textAlign: `right`, width: `100%` }}>Total cost</div>
                        </Table.TableCell>
                    </Table.TableRow>
                </Table.TableHead>
                {children}
            </Table>
        </Table.TableContainer>
    )
}

export default ProposalsTable
