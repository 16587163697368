import { OrganisationDefaultPaymentMethod } from '@lune-fe/dapi-models'
import { Button, LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import AddIcon from '@mui/icons-material/AddCardOutlined'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import Radio from '@mui/material/Radio'
import { useMemo } from 'react'

import CardsList from 'views/PaymentMethod/CardsList'

const SelectDefaultPaymentMethod = ({
    onChange,
    selectedMethod,
    onAdd,
}: {
    selectedMethod: OrganisationDefaultPaymentMethod | undefined
    onChange: (newMethod: OrganisationDefaultPaymentMethod) => void
    onAdd: () => void
}) => {
    const { palette } = LuneTheme

    const radioBtnSx = useMemo(
        () => ({
            color: palette.Grey900,
            p: 0,
            '&.Mui-checked': {
                color: palette.Grey900,
            },
        }),
        [palette],
    )

    const selectedCardId = useMemo(
        () => (selectedMethod?.type === 'card' ? selectedMethod.value : undefined),
        [selectedMethod],
    )

    return (
        <Box
            sx={{
                width: '704px',
            }}
        >
            <Tile
                sx={{
                    borderRadius: `8px`,
                }}
                onClick={() =>
                    onChange({
                        type: `invoice`,
                    })
                }
                icon={
                    <Radio
                        name={'next-invoice'}
                        data-testid={'next-invoice-radio'}
                        sx={radioBtnSx}
                        checked={selectedMethod?.type === 'invoice'}
                    />
                }
            >
                <Text
                    variant={'body2'}
                    sx={{
                        fontSize: '16px',
                        color: palette.Grey900,
                        py: 2.5,
                    }}
                >
                    Invoice
                </Text>
            </Tile>

            <Divider
                sx={{
                    my: 5,
                }}
            />

            <CardsList
                onSelect={(id) => {
                    onChange({
                        type: 'card',
                        value: id,
                    })
                }}
                selectedCardId={selectedCardId}
            />

            <Tile
                onClick={onAdd}
                sx={{
                    borderRadius: `8px`,
                    mt: 3,
                }}
                icon={
                    <AddIcon
                        sx={{
                            color: palette.Grey900,
                        }}
                    />
                }
            >
                <Box
                    sx={{
                        display: `flex`,
                        justifyContent: `space-between`,
                        alignItems: `center`,
                    }}
                >
                    <ListItemText
                        disableTypography
                        primary={
                            <Text
                                variant={'body2'}
                                sx={{
                                    lineHeight: 1.5,
                                    color: palette.Grey900,
                                    fontSize: '16px',
                                }}
                            >
                                Add Card
                            </Text>
                        }
                        secondary={
                            <Text variant={'body3'} sx={{ lineHeight: 1.5 }}>
                                We support Visa, MasterCard, American Express
                            </Text>
                        }
                    />
                    <Button data-testid={'add-new-card'} variant={'text'} onClick={onAdd}>
                        Add
                    </Button>
                </Box>
            </Tile>
        </Box>
    )
}

export default SelectDefaultPaymentMethod
