import { GeographicCoordinates } from '@lune-climate/lune'

export function coordinatesToMapboxPosition({ lat, lon }: GeographicCoordinates): [number, number] {
    return [lon, lat]
}

/**
 * Converts degrees to radians.
 */
export function degToRad(deg: number): number {
    return (deg * Math.PI) / 180.0
}

/**
 * Converts radians to degrees.
 */
export function radToDeg(rad: number): number {
    return (rad * 180.0) / Math.PI
}

/**
 * Produces an array with numbers from 0 (inclusive) to `count` (exclusive).
 *
 * Providing a number lower than 1 or a floating point number as `count` is a programming error.
 */
export function range(count: number): number[] {
    if (!Number.isInteger(count) || count < 1) {
        throw new Error(`Floating point or too low count: ${count}`)
    }

    return Array.from(Array(count).keys())
}

/**
 * Produces an array of `count` intermediate points between `a` and `b`.
 *
 * Passing a number lower than 1 or a floating point number as `count` is a programming error.
 */
export function intermediateCoordinates(
    { lat: lat1deg, lon: lon1deg }: GeographicCoordinates,
    { lat: lat2deg, lon: lon2deg }: GeographicCoordinates,
    count: number,
): GeographicCoordinates[] {
    if (!Number.isInteger(count) || count < 1) {
        throw new Error(`Floating point or too low count: ${count}`)
    }

    // The algorithm is taken from https://www.movable-type.co.uk/scripts/latlong.html
    // Sections:
    //
    // * "Distance" – c is our ro
    // * "Intermediate point"

    // Calculating ro (the "Distance" section)
    const lat1 = degToRad(lat1deg)
    const lon1 = degToRad(lon1deg)
    const lat2 = degToRad(lat2deg)
    const lon2 = degToRad(lon2deg)
    const deltaLat = degToRad(lat2deg - lat1deg)
    const deltaLon = degToRad(lon2deg - lon1deg)

    const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2)
    const ro = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    // Calculating intermediate points (the "Intermediate point" section)
    const SEGMENTS = count + 1
    return range(count).map((index) => {
        const f = (index + 1) / SEGMENTS
        const a = Math.sin((1 - f) * ro) / Math.sin(ro)
        const b = Math.sin(f * ro) / Math.sin(ro)
        const x = a * Math.cos(lat1) * Math.cos(lon1) + b * Math.cos(lat2) * Math.cos(lon2)
        const y = a * Math.cos(lat1) * Math.sin(lon1) + b * Math.cos(lat2) * Math.sin(lon2)
        const z = a * Math.sin(lat1) + b * Math.sin(lat2)
        const latRad = Math.atan2(z, Math.sqrt(x * x + y * y))
        const lonRad = Math.atan2(y, x)
        return {
            lat: radToDeg(latRad),
            lon: radToDeg(lonRad),
        }
    })
}

// Helper function to calculate a midpoint between two coordinates
function calculateMidpoint(
    point1: GeographicCoordinates,
    point2: GeographicCoordinates,
): GeographicCoordinates {
    const lat = (point1.lat + point2.lat) / 2
    const lon = (point1.lon + point2.lon) / 2
    return { lat, lon }
}

/**
 * Returns given route with intermediate points inserted into where appropriate.
 *
 * "Appropriate" means any two consecutive positions are relatively far from each other.
 *
 * The intermediate points are placed along the shortest paths between the points (following
 * the Earth's surface).
 *
 * The reason for this is so that we render shortest paths between distant points correctly
 * (if we give Mapbox two distant positions it'll just draw a straight line in screen
 * coordinates which will not reflect the actual path).
 *
 * See https://linear.app/lune/issue/LUN-3391/fix-the-straight-line-visualization-in-the-map-widget
 * for details.
 */
export function routeWithIntermediatePoints(
    route: GeographicCoordinates[],
): GeographicCoordinates[] {
    let result = [route[0]]
    let previous = route[0]
    // An arbitrary number, tune as needed. A higher number means more positions to render.
    const POSITIONS_PER_DEGREE = 0.5
    for (const p of route.slice(1)) {
        const deltaLat = Math.abs(p.lat - previous.lat)
        const deltaLon = Math.abs(p.lon - previous.lon)
        // Ideally we'd use both deltas here but we don't care to be *that* precise, just
        // pick the higher one as an approximation.
        const delta = Math.max(deltaLat, deltaLon)
        const intermediatePointCount = Math.trunc(delta * POSITIONS_PER_DEGREE)
        if (intermediatePointCount >= 1) {
            result = [...result, ...intermediateCoordinates(previous, p, intermediatePointCount)]
        }
        result.push(p)
        previous = p
    }

    // If the number of points is even, add an intermediate point in the middle
    // this is needed to draw the route middle icon correctly in the center of the line
    if (result.length % 2 === 0) {
        const middleIndex = Math.round(result.length / 2)
        const middlePoint = calculateMidpoint(result[middleIndex - 1], result[middleIndex])
        result.splice(middleIndex, 0, middlePoint) // add the middle point
    }

    return result
}
