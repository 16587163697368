import { MassUnit } from '@lune-climate/lune'
import { Table } from '@lune-fe/lune-ui-lib'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import type { SxProps } from '@mui/material'
import { Big } from 'big.js'
import { FC } from 'react'

import { convertToGram, convertToKg, convertToTonne } from '../../utils/utils'

import { EmissionsExplanationSectionProps } from './useExplanationSteps'

type PollutantKeys = keyof NonNullable<EmissionsExplanationSectionProps['emissions']['pollutants']>

const PollutantRow: FC<{
    label: string
    pollutant: NonNullable<
        EmissionsExplanationSectionProps['emissions']['pollutants']
    >[PollutantKeys]
    emissionsUnit: MassUnit
}> = ({ label, pollutant, emissionsUnit }) => {
    const unitConversionMap = {
        g: convertToGram,
        kg: convertToKg,
        t: convertToTonne,
    }
    const unitConversion = unitConversionMap[emissionsUnit]
    const unitFormatter = (value: Big) => `${Number(value)} ${emissionsUnit}`

    return (
        <Table.TableRow>
            <Table.TableCell colSize={4}>
                <ScienceOutlinedIcon sx={{ mr: 3 }} /> {label}
            </Table.TableCell>
            <Table.TableCell align="right" alignEnd>
                {unitFormatter(unitConversion(pollutant.wtt))}
            </Table.TableCell>
            <Table.TableCell align="right" alignEnd>
                {unitFormatter(unitConversion(pollutant.ttw))}
            </Table.TableCell>
            <Table.TableCell align="right" alignEnd>
                {unitFormatter(unitConversion(pollutant.wtt).add(unitConversion(pollutant.ttw)))}
            </Table.TableCell>
        </Table.TableRow>
    )
}

const EmissionPollutants: FC<{
    pollutants: NonNullable<EmissionsExplanationSectionProps['emissions']['pollutants']>
    emissionsUnit: MassUnit
    sx?: SxProps
}> = ({ pollutants, emissionsUnit, sx }) => {
    return (
        <Table.TableContainer sx={sx}>
            <Table>
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell colSize={4}>Pollutant</Table.TableCell>
                        <Table.TableCell colSize={1} align="right" alignEnd>
                            WTT
                        </Table.TableCell>
                        <Table.TableCell colSize={1} align="right" alignEnd>
                            TTW
                        </Table.TableCell>
                        <Table.TableCell colSize={1} align="right" alignEnd>
                            WTW
                        </Table.TableCell>
                    </Table.TableRow>
                </Table.TableHead>
                <Table.TableBody>
                    <PollutantRow
                        label="NOx"
                        pollutant={pollutants.nox}
                        emissionsUnit={emissionsUnit}
                    />
                    <PollutantRow
                        label="NMHC"
                        pollutant={pollutants.nmhc}
                        emissionsUnit={emissionsUnit}
                    />
                    <PollutantRow
                        label="CO₂"
                        pollutant={pollutants.co2}
                        emissionsUnit={emissionsUnit}
                    />
                    <PollutantRow
                        label="PM"
                        pollutant={pollutants.pm}
                        emissionsUnit={emissionsUnit}
                    />
                    <PollutantRow
                        label="SO₂"
                        pollutant={pollutants.so2}
                        emissionsUnit={emissionsUnit}
                    />
                </Table.TableBody>
            </Table>
        </Table.TableContainer>
    )
}

export default EmissionPollutants
