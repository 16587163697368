import { ProposalRequest } from '@lune-fe/dapi-models'
import { Button, Loading, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useSnackbar } from 'notistack'
import { RefObject, useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { Form } from 'react-final-form'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import Divider from 'components/Divider'
import FormChangesDetector from 'components/FormChangesDetector'
import { createProposal } from 'endpoints/dapi'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import { queryKeys } from 'queryKeys'
import { SnackbarMessages } from 'SnackbarMessages'
import BudgetStep from 'views/Proposals/ProposalRequestForm/BudgetStep'
import FAQs from 'views/Proposals/ProposalRequestForm/FAQs'
import GoalsStep from 'views/Proposals/ProposalRequestForm/GoalsStep'
import ProjectRequirementsStep from 'views/Proposals/ProposalRequestForm/ProjectRequirementsStep'
import Recap from 'views/Proposals/ProposalRequestForm/Recap'

const CreateProposalRequest = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const TOTAL_STEPS = 4
    const client = useQueryClient()
    const [activeStep, setActiveStep] = useState<number>(1)
    const stepsLabels = [
        'Understanding your budget',
        'Understanding your project requirements',
        'Understanding your goals',
        'Recap',
    ]
    const navigate = useNavigate()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()

    const scrollTop = () => {
        scrollRef.current!.scrollTo({ top: 0 })
    }

    useEffect(() => {
        scrollTop()
        // disabling this because it is meant to be called only on first component load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [initialValues] = useState<ProposalRequest>({
        numberOfBundles: [2, 3],
        readyToBuy: true,
        carbonPermanence: {
            mustHave: false,
            items: [],
        },
        geographiesExcluded: {
            mustHave: false,
            items: [],
        },
        geographiesIncluded: {
            mustHave: false,
            items: [],
        },
        offsetType: {
            mustHave: false,
            items: [],
        },
        projectType: {
            mustHave: false,
            items: [],
        },
        registry: {
            mustHave: false,
            items: [],
        },
    })
    const [formValue, setFormValue] = useState<ProposalRequest>()
    const [submittingForm, setSubmittingForm] = useState<boolean>(false)

    const requestWithoutEmptyArrays = (values: ProposalRequest): ProposalRequest => {
        return {
            ...values,
            // remove values with empty arrays
            carbonPermanence: values.carbonPermanence?.items.length
                ? values.carbonPermanence
                : undefined,
            offsetType: values.offsetType?.items.length ? values.offsetType : undefined,
            projectType: values.projectType?.items.length ? values.projectType : undefined,
            registry: values.registry?.items.length ? values.registry : undefined,
            geographiesExcluded: values.geographiesExcluded?.items.length
                ? values.geographiesExcluded
                : undefined,
            geographiesIncluded: values.geographiesIncluded?.items.length
                ? values.geographiesIncluded
                : undefined,
        }
    }

    const onSubmit = (values: ProposalRequest) => {
        setSubmittingForm(true)
        const request: ProposalRequest = requestWithoutEmptyArrays(values)
        createProposal(request)
            .then(async (res) => {
                // When you call a state update function, the state does not immediately change, but it's scheduled the to happen sometime in the future
                // flushSync is used for synchronously flushing updates, meaning everything within flushSync is processed right away before moving to the next line of code.
                // The flushSync call is forcing setHasUnsavedChangesState(false) to update synchronously.
                // This means that it is fully executed and completed before the function call returns,
                // ensuring that the state value hasUnsavedChangesState has been updated to false before moving on to the navigate function.
                flushSync(() => {
                    setHasUnsavedChangesState(false)
                })
                await client.invalidateQueries(queryKeys.GET_PROPOSALS)
                navigate(`/proposal/${res.id}`)
            })
            .catch(() => {
                setSubmittingForm(false)
                snackbar(SnackbarMessages.GENERIC_ERROR)
            })
    }

    return (
        <MainLayoutContainer
            headerComponent={
                <Box>
                    {activeStep === 1 ? (
                        <Button
                            variant="text"
                            iconButton
                            leftIcon={<CloseIcon />}
                            sx={{
                                ml: -2,
                                mb: 4,
                            }}
                            onClick={() => navigate(-1)}
                        />
                    ) : (
                        <Button
                            variant="text"
                            iconButton
                            leftIcon={<ArrowBackOutlinedIcon />}
                            sx={{ mb: 4, ml: -2 }}
                            disabled={submittingForm}
                            onClick={() => setActiveStep(activeStep - 1)}
                        />
                    )}
                    <Box>
                        {activeStep < TOTAL_STEPS && (
                            <Text variant={'h6'}>
                                Step {activeStep}/{TOTAL_STEPS - 1}
                            </Text>
                        )}
                        <Text variant={'h4'}>{stepsLabels[activeStep - 1]}</Text>
                    </Box>
                </Box>
            }
        >
            <Box sx={{ mt: 4, mb: 4 }}>
                <Stack direction={'column'} spacing={9}>
                    <FormChangesDetector initialValues={initialValues} newValues={formValue}>
                        <Form
                            mutators={{
                                // Used to set form field values manually
                                setValue: ([field, value], state, { changeValue }) => {
                                    changeValue(state, field, () => value)
                                },
                            }}
                            onSubmit={onSubmit}
                            initialValues={initialValues}
                            render={({
                                handleSubmit,
                                values,
                                invalid,
                                hasValidationErrors,
                                form,
                            }) => {
                                setFormValue(values)
                                return (
                                    <form
                                        style={{ position: `relative`, zIndex: 2 }}
                                        onSubmit={(form) => {
                                            if (hasValidationErrors && invalid) {
                                                snackbar(
                                                    'Please check the information you provided.',
                                                    {
                                                        variant: 'error',
                                                    },
                                                )
                                            }
                                            handleSubmit(form)
                                        }}
                                    >
                                        <Stack direction={'column'} spacing={9}>
                                            {activeStep === 1 && <BudgetStep formValues={values} />}
                                            {activeStep === 2 && <ProjectRequirementsStep />}
                                            {activeStep === 3 && <GoalsStep />}
                                            {activeStep === 4 && <Recap formValues={values} />}
                                        </Stack>
                                        <Button
                                            data-testid={'proposal-request-submit-button'}
                                            sx={{
                                                mt: 9,
                                            }}
                                            onClick={() => {
                                                if (activeStep === TOTAL_STEPS) {
                                                    if (!submittingForm) {
                                                        form.submit()
                                                    }
                                                } else {
                                                    setActiveStep(activeStep + 1)
                                                    scrollTop()
                                                }
                                            }}
                                            variant={'contained'}
                                            leftIcon={
                                                submittingForm ? (
                                                    <Loading
                                                        sx={{
                                                            filter: 'invert(1)',
                                                        }}
                                                    />
                                                ) : (
                                                    <ArrowCircleRightIcon
                                                        sx={{
                                                            width: `20px`,
                                                            height: `20px`,
                                                            borderRadius: `40px`,
                                                        }}
                                                    />
                                                )
                                            }
                                        >
                                            {activeStep === TOTAL_STEPS ? 'Submit request' : 'Next'}
                                        </Button>
                                    </form>
                                )
                            }}
                        />
                    </FormChangesDetector>
                    {activeStep !== 4 && (
                        <>
                            <Divider />
                            <FAQs />
                        </>
                    )}
                </Stack>
            </Box>
        </MainLayoutContainer>
    )
}

export default CreateProposalRequest
