import {
    SeaEmissionsAggregatedByRouteAndCarrier,
    SeaEmissionsByCarrier,
} from '@lune-fe/dapi-models'
import { Button, LuneTheme, Select, Text } from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Box } from '@mui/material'
import _ from 'lodash'
import { useCallback, useState } from 'react'

import { RouterAndCarrierChart } from 'views/ReduceEmissions/RouterAndCarrierChart'

const { palette } = LuneTheme

enum SortByOption {
    TEUS_ASC = 'TEUS_ASC',
    TEUS_DESC = 'TEUS_DESC',
    TOTAL_EMISSIONS_ASC = 'TOTAL_EMISSIONS_ASC',
    TOTAL_EMISSIONS_DESC = 'TOTAL_EMISSIONS_DESC',
    CARRIER_INTENSITY_ASC = 'CARRIER_INTENSITY_ASC',
    CARRIER_INTENSITY_DESC = 'CARRIER_INTENSITY_DESC',
}

function sortAggregatedRoutes(
    aggregatedRoutes: SeaEmissionsByCarrier,
    sortByKey: 'totalEmissions' | 'totalTeu' | 'carbonIntensity',
    ascending: boolean,
): SeaEmissionsByCarrier {
    return {
        ...aggregatedRoutes,
        emissionsByCarrier: aggregatedRoutes.emissionsByCarrier.sort((a, b) => {
            const compareValue = a[sortByKey] - b[sortByKey]
            return ascending ? compareValue : -compareValue
        }),
    }
}

const SortButton = ({
    active,
    options,
    onClick,
}: {
    active: SortByOption
    options: [SortByOption, SortByOption]
    onClick: (newVal: SortByOption) => void
}) => {
    // this button can have 3 states
    // 0 -> not selected
    // 1 -> ASC
    // 2 -> DESC
    const state = options.includes(active) ? (active.includes('ASC') ? 1 : 2) : 0
    const handleClick = () => {
        if (state === 0) {
            onClick(options[0])
        } else if (state === 1) {
            onClick(options[0])
        } else {
            onClick(options[1])
        }
    }

    return (
        <Button
            variant={'text'}
            iconButton
            leftIcon={<ArrowDownwardIcon />}
            sx={{
                ml: 1 / 2,
                width: '24px',
                height: '24px',
                p: 0,
                svg: {
                    width: '20px',
                    height: '20px',
                    color: state === 0 ? palette.Grey400 : palette.Grey900,
                },
                transform: `rotate(${state === 1 ? '180deg' : '0'})`,
                transition: 'transform .3s cubic-bezier(0.4, 0, 1, 1)',
            }}
            onClick={handleClick}
        />
    )
}

export const RouterAndCarrierMetrics = ({
    data,
}: {
    data: SeaEmissionsAggregatedByRouteAndCarrier
}) => {
    const { aggregatedRoutes, topRoutes } = data
    const [activeSortOption, setActiveSortOption] = useState<SortByOption>(
        SortByOption.TOTAL_EMISSIONS_DESC,
    )
    const [selectedFilterIndex, setSelectedFilterIndex] = useState<number | null>(null)

    const getSortedData = useCallback(() => {
        const data =
            selectedFilterIndex === null
                ? aggregatedRoutes
                : topRoutes[selectedFilterIndex - 1].data
        switch (activeSortOption) {
            case SortByOption.TOTAL_EMISSIONS_ASC:
                return sortAggregatedRoutes(data, 'totalEmissions', true)
            case SortByOption.TOTAL_EMISSIONS_DESC:
                return sortAggregatedRoutes(data, 'totalEmissions', false)
            case SortByOption.TEUS_ASC:
                return sortAggregatedRoutes(data, 'totalTeu', true)
            case SortByOption.TEUS_DESC:
                return sortAggregatedRoutes(data, 'totalTeu', false)
            case SortByOption.CARRIER_INTENSITY_ASC:
                return sortAggregatedRoutes(data, 'carbonIntensity', true)
            case SortByOption.CARRIER_INTENSITY_DESC:
                return sortAggregatedRoutes(data, 'carbonIntensity', false)
            default:
                return data
        }
    }, [activeSortOption, aggregatedRoutes, selectedFilterIndex])

    const getMaxValues = useCallback(() => {
        const emissionsByCarrier =
            selectedFilterIndex === null
                ? aggregatedRoutes.emissionsByCarrier
                : topRoutes[selectedFilterIndex - 1].data.emissionsByCarrier

        const maxTotalTeu = _.maxBy(emissionsByCarrier, 'totalTeu')
        const maxTotalEmissions = _.maxBy(emissionsByCarrier, 'totalEmissions')

        return {
            maxTotalTeu: maxTotalTeu?.totalTeu || 0,
            maxTotalEmissions: maxTotalEmissions?.totalEmissions || 0,
        }
    }, [selectedFilterIndex])

    return (
        <Box
            sx={{
                overflow: 'auto',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    mb: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Text
                    variant={'h5'}
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    Total Emissions Per Carrier
                </Text>
                <Select
                    sx={{ width: '220px' }}
                    label={'Filter by Port Pairs'}
                    items={[
                        ...[{ label: 'No filter', value: null }],
                        ...topRoutes.map((route, index) => ({
                            label: `${route.route.source} - ${route.route.destination}`,
                            value: index + 1,
                        })),
                    ]}
                    value={selectedFilterIndex}
                    onChange={(selected) => setSelectedFilterIndex(selected)}
                />
            </Box>
            <Box>
                <Box
                    sx={{
                        minWidth: '800px',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            '.MuiTypography-root': {
                                lineHeight: '24px',
                                '&:nth-child(1)': {
                                    width: '32%',
                                    textAlign: 'right',
                                },
                                '&:nth-child(2)': {
                                    width: '18%',
                                    textAlign: 'center',
                                },
                                '&:nth-child(3)': {
                                    width: '32%',
                                },
                                '&:nth-child(4)': {
                                    width: '18%',
                                    textAlign: 'right',
                                },
                            },
                        }}
                    >
                        <Text variant={'button'}>
                            <SortButton
                                options={[SortByOption.TEUS_DESC, SortByOption.TEUS_ASC]}
                                active={activeSortOption}
                                onClick={(newVal) => setActiveSortOption(newVal)}
                            />
                            TEUs
                        </Text>
                        <Text variant={'button'}>Carrier</Text>
                        <Text variant={'button'}>
                            <SortButton
                                options={[
                                    SortByOption.TOTAL_EMISSIONS_DESC,
                                    SortByOption.TOTAL_EMISSIONS_ASC,
                                ]}
                                onClick={(newVal) => setActiveSortOption(newVal)}
                                active={activeSortOption}
                            />
                            Total Emissions CO₂e
                        </Text>
                        <Text variant={'button'}>
                            <SortButton
                                options={[
                                    SortByOption.CARRIER_INTENSITY_DESC,
                                    SortByOption.CARRIER_INTENSITY_ASC,
                                ]}
                                onClick={(newVal) => setActiveSortOption(newVal)}
                                active={activeSortOption}
                            />
                            Carrier Intensity
                        </Text>
                    </Box>
                    <RouterAndCarrierChart
                        data={getSortedData()}
                        highestCarrierByTeu={getMaxValues().maxTotalTeu}
                        highestCarrierByEmissions={getMaxValues().maxTotalEmissions}
                    />
                </Box>
            </Box>
        </Box>
    )
}
