import {
    PassengerTransportationEmissionEstimate,
    PassengerTransportationLegEmissionEstimate,
} from '@lune-climate/lune'
import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'

import { getFormattedAmount } from '../../utils'

import { LegsScrollableContainer } from './Legs'
import { getPassengerTransportationType, passengerTransportationIcons } from './utils'

const { palette } = LuneTheme

const PassengerTransportExplanationLegBox: FC<{
    leg: PassengerTransportationLegEmissionEstimate
    legNumber: number
    active: boolean
    onClick: (legNumber: number, legElement: HTMLButtonElement) => void
    smallBoxes: boolean
    icon: React.ReactNode
}> = ({ leg, legNumber, active, onClick, smallBoxes, icon }) => {
    const legFormattedAmount = (leg: PassengerTransportationLegEmissionEstimate): string => {
        const formattedAmount = getFormattedAmount(leg.mass.amount)
        return `${Number(formattedAmount.amountAsNumber).toFixed(
            2,
        )} ${formattedAmount.amountUnit}CO₂e`
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClick(legNumber, event.currentTarget)
    }

    return (
        <Box
            data-testid={`leg-btn-${legNumber}`}
            onClick={handleClick}
            component={'button'}
            sx={{
                background: active ? palette.Blue50 : palette.Grey100,
                mt: 1,
                mb: 1,
                height: '160px',
                borderRadius: '16px',
                p: 2,
                minWidth: '120px',
                maxWidth: active ? '320px' : smallBoxes ? '122px' : '160px',
                width: active ? '320px' : '100%',
                flex: '1 0 auto',
                transition: 'background .15s ease-in-out',
                overflow: 'hidden',
                cursor: 'pointer',
                border: 'none',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'space-between',
                justifyContent: 'space-between',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    padding: '10px 14px 10px 12px',
                    borderRadius: '40px',
                    background: active ? palette.Blue500 : palette.Grey300,
                    transition: 'background .15s ease-in-out',
                    color: active ? palette.White : palette.Grey900,
                    svg: {
                        height: '20px',
                        width: '20px',
                    },
                    span: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    },
                }}
            >
                <Text variant={'button'}>
                    {icon} Leg {legNumber}
                </Text>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                {leg.distance && (
                    <Text variant={'body2'}>
                        {leg.distance.amount} {leg.distance.unit}
                    </Text>
                )}
                <Text variant={'caption'} data-testid={`leg-emissions-amount-${legNumber}`}>
                    {legFormattedAmount(leg)}
                </Text>
            </Box>
        </Box>
    )
}

export const PassengerTransportExplanationLegs = ({
    estimate,
    setActiveTab,
}: {
    estimate: PassengerTransportationEmissionEstimate
    setActiveTab: Dispatch<SetStateAction<number>>
}) => {
    const { legs, request } = estimate
    const [activeTabIndex, setActiveTabIndex] = useState<number>(1)

    const handleLegClick = (legNumber: number, legElement: HTMLButtonElement) => {
        setActiveTabIndex(legNumber)
        setActiveTab(legNumber)

        legElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
        })
    }

    return (
        <LegsScrollableContainer>
            <Box
                sx={{
                    pr: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'flex-start',
                        gap: 1,
                        flexWrap: 'nowrap',
                        width: 'max-content',
                    }}
                >
                    {legs.map((leg, index) => {
                        const Icon =
                            passengerTransportationIcons[
                                getPassengerTransportationType(request.legs[index])
                            ]
                        return (
                            <PassengerTransportExplanationLegBox
                                icon={<Icon />}
                                key={index}
                                leg={leg}
                                smallBoxes={legs.length > 5}
                                legNumber={index + 1}
                                active={activeTabIndex === index + 1}
                                onClick={handleLegClick}
                            />
                        )
                    })}
                </Box>
            </Box>
        </LegsScrollableContainer>
    )
}

export default PassengerTransportExplanationLegs
