import { SeaEmissionsAggregatedByRouteAndCarrier, SeaShippingMetrics } from '@lune-fe/dapi-models'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { MainLayoutContainer, Text, Tooltip } from '@lune-fe/lune-ui-lib'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, Stack } from '@mui/material'
import moment from 'moment'

import Divider from 'components/Divider'
import { RouterAndCarrierMetrics } from 'views/ReduceEmissions/RouterAndCarrierMetrics'

const HeadlineMetric = ({ label, value }: { label: string; value: string }) => {
    return (
        <Stack gap={1}>
            <Text variant={'body3'}>{label}</Text>
            <Text variant={'h5'}>{value}</Text>
        </Stack>
    )
}

export const EnabledView = ({
    data,
    preview,
}: {
    data: {
        seaShippingMetrics: SeaShippingMetrics
        routerAndCarrierMetrics: SeaEmissionsAggregatedByRouteAndCarrier
    }
    preview: boolean
}) => {
    const { seaShippingMetrics, routerAndCarrierMetrics } = data
    return (
        <Box>
            {preview && (
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.2)',
                        color: 'red',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        p: 1,
                    }}
                >
                    <Text variant={'button'}>
                        PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW -
                        PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW -
                        PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW - PREVIEW -
                        PREVIEW - PREVIEW - PREVIEW - PREVIEW{' '}
                    </Text>
                </Box>
            )}
            <MainLayoutContainer
                headerComponent={
                    <>
                        <Text variant={'h4'} sx={{ mb: 2 }}>
                            Emission Reductions
                        </Text>

                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                            <Tooltip
                                title={`Sea shipping accounts for ${((seaShippingMetrics.totalSeaShippingLegs / seaShippingMetrics.totalShippingLegs) * 100).toFixed(1)}% of your shipping activity.`}
                            >
                                <Text
                                    variant="body3"
                                    sx={{
                                        color: 'Grey700',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatNumbers(seaShippingMetrics.totalSeaShippingLegs)} sea
                                    shipments
                                </Text>
                            </Tooltip>
                            <CircleIcon sx={{ color: 'Grey700', fontSize: '4px' }} />
                            <Text
                                variant="body3"
                                sx={{
                                    color: 'Grey700',
                                }}
                            >
                                Data from previous 12 months from{' '}
                                {moment.utc().format('MMMM D, YYYY')}
                            </Text>
                        </Box>
                    </>
                }
            >
                <Stack
                    direction="column"
                    sx={{
                        mb: 9,
                        width: '100%',
                    }}
                    spacing={{
                        xs: 9,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <HeadlineMetric
                            label="TEUs shipped"
                            value={`${formatNumbers(seaShippingMetrics.totalTeuSea, 2)}`}
                        />
                        <HeadlineMetric
                            label="Shipment emissions"
                            value={`${formatNumbers(seaShippingMetrics.totalEmissionsSea, 2)} tCO₂e`}
                        />
                        <HeadlineMetric
                            label="Carbon intensity (per TEU·km)"
                            value={`${formatNumbers(seaShippingMetrics.carbonIntensitySea, 2)} gCO₂e`}
                        />
                    </Box>
                    {!!routerAndCarrierMetrics.aggregatedRoutes.emissionsByCarrier.length && (
                        <>
                            <Divider />
                            <RouterAndCarrierMetrics data={routerAndCarrierMetrics} />
                        </>
                    )}
                </Stack>
            </MainLayoutContainer>
        </Box>
    )
}
