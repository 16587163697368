import { Portfolio } from '@lune-fe/dapi-models'
import { Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Divider from 'components/Divider'
import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useScrollRestoration, { ScrollPositionKeys } from 'hooks/useScrollRestoration'
import { Allocation } from 'views/BuyOffsets/BuyOffsetsTypes'
import ByVolumeCalculationsWrapper from 'views/BuyOffsets/CartModal/CartModalController/ByVolumeCalculationsWrapper'
import CustomPortfolioCartModalController from 'views/BuyOffsets/CustomPortfolioCartModalController'
import Option from 'views/Proposals/Proposal/Option'

const Options: FC<{
    options: Portfolio[]
    scrollRef: RefObject<HTMLDivElement>
    proposalId: string
}> = ({ options, scrollRef, proposalId }) => {
    const [editing, setEditing] = useState<boolean>(false)
    const [allocation, setAllocation] = useState<Allocation[]>()
    const [calculatingQuote, setCalculatingQuote] = useState<boolean>(false)

    const { setBuyOffsetProps, resetBuyOffsetProps, buyOffsetProps } = useBuyOffsetsState()
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const navigate = useNavigate()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { saveScrollPosition } = useScrollRestoration()

    useEffect(() => {
        resetBuyOffsetProps()
        setAllocation(undefined)
    }, [resetBuyOffsetProps])

    useEffect(() => {
        resetBuyOffsetProps()
    }, [resetBuyOffsetProps])

    const onEdit = (allocation: Allocation[], option: Portfolio) => {
        setEditing(true)
        setBuyOffsetProps({
            allocation,
            selectedBundles: allocation.map((a) => a.bundle),
            simpleModal: true,
            amount: option.quantity.amount,
            orderTotalAmount: Number(option.quantity.amount),
            isCustomBundlePercentageSet: true,
            isCustomBundleVolumeSet: false,
            customPercentages: allocation.map((a) => {
                return {
                    bundleId: a.bundle.id,
                    percentage: a.percentage,
                }
            }),
        })
        saveScrollPosition(scrollRef, ScrollPositionKeys.PROPOSAL_VIEW)
    }

    const onBuyNow = (allocation: Allocation[], option: Portfolio) => {
        setCalculatingQuote(true)
        setAllocation(allocation)
        setBuyOffsetProps({
            allocation,
            selectedBundles: allocation.map((a) => a.bundle),
            amount: option.quantity.amount,
            orderTotalAmount: Number(option.quantity.amount),
        })
        saveScrollPosition(scrollRef, ScrollPositionKeys.PROPOSAL_VIEW)
    }

    useEffect(() => {
        if (allocation) {
            if (buyOffsetProps.quote) {
                navigate('/order/summary', {
                    state: buyOffsetProps,
                })
            }
            if (buyOffsetProps.insufficientBundlesError) {
                snackbar(buyOffsetProps.insufficientBundlesError.message, { persist: true })
            }
            if (buyOffsetProps.error) {
                snackbar(buyOffsetProps.error.message)
                setCalculatingQuote(false)
            }
        }
    }, [buyOffsetProps, allocation, navigate, snackbar])

    return (
        <Stack spacing={9}>
            {options.map((option, index) => {
                return (
                    <>
                        <Option
                            proposalId={proposalId}
                            calculatingQuote={calculatingQuote}
                            key={`proposal-option-${index}`}
                            index={index}
                            option={option}
                            scrollRef={scrollRef}
                            onEdit={(allocation) => onEdit(allocation, option)}
                            onBuyNow={(allocation) => onBuyNow(allocation, option)}
                        />
                        <Divider />
                    </>
                )
            })}

            {editing && (
                <CustomPortfolioCartModalController
                    modalTitle={'Edit portfolio'}
                    onCloseModal={() => {
                        setEditing(false)
                        resetBuyOffsetProps()
                        setHasUnsavedChangesState(false)
                    }}
                    onRemoveBundle={() => {}}
                />
            )}

            {/* Using ByVolumeCalculationsWrapper component to update buyOffsetsProps state with the right quote calculation
            This component does not render anything in UI on its own, it only contains the logic of calculating the quote */}
            {allocation?.length && (
                <ByVolumeCalculationsWrapper allocation={allocation} customizedPortfolio={true} />
            )}
        </Stack>
    )
}

export default Options
