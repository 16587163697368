import { Workbook, Worksheet } from 'exceljs'

import {
    ParameterName,
    RfqTemplateData,
    TemplateLegInfo,
} from 'views/CalculateEmissions/RFQ/utils/sharedTypes'

export type RFQBaseData = {
    workbook: Workbook
}

export type RFQTemplateOptions = Pick<RfqTemplateData, 'sheetName' | 'headerRow' | 'endRow'> & {
    numberOfLegs: number
}

type RFQWorkingData = {
    sheet: Worksheet
    headers: string[]
    exampleRow: string[]
}

export type RFQBaseState = RFQBaseData & RFQTemplateOptions & RFQWorkingData

export type RFQTemplateState = RFQBaseState & {
    load: RfqTemplateData['load'] | undefined
    currentLeg: number
    legs: TemplateLegInfo[]
    isFullInference: boolean
}

export const parameterNameAliases: Record<ParameterName, string[]> = {
    load: ['weight', 'payload', 'freight'],
    refrigeration: ['fridge'],
    tradelane: [],
    countryCode: ['country', 'ISO3'],
    streetLine: ['street', 'address', 'line 1'],
    city: ['location'],
    postcode: ['zipcode', 'zip code'],
    airportCode: ['IATA', 'ICAO'],
    portCode: ['location code', 'UNLOCODE', 'locode'],
    lat: ['latitude'],
    lon: ['longitude'],
    aircraftType: ['Aircraft', 'Airplane'],
    vehicleType: ['Vehicle', 'Car', 'Truck', 'Van'],
    fuel: ['Energy source'],
}

export const unitAliases: { [key: string]: string[] } = {
    t: ['ton', 'tonne', 'tonnage'],
    kg: ['kilogram', 'kilo'],
    TEU: [`20'DC`, `tons 20'DC`],
}

export const inputAliases: { [key: string]: string[] } = {
    FEEB: ['Whatever FEEB means'],
    FEWB: ['Whatever FEWB means'],
}
