import {
    EmissionFactorWithGasEmissions,
    EstimateMass,
    GasEmissions,
    TransactionEmissionEstimate,
} from '@lune-climate/lune'
import { BannerMessage } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { Big } from 'big.js'
import moment from 'moment'
import { FC, useMemo } from 'react'

import { convertToTonne, formatToCurrency } from '../../utils/utils'

import EmissionFactorExplanationCard from './EmissionFactorExplanationCard'
import ExplanationChip from './ExplanationChip'
import ExplanationSteps, { ExplanationText } from './ExplanationSteps'

export function getDietLabel(diet: string): string {
    return diet.replaceAll('_', ' ') + ' diet'
}

// Estimate response does not include the emission factor intensity.
// We calculate it instead.
export const calculateTransactionEmissionFactorAmount = (
    emissions: EstimateMass,
    estimateRequest: TransactionEmissionEstimate['request'],
    dietFactor: string | null,
    exchangeRate: string | null,
    emissionFactor: EmissionFactorWithGasEmissions,
): Big => {
    if (emissionFactor.gasEmissions !== null) {
        return Big(emissionFactor.gasEmissions.co2E)
    }
    let emissionsInTonnes = convertToTonne(emissions).div(estimateRequest.value.value)

    if (dietFactor) {
        emissionsInTonnes = emissionsInTonnes.div(dietFactor)
    }
    if (exchangeRate) {
        emissionsInTonnes = emissionsInTonnes.div(exchangeRate)
    }

    return emissionsInTonnes
}

interface TransactionEmissionExplanationProps {
    estimate: TransactionEmissionEstimate
    enableEmissionFactorLink?: boolean
}

export const TransactionEmissionExplanation: FC<TransactionEmissionExplanationProps> = ({
    estimate,
    enableEmissionFactorLink,
}) => {
    const {
        request: estimateRequest,
        mass: emissions,
        dietFactor,
        exchangeRate,
        exchangeRateDate,
        emissionFactor,
    } = estimate

    if (!emissionFactor) {
        // Estimates that pre-date our implentation of Emissions Explanations
        // do not have Emission Factors
        return (
            <BannerMessage
                sx={{ mb: 1 }}
                message={`Unfortunately we don't have Emission Factor and calculation data for legacy estimates.`}
            />
        )
    }

    const localEmissionFactorAmount = calculateTransactionEmissionFactorAmount(
        emissions,
        estimateRequest,
        dietFactor,
        exchangeRate,
        emissionFactor,
    )

    const emissionFactorExplanation = useMemo((): JSX.Element => {
        return (
            <>
                <ExplanationText text="Selected the" />
                <ExplanationChip text={emissionFactor.name} />
                <ExplanationText text="Emission Factor" />
            </>
        )
    }, [emissionFactor])

    const dietExplanation = useMemo((): JSX.Element | undefined => {
        if (!dietFactor || Number(dietFactor) === 1) {
            return
        }
        return (
            <>
                <ExplanationText text="Multiplied the Emission Factor by" />
                <ExplanationChip text={dietFactor} />
                <ExplanationText text="to account for" />
                <ExplanationChip text={getDietLabel(estimateRequest.diet!)} />
            </>
        )
    }, [dietFactor, estimateRequest])

    const exchangeRateExplanation = useMemo((): JSX.Element | undefined => {
        if (!exchangeRate || !exchangeRateDate) {
            return
        }
        const amountInInputCurrency = formatToCurrency(
            estimateRequest.value.value,
            estimateRequest.value.currency,
        )
        const amountInConvertedCurrency = formatToCurrency(
            Big(estimateRequest.value.value).mul(exchangeRate).toString(),
            emissionFactor.denominatorUnit,
        )
        const formattedDate = moment(exchangeRateDate).format('D MMMM YYYY')

        return (
            <>
                <ExplanationText text="Converted" />
                <ExplanationChip text={amountInInputCurrency} />
                <ExplanationText text="to" />
                <ExplanationChip text={amountInConvertedCurrency} />
                <ExplanationText text="based on the" />
                <ExplanationChip text={formattedDate} />
                <ExplanationText text="exchange rate of" />
                <ExplanationChip text={exchangeRate} />
            </>
        )
    }, [exchangeRate, exchangeRateDate, emissionFactor, estimateRequest])

    const formulaExplanation = dietFactor ? (
        <ExplanationText text="Multiplied the adjusted Emission Factor by the amount" />
    ) : (
        <ExplanationText text="Multiplied the Emission Factor by the amount" />
    )

    const steps: { description?: JSX.Element }[] = [
        { description: emissionFactorExplanation },
        { description: dietExplanation },
        { description: exchangeRateExplanation },
        { description: formulaExplanation },
    ].filter((s) => !!s.description)

    return (
        <>
            <ExplanationSteps steps={steps} />
            <Box>
                <EmissionFactorExplanationCard
                    {...(emissionFactor.gasEmissions === null
                        ? {
                              emissionFactor: emissionFactor as Omit<
                                  EmissionFactorWithGasEmissions,
                                  'gasEmissions'
                              > & { gasEmissions: null },
                              amount: localEmissionFactorAmount.mul(1000).toString(),
                          }
                        : {
                              emissionFactor: emissionFactor as Omit<
                                  EmissionFactorWithGasEmissions,
                                  'gasEmissions'
                              > & { gasEmissions: GasEmissions },
                              amount: null,
                          })}
                    label={emissionFactor.name}
                    href={
                        enableEmissionFactorLink
                            ? `/emission-factors/${emissionFactor.id}`
                            : undefined
                    }
                />
            </Box>
        </>
    )
}

export default TransactionEmissionExplanation
