import {
    Button,
    Input,
    LabeledFormSection,
    ListItemLayout,
    MainLayoutContainer,
    Text,
} from '@lune-fe/lune-ui-lib'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import { Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'
import { patchOrganisation } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useMixpanel from 'hooks/useMixpanel'
import { useIsAuthorised } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'
import InviteTeamMembers from 'views/Settings/OrganisationSettings/InviteTeamMembers'
import ManageLogo from 'views/Settings/OrganisationSettings/ManageLogo'
import ManageLogoVisibility from 'views/Settings/OrganisationSettings/ManageLogoVisibility'
import ManageTeam from 'views/Settings/OrganisationSettings/ManageTeam'

export enum Role {
    ADMIN = 'admin',
    USER = 'user',
}

const OrganisationSettings: FC = () => {
    const { activeOrg, refetchActiveOrg } = useActiveOrganisation()
    const [localName, setLocalName] = useState(activeOrg?.name || ``)
    const [localBeneficiary, setLocalBeneficiary] = useState(activeOrg?.beneficiary || ``)
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const isAuthorised = useIsAuthorised()
    const mixpanel = useMixpanel()

    useEffect(() => {
        if (activeOrg) {
            setLocalName(activeOrg.name)
            setLocalBeneficiary(activeOrg.beneficiary)
        }
    }, [activeOrg])

    const updateOrg = useCallback(
        ({ name, beneficiary }: { name?: string; beneficiary?: string }) =>
            patchOrganisation(activeOrg?.id || ``, {
                beneficiary,
                name,
            })
                .then(() => {
                    refetchActiveOrg()
                    snackbar(SnackbarMessages.SETTINGS_SAVED)
                    if (beneficiary !== activeOrg?.beneficiary) {
                        mixpanel.track('settings_beneficiary_saved')
                    }
                })
                .catch(() => {
                    snackbar(SnackbarMessages.SETTINGS_UPDATE_FAILED)
                }),
        [activeOrg, mixpanel, snackbar, refetchActiveOrg],
    )

    return (
        <MainLayoutContainer headerComponent={<Text variant={'h4'}>Organisation</Text>}>
            <LabeledFormSection
                title={`Name & logo`}
                sx={{
                    mb: 5.5,
                }}
            >
                <ConditionalTooltip show={!isAuthorised} tooltip={NotAdminTooltip}>
                    <Input
                        disabled={!isAuthorised}
                        wide
                        value={localName}
                        onChange={(e) => setLocalName(e.target.value)}
                        onBlur={(e) => {
                            const trimmedName = e.target.value.trim()
                            if (trimmedName === ``) {
                                setLocalName(activeOrg?.name!)
                                snackbar(SnackbarMessages.ORGANISATION_NAME_UPDATE_FAIL)
                                return
                            }
                            if (trimmedName === activeOrg?.name) {
                                return
                            }
                            return updateOrg({
                                name: trimmedName,
                                beneficiary: activeOrg?.beneficiary,
                            })
                        }}
                    />
                </ConditionalTooltip>
            </LabeledFormSection>

            <ManageLogo />
            <ManageLogoVisibility />

            <LabeledFormSection
                title={`Organisation ID`}
                sx={{
                    mt: 10.5,
                    mb: 9,
                }}
            >
                <Container
                    disableGutters
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <ListItemLayout image={<TagOutlinedIcon />} title={activeOrg?.id} />
                    <CopyToClipboard
                        text={activeOrg?.id ?? ''}
                        onCopy={() => snackbar(`Organisation ID copied`)}
                    >
                        <Button variant={'text'}>Copy</Button>
                    </CopyToClipboard>
                </Container>
            </LabeledFormSection>

            <LabeledFormSection
                title={`Beneficiary`}
                subTile={`The beneficiary will be shown on the Carbon Offset Certificate. It will also appear on the carbon registries (e.g. Verra).`}
                sx={{
                    mt: 10.5,
                    mb: 9,
                }}
            >
                <ConditionalTooltip show={!isAuthorised} tooltip={NotAdminTooltip}>
                    <Input
                        disabled={!isAuthorised}
                        wide
                        value={localBeneficiary}
                        onChange={(e) => setLocalBeneficiary(e.target.value)}
                        onBlur={(e) => {
                            const ben = e.target.value.trim()
                            if (ben === ``) {
                                setLocalBeneficiary(activeOrg?.beneficiary!)
                                snackbar(SnackbarMessages.ORGANISATION_BENEFICIARY_UPDATE_FAIL)
                                return
                            }
                            if (ben === activeOrg?.beneficiary) {
                                return
                            }
                            return updateOrg({
                                name: activeOrg?.name,
                                beneficiary: ben,
                            })
                        }}
                    />
                </ConditionalTooltip>
            </LabeledFormSection>

            <ManageTeam />
            <InviteTeamMembers />
        </MainLayoutContainer>
    )
}

export default OrganisationSettings
