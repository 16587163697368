import { MassUnit } from '@lune-climate/lune'
import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import HorizontalSegmentedBar from 'lune-ui-lib/src/components/HorizontalSegmentedBar'

import { convertToGram, convertToKg, convertToTonne } from '../../utils/utils'

import { EmissionsExplanationSectionProps } from './useExplanationSteps'

export const WtwBreakdownExplanation = ({
    emissions,
    emissionsUnit,
    sx,
}: {
    emissions: EmissionsExplanationSectionProps['emissions']
    emissionsUnit: MassUnit
    sx?: SxProps
}) => {
    const unitConversionMap = {
        g: convertToGram,
        kg: convertToKg,
        t: convertToTonne,
    }
    const unitConversion = unitConversionMap[emissionsUnit]
    const breakdownConvertedUnit = emissions.wtwDetails
        ? {
              wtt: unitConversion(emissions.wtwDetails.wtt),
              ttw: unitConversion(emissions.wtwDetails.ttw),
              unknown: unitConversion(emissions.wtwDetails.unknown),
          }
        : undefined
    return (
        <Box sx={sx}>
            {breakdownConvertedUnit && (
                <HorizontalSegmentedBar
                    segments={[
                        {
                            value: Number(breakdownConvertedUnit.wtt),
                            unit: `${emissionsUnit}CO₂e`,
                            colour: '#0099F1',
                            order: 0,
                            label: 'Well-To-Tank',
                        },
                        {
                            value: Number(breakdownConvertedUnit.ttw),
                            unit: `${emissionsUnit}CO₂e`,
                            colour: '#3BC9F0',
                            order: 1,
                            label: 'Tank-To-Wheel',
                        },
                        {
                            value: Number(breakdownConvertedUnit.unknown),
                            unit: `${emissionsUnit}CO₂e`,
                            colour: '#BDBDBD',
                            order: 2,
                            label: 'Unknown stage',
                        },
                    ]}
                    legendOptions={{ ontop: false, vertical: true }}
                />
            )}
        </Box>
    )
}

export default WtwBreakdownExplanation
