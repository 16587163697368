import { TransactionDocumentEmissionEstimate } from '@lune-climate/lune'
import { Snippet, SnippetItem } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'

import Divider from '../../components/Divider'
import { getFormattedAmount } from '../../utils/utils'

import { InputWrapper } from './InputsWrapper'
import { LineItems } from './LineItems'
import { TransactionDocumentEmissionsExplanationLineItemSection } from './TransactionDocumentEmissionsExplanationLineItemSection'

export const TransactionDocumentLineItems = ({
    lineItems,
    input,
    enableEmissionFactorLink,
}: {
    lineItems: NonNullable<TransactionDocumentEmissionEstimate['lineItems']>
    input: any
    enableEmissionFactorLink: boolean
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState<string>('1')

    const activeLineItem = useMemo(() => {
        if (activeTabIndex && lineItems.length) {
            return lineItems[Number(activeTabIndex) - 1]
        }
        return null
    }, [activeTabIndex, lineItems])

    const activeLineItemFormattedAmount = useMemo(() => {
        if (activeTabIndex && lineItems.length) {
            const lineItem = lineItems[Number(activeTabIndex) - 1]
            return {
                amountAsNumber: getFormattedAmount(lineItem.mass.amount).amountAsNumber,
                amountUnit: getFormattedAmount(lineItem.mass.amount).amountUnit,
            }
        }
        return null
    }, [activeTabIndex, lineItems])

    const formattedInput = useMemo((): string => {
        return JSON.stringify(input, null, 2)
    }, [input])

    return (
        <>
            <LineItems
                lineItems={lineItems}
                setActiveTab={(activeIndex) => setActiveTabIndex(activeIndex.toString())}
            />
            {activeLineItem && activeLineItemFormattedAmount && lineItems.length && (
                <>
                    <Divider />
                    <TransactionDocumentEmissionsExplanationLineItemSection
                        lineItem={activeLineItem}
                        enableEmissionFactorLink={enableEmissionFactorLink}
                    />
                    <Divider />
                    <Box>
                        <InputWrapper>
                            <Snippet header="JSON" sx={{ maxHeight: '480px' }}>
                                <SnippetItem language={'plain'} toCopy={formattedInput}>
                                    {formattedInput}
                                </SnippetItem>
                            </Snippet>
                        </InputWrapper>
                    </Box>
                </>
            )}
        </>
    )
}

export default TransactionDocumentLineItems
